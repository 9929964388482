import { LOADING_STATUS } from '../../../constants'
import { activityLogCaseAdapter } from './reducer'

export const { selectAll: selectAllActivityLogCases } =
  activityLogCaseAdapter.getSelectors(state => state.activityLogCases)

export const selectActivityLogCasesCount = state => state.activityLogCases.count

export const isActivityLogCasesFetching = state =>
  state.activityLogCases.status === LOADING_STATUS

import React from 'react'
import { Link } from 'react-router-dom'
import { CheckOutlined, WarningOutlined } from '@ant-design/icons'
import { Tag, Space } from 'antd'
import EditorCell from '../view/components/Exception/EditorCell'
import { DOCTOR, FACILITY, HEALTH_NETWORK } from '../constants'
import { mapStatusColor, parseValues, formatDate } from '../utils/helpers'

const widthMapping = {
  name: 140,
  address_type: 30,
  state: 40,
  city: 100,
  address_line_1: 150,
  address_line_2: 100,
  zip_code: 50,
  phones: 110,
  faxes: 110,
}

export const facilityTableColumns = [
  {
    title: 'Name',
    key: 'name',
    link: true,
    highlightOptions: {
      color: 'light_blue',
      fields: ['facility_name', 'first_name', 'last_name'],
    },
    width: widthMapping.name,
  },
  {
    title: 'Health Network',
    key: 'health_network',
    link: true,
    width: 120,
    // eslint-disable-next-line react/display-name
    render: (text = []) => {
      const [healthNetwork] = text
      if (!healthNetwork) return <></>
      const path = `/${HEALTH_NETWORK}/${healthNetwork.id}/`

      return (
        <Link to={{ pathname: path }}>
          <Space>{healthNetwork.name}</Space>
        </Link>
      )
    },
  },
  {
    title: 'Vendors',
    key: 'vendor_relations',
    align: 'center',
  },
  {
    title: 'Type',
    key: 'address_type',
    align: 'center',
    width: 30,
  },
  {
    title: 'State',
    key: 'state',
    align: 'center',
    highlightOptions: {
      color: 'light_blue',
      field: 'state',
    },
    width: widthMapping.state,
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
    highlightOptions: {
      color: 'light_blue',
      field: 'city',
    },
    oneString: true,
    width: widthMapping.city,
  },
  {
    title: 'Address Line 1',
    key: 'address_line_1',
    highlightOptions: {
      color: 'light_blue',
      field: 'address_line_1',
    },
    oneString: true,
    width: widthMapping.address_line_1,
  },
  {
    title: 'Address Line 2',
    key: 'address_line_2',
    highlightOptions: {
      color: 'light_blue',
      field: 'address_line_2',
    },
    oneString: true,
    width: widthMapping.address_line_2,
  },
  {
    title: 'Zip Code',
    key: 'zip_code',
    highlightOptions: {
      color: 'light_blue',
      field: 'zip_code',
    },
    width: widthMapping.zip_code,
  },
  {
    title: 'Phone',
    key: 'phones',
    highlightOptions: {
      color: 'medium_blue',
      field: 'phone',
    },
    field: 'number',
    boldLocationType: true,
    width: widthMapping.phones,
  },
  {
    title: 'Fax',
    key: 'faxes',
    highlightOptions: {
      color: 'strong_blue',
      field: 'fax',
    },
    field: 'number',
    boldLocationType: true,
    width: widthMapping.faxes,
  },
  {
    title: 'Doctors',
    key: 'related_doctors',
    align: 'center',
    link: true,
    // eslint-disable-next-line react/display-name
    render: (text, column, data) => {
      const { id } = data
      const path = `/${FACILITY}/${id}/`
      return <Link to={{ pathname: path, data: { autoscroll: true } }}>{text}</Link>
    },
    width: 68,
  },
  {
    title: 'Facility Profile',
    key: 'facility_profile',
    align: 'center',
    width: 100,
  },
]

export const vendorFacilityTableColumns = [
  {
    title: 'Name',
    key: 'name',
    link: true,
    highlightOptions: {
      color: 'light_blue',
      fields: ['facility_name', 'first_name', 'last_name'],
    },
    width: widthMapping.name,
  },
  {
    title: 'Type',
    key: 'address_type',
    width: widthMapping.address_type,
  },
  {
    title: 'State',
    key: 'state',
    align: 'center',
    highlightOptions: {
      color: 'light_blue',
      field: 'state',
    },
    width: widthMapping.state,
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
    highlightOptions: {
      color: 'light_blue',
      field: 'city',
    },
    oneString: true,
    width: widthMapping.city,
  },
  {
    title: 'Address Line 1',
    key: 'address_line_1',
    highlightOptions: {
      color: 'light_blue',
      field: 'address_line_1',
    },
    oneString: true,
    width: widthMapping.address_line_1,
  },
  {
    title: 'Address Line 2',
    key: 'address_line_2',
    highlightOptions: {
      color: 'light_blue',
      field: 'address_line_2',
    },
    oneString: true,
    width: 80,
  },
  {
    title: 'Zip Code',
    key: 'zip_code',
    highlightOptions: {
      color: 'light_blue',
      field: 'zip_code',
    },
    width: widthMapping.zip_code,
  },
  {
    title: 'Phone',
    key: 'phones',
    highlightOptions: {
      color: 'medium_blue',
      field: 'phone',
    },
    field: 'number',
    boldLocationType: true,
    width: 104,
  },
  {
    title: 'Fax',
    key: 'faxes',
    highlightOptions: {
      color: 'strong_blue',
      field: 'fax',
    },
    field: 'number',
    boldLocationType: true,
    width: widthMapping.faxes,
  },
  {
    title: 'Facility Profile',
    key: 'facility_profile',
    align: 'center',
    width: 100,
  },
  {
    title: 'Skip On Import',
    key: 'skip_on_import',
    align: 'center',
    width: 80,
    render: text => (text ? 'Yes' : 'No'),
  },
  {
    title: 'Organization Name',
    key: 'organization_name',
    width: 100,
  },
  {
    title: 'Organization ID',
    key: 'organization_id',
    align: 'center',
    width: 100,
  },
]

export const vendorDoctorTableColumns = [
  {
    title: 'Name',
    key: 'name',
    link: true,
    highlightOptions: {
      color: 'light_blue',
      fields: ['facility_name', 'first_name', 'last_name'],
    },
    width: widthMapping.name,
  },
  {
    title: 'Type',
    key: 'address_type',
    width: widthMapping.address_type,
  },
  {
    title: 'State',
    key: 'state',
    align: 'center',
    highlightOptions: {
      color: 'light_blue',
      field: 'state',
    },
    width: widthMapping.state,
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
    highlightOptions: {
      color: 'light_blue',
      field: 'city',
    },
    oneString: true,
    width: widthMapping.city,
  },
  {
    title: 'Address Line 1',
    key: 'address_line_1',
    highlightOptions: {
      color: 'light_blue',
      field: 'address_line_1',
    },
    oneString: true,
    width: widthMapping.address_line_1,
  },
  {
    title: 'Address Line 2',
    key: 'address_line_2',
    highlightOptions: {
      color: 'light_blue',
      field: 'address_line_2',
    },
    oneString: true,
    width: 80,
  },
  {
    title: 'Zip Code',
    key: 'zip_code',
    highlightOptions: {
      color: 'light_blue',
      field: 'zip_code',
    },
    width: widthMapping.zip_code,
  },
  {
    title: 'Phone',
    key: 'phones',
    highlightOptions: {
      color: 'medium_blue',
      field: 'phone',
    },
    field: 'number',
    boldLocationType: true,
    width: 104,
  },
  {
    title: 'Fax',
    key: 'faxes',
    highlightOptions: {
      color: 'strong_blue',
      field: 'fax',
    },
    field: 'number',
    boldLocationType: true,
    width: widthMapping.faxes,
  },
  {
    title: 'Facility Profile',
    key: 'facility_profile',
    align: 'center',
    width: 100,
  },
  {
    title: 'Skip On Import',
    key: 'skip_on_import',
    align: 'center',
    width: 80,
    render: text => (text ? 'Yes' : 'No'),
  },
  {
    title: 'Organization Name',
    key: 'organization_name',
    width: 100,
  },
  {
    title: 'Organization ID',
    key: 'organization_id',
    align: 'center',
    width: 100,
  },
]

export const doctorTableColumns = [
  {
    title: 'Name',
    key: 'name',
    link: true,
    highlightOptions: {
      color: 'light_blue',
      fields: ['facility_name', 'first_name', 'last_name'],
    },
    width: widthMapping.name,
  },
  {
    title: 'Vendors',
    key: 'vendor_relations',
    align: 'center',
  },
  {
    title: 'Type',
    key: 'address_type',
    align: 'center',
    width: 30,
  },
  {
    title: 'State',
    key: 'state',
    align: 'center',
    highlightOptions: {
      color: 'light_blue',
      field: 'state',
    },
    width: widthMapping.state,
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
    highlightOptions: {
      color: 'light_blue',
      field: 'city',
    },
    oneString: true,
    width: widthMapping.city,
  },
  {
    title: 'Address Line 1',
    key: 'address_line_1',
    highlightOptions: {
      color: 'light_blue',
      field: 'address_line_1',
    },
    oneString: true,
    width: widthMapping.address_line_1,
  },
  {
    title: 'Address Line 2',
    key: 'address_line_2',
    highlightOptions: {
      color: 'light_blue',
      field: 'address_line_2',
    },
    oneString: true,
    width: widthMapping.address_line_2,
  },
  {
    title: 'Zip Code',
    key: 'zip_code',
    highlightOptions: {
      color: 'light_blue',
      field: 'zip_code',
    },
    width: widthMapping.zip_code,
  },
  {
    title: 'Phone',
    key: 'phones',
    highlightOptions: {
      color: 'medium_blue',
      field: 'phone',
    },
    field: 'number',
    boldLocationType: true,
    width: widthMapping.phones,
  },
  {
    title: 'Fax',
    key: 'faxes',
    highlightOptions: {
      color: 'strong_blue',
      field: 'fax',
    },
    field: 'number',
    boldLocationType: true,
    width: widthMapping.faxes,
  },
  {
    title: 'Facilities',
    key: 'related_facilities',
    align: 'center',
    // eslint-disable-next-line react/display-name
    render: (text, column, data) => {
      const { id } = data
      const path = `/${DOCTOR}/${id}/`
      return <Link to={{ pathname: path, data: { autoscroll: true } }}>{text}</Link>
    },
    width: 68,
  },
  {
    title: 'Facility Profile',
    key: 'facility_profile',
    align: 'center',
    width: 100,
  },
]

export const exceptionsColumns = [
  {
    title: 'CMS Completed',
    key: 'is_completed',
    width: 85,
    align: 'center',
    // eslint-disable-next-line react/display-name
    render: text => text && <CheckOutlined style={{ color: '#1890ff' }} />,
  },
  {
    title: 'Status',
    key: 'status',
    // eslint-disable-next-line react/display-name
    render: (text, column, { status }) => (
      <Tag color={mapStatusColor(status)}>{status}</Tag>
    ),
    width: 125,
  },
  {
    title: 'Jaia Case ID',
    key: 'id',
    link: true,
    target: '_blank',
    width: 80,
  },
  {
    title: 'CMS Case ID',
    key: 'cms_case_id',
    width: 100,
  },
  {
    title: 'Date  |  Time',
    key: 'created_at',
    width: 160,
    date: true,
  },
  {
    title: 'Editor',
    key: 'editor',
    link: true,
    path: 'users',
    ellipsis: true,
    // eslint-disable-next-line react/display-name
    render: (text, { path }, { editor }, isUserActionAllowed) => (
      <EditorCell editor={editor} path={path} isUserActionAllowed={isUserActionAllowed} />
    ),
    width: 160,
  },
  {
    title: 'Reason',
    key: 'reason',
    width: 180,
  },
  {
    key: 'reported_by_retriever',
    width: 100,
    title: 'Reported By Retriever',
    align: 'center',
    // eslint-disable-next-line react/display-name
    render: isReportedByRetriever =>
      isReportedByRetriever && (
        <WarningOutlined
          className="search-table__link-icon"
          style={{ color: '#A8071A' }}
        />
      ),
  },
]

export const exceptionColumnCompact = [
  {
    title: 'Status',
    key: 'status',
    // eslint-disable-next-line react/display-name
    render: (text, column, { status }) => (
      <Tag color={mapStatusColor(status)}>{status}</Tag>
    ),
    width: 125,
  },
  {
    title: 'CMS Case ID',
    key: 'cms_case_id',
    width: 100,
  },
  {
    title: 'CMS Contact Form',
    key: 'cms_contact_form',
    // eslint-disable-next-line react/display-name
    render: text => (
      <>
        {text && (
          <a target="_blank" rel="noreferrer" href={text}>
            View
          </a>
        )}
      </>
    ),
    width: 150,
  },
  {
    title: 'Original Provider Name',
    key: 'original_provider_name',
    width: 150,
  },
  {
    title: 'Date  |  Time',
    key: 'created_at',
    width: 160,
    date: true,
  },
  {
    title: 'Reason',
    key: 'reason',
    width: 180,
  },
  {
    title: 'Goals',
    key: 'id',
    customRender: true,
  },
]

export const caseInformationColumns = [
  {
    title: 'Search options',
    key: 'suggestion_type',
    width: 75,
  },
  {
    title: 'Links',
    key: 'links',
    width: 100,
  },
  {
    title: 'Name',
    key: 'name',
    highlightOptions: {
      color: 'light_blue',
      fields: ['facility_name', 'first_name', 'last_name'],
    },
    width: widthMapping.name,
  },
  {
    title: 'State',
    key: 'state',
    align: 'center',
    oneString: true,
    width: widthMapping.state,
    highlightOptions: {
      color: 'light_blue',
      field: 'state',
    },
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
    highlightOptions: {
      color: 'light_blue',
      field: 'city',
    },
    oneString: true,
    width: widthMapping.city,
  },
  {
    title: 'Address Line 1',
    key: 'address_line_1',
    highlightOptions: {
      color: 'light_blue',
      field: 'address_line_1',
    },
    oneString: true,
    width: widthMapping.address_line_1,
  },

  {
    title: 'Address Line 2',
    key: 'address_line_2',
    width: widthMapping.address_line_2,
    highlightOptions: {
      color: 'light_blue',
      field: 'address_line_2',
    },
    oneString: true,
  },
  {
    title: 'Zip Code',
    key: 'zip_code',
    width: widthMapping.zip_code,
    highlightOptions: {
      color: 'light_blue',
      field: 'zip_code',
    },
  },
  {
    title: 'Phone',
    key: 'phone',
    highlightOptions: {
      color: 'medium_blue',
      field: 'phone',
    },
    width: widthMapping.phones,
  },
  {
    title: 'Fax',
    key: 'fax',
    highlightOptions: {
      color: 'strong_blue',
      field: 'fax',
    },
    width: widthMapping.faxes,
  },
]

export const caseInformationHistoryColumns = [
  {
    title: 'Provider',
    key: 'suggestion_type',
    width: 75,
  },
  {
    title: 'Name',
    key: 'name',
    width: widthMapping.name,
  },
  {
    title: 'State',
    key: 'state',
    align: 'center',
    oneString: true,
    width: widthMapping.state,
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
    oneString: true,
  },
  {
    title: 'Address Line 1',
    key: 'address_line_1',
    oneString: true,
  },

  {
    title: 'Address Line 2',
    key: 'address_line_2',
    oneString: true,
  },
  {
    title: 'Zip Code',
    key: 'zip_code',
    width: widthMapping.zip_code,
  },
  {
    title: 'Phone',
    key: 'phone',
    width: widthMapping.phones,
  },
  {
    title: 'Fax',
    key: 'fax',
    width: widthMapping.faxes,
  },
]

export const caseFeedbackLoopColumns = [
  {
    title: 'Provider Name',
    dataIndex: 'provider_name',
    key: 'provider_name',
  },
  {
    title: 'Contact Name',
    dataIndex: 'contact_name',
    key: 'contact_name',
    width: widthMapping.name,
  },
  {
    title: 'State',
    key: 'state',
    align: 'center',
    oneString: true,
    width: widthMapping.state,
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
  },
  {
    title: 'Address Line 1',
    key: 'address_line_1',
  },

  {
    title: 'Address Line 2',
    key: 'address_line_2',
  },
  {
    title: 'Zip Code',
    key: 'zip_code',
    width: widthMapping.zip_code,
    oneString: true,
  },
  {
    title: 'Phone',
    key: 'phone',
    width: widthMapping.phones,
    oneString: true,
  },
  {
    title: 'Fax',
    key: 'fax',
    width: widthMapping.faxes,
    oneString: true,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
]

export const clusterTableColumns = [
  {
    title: 'Name',
    key: 'name',
    avatar: false,
    link: true,
    width: widthMapping.name,
  },
  {
    title: 'Health Network',
    key: 'health_network',
    link: true,
    width: 120,
    // eslint-disable-next-line react/display-name
    render: (text = []) => {
      const [healthNetwork] = text
      if (!healthNetwork) return <></>
      const path = `/${HEALTH_NETWORK}/${healthNetwork.id}/`

      return (
        <Link to={{ pathname: path }}>
          <Space>{healthNetwork.name}</Space>
        </Link>
      )
    },
  },
  {
    title: 'Vendors',
    key: 'vendor_relations',
    align: 'center',
  },
  {
    title: 'Type',
    key: 'address_type',
    align: 'center',
    width: widthMapping.address_type,
  },
  {
    title: 'State',
    key: 'state',
    align: 'center',
    oneString: true,
    width: widthMapping.state,
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
    oneString: true,
    width: widthMapping.city,
  },
  {
    title: 'Address Line 1',
    key: 'address_line_1',
    oneString: true,
    width: widthMapping.address_line_1,
  },
  {
    title: 'Address Line 2',
    key: 'address_line_2',
    oneString: true,
    width: widthMapping.address_line_2,
  },
  {
    title: 'Zip Code',
    key: 'zip_code',
    width: widthMapping.zip_code,
  },
  {
    title: 'Phone',
    key: 'phones',
    field: 'number',
    boldLocationType: true,
    width: widthMapping.phones,
  },
  {
    title: 'Fax',
    key: 'faxes',
    field: 'number',
    boldLocationType: true,
    width: widthMapping.faxes,
  },
]

export const healthNetworkTableColumns = [
  {
    title: 'Name',
    key: 'name',
    avatar: false,
    link: true,
  },
  {
    title: 'Doctors',
    key: 'doctors_counter',
    align: 'center',
    width: 100,
  },
  {
    title: 'Facilities',
    key: 'facilities_counter',
    align: 'center',
    width: 100,
  },
]

export const vendorsTableColumns = [
  {
    title: 'Name',
    key: 'name',
    avatar: false,
    link: true,
  },
  {
    title: 'Type',
    key: 'type',
    align: 'center',
    width: 120,
  },
  {
    title: 'Priority',
    key: 'priority',
    align: 'center',
    width: 90,
  },
  {
    title: 'Critical',
    key: 'critical',
    align: 'center',
    width: 90,
    render: text => (text ? 'Yes' : 'No'),
  },
  {
    title: 'Retriever CMS ID',
    key: 'retriever_cms_id',
    align: 'center',
    width: 110,
  },
  {
    title: 'Vendor CMS ID',
    key: 'vendor_cms_id',
    align: 'center',
    width: 100,
  },
  {
    title: 'Disabled',
    key: 'is_disabled',
    align: 'center',
    width: 90,
    render: text => (text ? 'Yes' : 'No'),
  },
]

export const caseHistoryColumns = [
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name',
    oneString: true,
    width: widthMapping.name,
  },
  {
    title: 'State',
    key: 'state',
    align: 'center',
    dataIndex: 'state',
    oneString: true,
    width: widthMapping.state,
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
    oneString: true,
    width: widthMapping.city,
  },
  {
    title: 'Address Line 1',
    key: 'address_line_1',
    dataIndex: 'address_line_1',
    oneString: true,
    width: widthMapping.address_line_1,
  },
  {
    title: 'Address Line 2',
    key: 'address_line_2',
    dataIndex: 'address_line_2',
    oneString: true,
    width: widthMapping.address_line_2,
  },
  {
    title: 'Zip Code',
    key: 'zip_code',
    dataIndex: 'zip_code',
    width: widthMapping.zip_code,
  },
  {
    title: 'Phone',
    key: 'phone',
    dataIndex: 'phone',
    width: widthMapping.phones,
  },
  {
    title: 'Fax',
    key: 'fax',
    dataIndex: 'fax',
    width: widthMapping.faxes,
  },
]

export const copyCompanyList = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'CMS ID',
    dataIndex: 'cms_id',
    key: 'cms_id',
  },
  {
    title: 'Site ID',
    dataIndex: 'site_id',
    key: 'site_id',
  },
  {
    title: 'Case Count',
    dataIndex: 'case_count',
    key: 'case_count',
  },
]

export const facilityMRDColumns = [
  {
    title: 'Name',
    key: 'name',
    avatar: false,
    link: true,
    width: widthMapping.name,
  },
  {
    title: 'Health Network',
    key: 'health_network',
    link: true,
    width: 120,
    // eslint-disable-next-line react/display-name
    render: (text = []) => {
      const [healthNetwork] = text
      if (!healthNetwork) return <></>
      const path = `/${HEALTH_NETWORK}/${healthNetwork.id}/`

      return (
        <Link to={{ pathname: path }}>
          <Space>{healthNetwork.name}</Space>
        </Link>
      )
    },
  },
  {
    title: 'Vendors',
    key: 'vendor_relations',
    align: 'center',
  },
  {
    title: 'Type',
    key: 'address_type',
    align: 'center',
  },
  {
    title: 'State',
    key: 'state',
    align: 'center',
    oneString: true,
    width: widthMapping.state,
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
    oneString: true,
    width: widthMapping.city,
  },
  {
    title: 'Address Line 1',
    key: 'address_line_1',
    oneString: true,
    width: widthMapping.address_line_1,
  },
  {
    title: 'Address Line 2',
    key: 'address_line_2',
    oneString: true,
    width: widthMapping.address_line_2,
  },
  {
    title: 'Zip Code',
    key: 'zip_code',
    width: widthMapping.zip_code,
  },
  {
    title: 'Phone',
    key: 'phones',
    field: 'number',
    boldLocationType: true,
    width: widthMapping.phones,
  },
  {
    title: 'Fax',
    key: 'faxes',
    field: 'number',
    boldLocationType: true,
    width: widthMapping.faxes,
  },
]

export const facilityProfilesColumns = [
  {
    title: 'Provider Name',
    key: 'name',
    avatar: false,
    link: true,
    highlightOptions: {
      color: 'light_blue',
      fields: ['facility_name', 'first_name', 'last_name'],
    },
    width: widthMapping.name,
  },
  {
    title: 'Health Network',
    key: 'health_network',
    link: true,
    width: 120,
    // eslint-disable-next-line react/display-name
    render: (text = []) => {
      const [healthNetwork] = text
      if (!healthNetwork) return <></>
      const path = `/${HEALTH_NETWORK}/${healthNetwork.id}/`

      return (
        <Link to={{ pathname: path }}>
          <Space>{healthNetwork.name}</Space>
        </Link>
      )
    },
  },
  {
    title: 'Main Retriever',
    width: 120,
    permission: 'view_retriever',
    render: text =>
      [text?.main_retriever_data?.contname, text?.main_retriever_data?.fullname]
        .filter(Boolean)
        .join(' '),
  },
  {
    title: 'Vendors',
    key: 'vendor_relations',
    align: 'center',
  },
  {
    title: 'Type',
    key: 'address_type',
    align: 'center',
    width: widthMapping.address_type,
  },
  {
    title: 'State',
    key: 'state',
    align: 'center',
    oneString: true,
    highlightOptions: {
      color: 'light_blue',
      field: 'state',
    },
    width: widthMapping.state,
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
    oneString: true,
    highlightOptions: {
      color: 'light_blue',
      field: 'city',
    },
    width: widthMapping.city,
  },
  {
    title: 'Address Line 1',
    key: 'address_line_1',
    oneString: true,
    highlightOptions: {
      color: 'light_blue',
      field: 'address_line_1',
    },
    width: widthMapping.address_line_1,
  },
  {
    title: 'Address Line 2',
    key: 'address_line_2',
    oneString: true,
    highlightOptions: {
      color: 'light_blue',
      field: 'address_line_2',
    },
    width: widthMapping.address_line_2,
  },
  {
    title: 'Zip Code',
    key: 'zip_code',
    highlightOptions: {
      color: 'light_blue',
      field: 'zip_code',
    },
    width: widthMapping.zip_code,
  },
  {
    title: 'Phone',
    key: 'phones',
    field: 'number',
    boldLocationType: true,
    highlightOptions: {
      color: 'medium_blue',
      field: 'phone',
    },
    width: widthMapping.phones,
  },
  {
    title: 'Fax',
    key: 'faxes',
    field: 'number',
    boldLocationType: true,
    highlightOptions: {
      color: 'strong_blue',
      field: 'fax',
    },
    width: widthMapping.faxes,
  },
]

export const activityLogUsersColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Last Name',
    dataIndex: 'lastname',
    key: 'lastname',
  },
  {
    title: 'First Name',
    dataIndex: 'firstname',
    key: 'firstname',
  },
  {
    title: 'Role ID',
    dataIndex: 'group_ids',
    key: 'group_ids',
    align: 'center',
  },
  {
    title: 'Is Active',
    dataIndex: 'is_active',
    key: 'is_active',
    align: 'center',
    render: text => text?.toString(),
  },
  {
    title: 'Is Super User',
    dataIndex: 'is_superuser',
    key: 'is_superuser',
    align: 'center',
    render: text => text?.toString(),
  },
]

export const activityLogFacilityColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Type',
    key: 'address_type',
    align: 'center',
  },
  {
    title: 'State',
    key: 'state',
    align: 'center',
    highlightOptions: {
      color: 'light_blue',
      field: 'state',
    },
    width: widthMapping.state,
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
    highlightOptions: {
      color: 'light_blue',
      field: 'city',
    },
    oneString: true,
    width: widthMapping.city,
  },
  {
    title: 'Address Line 1',
    key: 'address_line_1',
    highlightOptions: {
      color: 'light_blue',
      field: 'address_line_1',
    },
    oneString: true,
    width: widthMapping.address_line_1,
  },
  {
    title: 'Address Line 2',
    key: 'address_line_2',
    highlightOptions: {
      color: 'light_blue',
      field: 'address_line_2',
    },
    oneString: true,
    width: widthMapping.address_line_2,
  },
  {
    title: 'Zip Code',
    key: 'zip_code',
    highlightOptions: {
      color: 'light_blue',
      field: 'zip_code',
    },
    width: widthMapping.zip_code,
  },
  {
    title: 'Phone',
    key: 'phones',
    render: text =>
      text?.map((val, index) => {
        const type = val.type ? `${val.type} ` : ''
        const extension = val.phone_extension ? ` (${val.phone_extension})` : ''
        return (
          <div key={`key_${val.number}_${index + 1}`}>
            <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
              {val.number ? (
                <>
                  {type}
                  <span className="bold">{parseValues(val, 'number')}</span>
                  {extension}
                </>
              ) : (
                '-'
              )}
            </span>
            <br />
          </div>
        )
      }),
  },
  {
    title: 'Fax',
    key: 'faxes',
    render: text => {
      const hasData = text?.length
      const result = text?.map((val, index) => {
        const type = val.type ? `${val.type} ` : ''
        return (
          <div key={`key_${val.number}_${index + 1}`}>
            <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
              {val.number ? (
                <>
                  {type}
                  <span className="bold">{parseValues(val, 'number')}</span>
                </>
              ) : (
                '-'
              )}
            </span>
            <br />
          </div>
        )
      })
      return hasData ? result : '-'
    },
  },
  {
    title: 'Email',
    dataIndex: 'emails',
    key: 'emails',
    render: text =>
      text?.map((val, index) => {
        const type = val.type ? `${val.type} ` : ''
        return (
          <div key={`key_${val.address}_${index + 1}`}>
            <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
              {val.address ? (
                <>
                  {type}
                  <span className="bold">{val.address}</span>
                </>
              ) : (
                '-'
              )}
            </span>
            <br />
          </div>
        )
      }) || '-',
  },
]

export const mainRetrieverPastColumns = [
  {
    title: 'Name',
    key: 'fullname',
  },
  {
    title: 'Contname',
    key: 'contname',
  },
  {
    title: 'CMS ID',
    key: 'cms_id',
  },
  {
    title: 'Last Case Date',
    key: 'last_case_date',
    render: data => data && formatDate(data),
  },
  {
    title: 'Case Count',
    key: 'total_cases',
  },
]

export const mainRetrieverColumns = [
  {
    title: 'Name',
    key: 'fullname',
  },
  {
    title: 'Contname',
    key: 'contname',
  },
  {
    title: 'CMS ID',
    key: 'cms_id',
  },
]

export const specialAuthorizationColumns = [
  {
    title: 'Insurance',
    key: 'insurance_company_name',
  },
  {
    title: 'Policy Type',
    key: 'policy_type_name',
  },
  {
    title: 'Accepted Authorizations',
    key: 'accepted_authorizations_percent',
    render: text => `${text} %`,
  },
]

export const primaryCopyCompanyColumns = [
  {
    title: 'Name',
    key: 'name',
  },
  {
    title: 'CMS ID',
    key: 'cms_id',
  },
  {
    title: 'Site ID',
    key: 'site_id',
  },
  {
    title: 'Case Count',
    key: 'case_count',
  },
]

import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Form, Input, Tabs as Tab, Button, Switch } from 'antd'
import { Formik } from 'formik'
import { SearchOutlined, SaveOutlined } from '@ant-design/icons'

import Tabs from '../../Tabs'
import FormObserver from '../../../shared/FormObserver/FormObserver'

import {
  getCopyCompanies,
  resetState as resetCopyCompaniesState,
} from '../../../../state/modules/copyCompanies'
import useDispatchHttp from '../../../../hooks/dispatchHttpHandler'
import { LOADING_STATUS } from '../../../../constants'
import { primaryCopyCompanyColumns } from '../../../../data'
import Table from '../../Tables/SearchTable'

const TABS = {
  PAST: 'past',
  ALL: 'all',
}

const SetCopyCompanyForm = ({
  initialValues,
  onSubmit,
  entityId,
  onClose,
  isAutoDetection,
  isUserActionAllowed,
}) => {
  const dispatch = useDispatch()
  // eslint-disable-next-line no-unused-vars
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 15,
  })
  const [searchParams, setSearchParams] = useState({
    facility_profile: initialValues.id,
  })

  const [isAutoDetectionEnabled, setIsAutoDetectionEnabled] = useState(isAutoDetection)
  // eslint-disable-next-line
  const [activeTab, setActiveTab] = useState(TABS.PAST)
  const dispatchHttp = useDispatchHttp()
  const copyCompaniesList = useSelector(state => state.copyCompanies.data)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [siteIds, setSiteIds] = useState({})
  const { count } = useSelector(state => state.copyCompanies)
  const loadingStatus = useSelector(state => state.copyCompanies.status)

  const handleSearch = useCallback(
    e => {
      setSearchParams(state => ({ ...state, search: e.target.value }))
      dispatchHttp(
        getCopyCompanies({
          ...searchParams,
          search: e.target.value,
          ...pagination,
        })
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatchHttp, searchParams]
  )

  const handleKeyPress = e => {
    if ((e.charCode || e.keyCode) === 13) {
      e.preventDefault()
    }
  }

  const handleChangeTab = tab => {
    let params
    setPagination({
      page: 1,
      pageSize: 15,
    })
    if (tab === TABS.PAST) {
      params = {
        facility_profile: initialValues.id,
      }
    } else {
      params = {
        facility_profile: null,
      }
    }

    setSearchParams(state => ({ ...state, ...params }))
    dispatchHttp(getCopyCompanies({ ...searchParams, ...params, ...pagination }))
    setActiveTab(tab)
  }

  useEffect(() => {
    dispatchHttp(
      getCopyCompanies({
        facility_profile: initialValues.id,
        ...pagination,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchHttp, initialValues])

  const initValues = {
    copy_company_id: initialValues.copy_company_id,
  }

  copyCompaniesList.forEach((item, index) => {
    initValues[`site_id_${index}_${item.id}`] = item.site_id || null
    if (item.is_primary) {
      initValues.selected_id = `${index}_${item.id}`
    }
  })

  useEffect(
    () => () => {
      dispatch(resetCopyCompaniesState())
    },
    [dispatch]
  )

  const primaryCopyCompanyColumnsEditable = [
    {
      title: 'Name',
      key: 'name',
    },
    {
      title: 'CMS ID',
      key: 'cms_id',
    },
    {
      title: 'Site ID',
      key: 'site_id',
      align: 'center',
      width: 120,
      // eslint-disable-next-line react/display-name
      render: (text, col, copyCompany) => (
        <Input
          size="small"
          onChange={({ target: { value } }) => {
            const key = `site_id_${copyCompany.id}`
            setSiteIds({ ...siteIds, [key]: value })
          }}
        />
      ),
    },
  ]

  const list = (setFieldValue, columns) => {
    const columnsPrepared =
      isUserActionAllowed('update_copy_company_facilityprofile') && columns
        ? columns
        : primaryCopyCompanyColumns

    return (
      <Table
        columns={columnsPrepared}
        totalData={copyCompaniesList}
        onLoadData={getCopyCompanies}
        onFormatData={data =>
          data.map((item, index) => ({
            ...item,
            item_id: item.id,
            id: `${index}_${item.id}`,
          }))
        }
        formData={searchParams}
        loadingStatus={loadingStatus}
        loading={loadingStatus === LOADING_STATUS}
        customRowSelection={{
          key: 'is_primary',
          disabled:
            !isUserActionAllowed('update_copy_company_facilityprofile') ||
            !isAutoDetectionEnabled,
          onSelect: newSelectedRowKeys => {
            setSelectedRowKeys([newSelectedRowKeys])
            setFieldValue('selected_id', newSelectedRowKeys)
          },
        }}
        totalCount={count}
        onPageChange={setPagination}
        pageSize={pagination.pageSize}
      />
    )
  }
  return (
    <Formik
      initialValues={{
        ...initValues,
        auto_detection: isAutoDetection,
      }}
      enableReinitialize
      onSubmit={async () => {
        try {
          const listOfIds = Object.keys(siteIds).length ? siteIds : initValues
          await onSubmit(
            entityId,
            {
              ...listOfIds,
              selected_id: selectedRowKeys && selectedRowKeys[0],
            },
            isAutoDetectionEnabled
          )
          onClose()
        } catch (err) {
          /* empty */
        }
      }}
    >
      {({ values, handleSubmit, dirty, setFieldValue }) => (
        <Form onFinish={handleSubmit} initialValues={values} onKeyDown={handleKeyPress}>
          <FormObserver
            onChange={data => {
              // because of enableReinitialize auto_detection sets to its default on tab change or pagination
              // keep auto_detection between values being reset
              if (data.auto_detection !== isAutoDetectionEnabled) {
                setFieldValue('auto_detection', isAutoDetectionEnabled)
              }
            }}
          />
          <Tabs
            defaultActiveKey="past"
            fullWidth
            theme="dark"
            destroyInactiveTabPane
            tabBarExtraContent={
              <Form layout="inline" className="sort-form" component="div">
                <Row>
                  <Col>
                    <Form.Item style={{ width: '300px', marginBottom: 0 }}>
                      <Input
                        prefix={
                          <Button type="link" size="small" icon={<SearchOutlined />} />
                        }
                        placeholder="Name, CMS ID, Site ID"
                        onPressEnter={handleSearch}
                      />
                    </Form.Item>
                  </Col>
                  {isUserActionAllowed('update_copy_company_auto_detection') && (
                    <Col>
                      <Form.Item
                        label="Auto Detection"
                        className="sort-form__select-field"
                        style={{ marginBottom: 0 }}
                      >
                        <Switch
                          defaultChecked={isAutoDetectionEnabled}
                          checked={isAutoDetectionEnabled}
                          name="auto_detection"
                          key="switch"
                          onChange={checked => {
                            setIsAutoDetectionEnabled(checked)
                            setFieldValue('auto_detection', checked)
                          }}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Form>
            }
            tabsProps={{
              size: 'small',
              tabBarGutter: 24,
              onChange: handleChangeTab,
            }}
          >
            <Tab.TabPane tab="Past Copy Companies" key={TABS.PAST}>
              {list(setFieldValue)}
            </Tab.TabPane>
            <Tab.TabPane tab="All" key={TABS.ALL}>
              {list(setFieldValue, primaryCopyCompanyColumnsEditable)}
            </Tab.TabPane>
          </Tabs>
          <Row justify="space-between">
            <Col>
              <Button onClick={onClose}>Close window</Button>
            </Col>
            <Col>
              {(isUserActionAllowed('update_copy_company_facilityprofile') ||
                isUserActionAllowed('update_copy_company_auto_detection')) && (
                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  htmlType="submit"
                  disabled={!dirty}
                >
                  Save Changes
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default SetCopyCompanyForm

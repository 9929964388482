import React, { useState } from 'react'
import { Row, Col, Form, Select, Button, Switch } from 'antd'
import { Formik } from 'formik'
import { SaveOutlined } from '@ant-design/icons'

import { communicationOptions, communicationMethodOptions } from '../../../../data'

const CommunicationMethodForm = ({
  initialValues,
  onSubmit,
  entityId,
  onClose,
  isAutoDetection,
}) => {
  const [isAutoDetectionEnabled, setIsAutoDetectionEnabled] = useState(isAutoDetection)
  const [communicationMethod, setCommunicationMethod] = useState(
    initialValues.communication_method
  )

  const handleKeyPress = e => {
    if ((e.charCode || e.keyCode) === 13) {
      e.preventDefault()
    }
  }

  return (
    <Formik
      initialValues={{
        communication_method: initialValues.communication_method,
      }}
      onSubmit={async () => {
        try {
          await onSubmit(entityId, communicationMethod, isAutoDetectionEnabled)
          onClose()
        } catch (err) {
          /* empty */
        }
      }}
    >
      {({ values, handleSubmit }) => (
        <Form onFinish={handleSubmit} initialValues={values} onKeyDown={handleKeyPress}>
          <Row>
            <Col>
              <Form.Item label="Auto Detection" className="sort-form__select-field">
                <Switch
                  defaultChecked={isAutoDetectionEnabled}
                  checked={isAutoDetectionEnabled}
                  name="auto_detection"
                  key="switch"
                  onChange={checked => setIsAutoDetectionEnabled(checked)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                label="Method"
                className="communication-method-form__select-field"
              >
                {isAutoDetectionEnabled && (
                  <Select
                    value={initialValues.communication_method}
                    size="middle"
                    className="sort-form__select"
                    onChange={() => {}}
                    options={communicationOptions}
                    placeholder="Select here"
                    style={{ width: '110px' }}
                    disabled={isAutoDetectionEnabled}
                  />
                )}
                {!isAutoDetectionEnabled && (
                  <Select
                    defaultValue={
                      // if communication method is 'unknown' set select to null
                      initialValues.communication_method === 2
                        ? null
                        : initialValues.communication_method
                    }
                    size="middle"
                    className="sort-form__select"
                    onChange={setCommunicationMethod}
                    options={communicationMethodOptions}
                    placeholder="Select here"
                    style={{ width: '110px' }}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row justify="space-between">
            <Col>
              <Button onClick={onClose}>Close window</Button>
            </Col>
            <Col>
              <Button type="primary" icon={<SaveOutlined />} htmlType="submit">
                Save Changes
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default CommunicationMethodForm

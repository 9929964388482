import React, { useEffect, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Table, Button, Row, Col, Form, Spin, Space, Input, Select } from 'antd'
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'

import PageLayout from '../../layouts/DetailPageLayout'
import useDebounce from '../../../hooks/useDebounce'
import useDispatchHttp from '../../../hooks/dispatchHttpHandler'
import { useUser } from '../../../providers/UserProvider'
import {
  fetchAllAssignmentCopyCompanies,
  selectAllAssignmentCopyCompanies,
  isAssignmentCopyCompaniesFetching,
  deleteAssignmentCopyCompany,
  resetState,
} from '../../../state/modules/assignmentCopyCompanies'
import showConfirmDialog from '../../components/Modals/DeleteConfirm'
import { validateSearchParams } from '../../../utils/functions'

const AssignmentCopyCompany = () => {
  const copyCompanies = useSelector(selectAllAssignmentCopyCompanies)
  const isLoading = useSelector(isAssignmentCopyCompaniesFetching)
  const { count } = useSelector(state => state.assignmentCopyCompanies)
  const [searchParams, setSearchParams] = useState({})

  const { isUserActionAllowed } = useUser()
  const dispatchHttp = useDispatchHttp()
  const dispatch = useDispatch()

  const isCreateAllowed = isUserActionAllowed('add_assignmentcopycompany')
  const isChangeAllowed = isUserActionAllowed('change_assignmentcopycompany')
  const isDeleteAllowed = isUserActionAllowed('delete_assignmentcopycompany')

  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
  })

  const handleDeleteAssignment = useCallback(
    async id => {
      await dispatchHttp(
        deleteAssignmentCopyCompany(id),
        'Copy Company Assignment has been successfully removed'
      )
      setParams({
        page: 1,
        pageSize: 10,
      })
      await dispatchHttp(fetchAllAssignmentCopyCompanies({ page: 1, pageSize: 10 }))
    },
    [dispatchHttp]
  )

  const removeAssignmentHandler = useCallback(
    data =>
      showConfirmDialog({
        title: 'Are you sure you want to remove Copy Company Assignment?',
        data,
        handleConfirm: handleDeleteAssignment,
        okText: 'Remove Assignment',
      }),
    [handleDeleteAssignment]
  )

  const renderActionsColumn = text => (
    <Space>
      {isChangeAllowed && (
        <Button
          type="text"
          icon={
            <Link to={`/copy-company-assignment/${text.id}`}>
              <EditOutlined style={{ color: '#BFBFBF', fontSize: 18 }} />
            </Link>
          }
        />
      )}
      {isDeleteAllowed && (
        <Button
          type="text"
          onClick={() => {
            removeAssignmentHandler(text.id)
          }}
          icon={<DeleteOutlined style={{ color: '#BFBFBF', fontSize: 18 }} />}
        />
      )}
    </Space>
  )

  const tableColumns = [
    {
      title: 'Name',
      key: 'name',
      width: 250,
      render: text => {
        if (isChangeAllowed) {
          return (
            <Link to={`/copy-company-assignment/${text.id}`}>
              {text.copy_company.name}
            </Link>
          )
        }
        return text.copy_company.name
      },
    },
    {
      title: 'CC CMS ID',
      key: 'cms_id',
      align: 'center',
      render: text => text.copy_company.cms_id,
    },
    {
      title: 'Enabled',
      key: 'is_enabled',
      align: 'center',
      render: text => (text.is_enabled ? 'Yes' : 'No'),
    },
    {
      title: 'Phone Number',
      key: 'phone_number',
      align: 'center',
      render: text => text.phone_number,
    },
    {
      title: 'Site ID',
      key: 'side_id',
      align: 'center',
      render: text => text.site_id,
    },
    {
      title: 'Actions',
      key: 'id',
      align: 'center',
      width: '90px',
      render: text => renderActionsColumn(text),
    },
  ]

  const getColumns = () => {
    const columnsCustom = [...tableColumns.slice(0, 5)]

    if (isChangeAllowed || isDeleteAllowed) {
      columnsCustom.push(tableColumns[5])
    }

    return columnsCustom
  }

  const allowedColumns = getColumns()

  const handleChangeSearch = useDebounce(e => {
    const { value } = e.target
    setParams({
      page: 1,
      pageSize: 10,
    })
    setSearchParams({ ...searchParams, search: value.trim() })
    dispatchHttp(
      fetchAllAssignmentCopyCompanies({
        ...searchParams,
        search: value.trim(),
      })
    )
  }, 500)

  const onChange = (page, pageSize) => {
    dispatchHttp(fetchAllAssignmentCopyCompanies({ ...searchParams, page, pageSize }))
    setParams(prev => ({ ...prev, page, pageSize }))
  }

  const handleChangeEnabledFilter = state => {
    const newSearchParams = validateSearchParams({
      ...searchParams,
      enabled: state,
    })

    setParams({
      page: 1,
      pageSize: 10,
    })

    setSearchParams(newSearchParams)

    dispatchHttp(fetchAllAssignmentCopyCompanies(newSearchParams))
  }

  useEffect(() => {
    dispatchHttp(
      fetchAllAssignmentCopyCompanies({
        page: params.page,
        pageSize: params.pageSize,
      })
    )
    // eslint-disable-next-line
  }, [dispatchHttp])

  useEffect(
    () => () => {
      dispatch(resetState())
    },
    [dispatch]
  )

  return (
    <PageLayout
      title={
        <>
          Copy Company Assignment
          <span className="badge">{count}</span>
        </>
      }
      extraButtons={
        isCreateAllowed ? (
          <Link to="/copy-company-assignment/add-new-cc-assignment">
            <Button type="primary" icon={<PlusOutlined style={{ fontSize: '12px' }} />}>
              Add New Assignment
            </Button>
          </Link>
        ) : (
          <></>
        )
      }
    >
      <Spin spinning={isLoading}>
        <Row
          gutter={[16, 32]}
          style={{
            background: '#fff',
            padding: '14px 12px',
            justifyContent: 'flex-end',
            margin: 0,
          }}
        >
          <Form
            layout="inline"
            className="sort-form"
            component="div"
            initialValues={{ search: '', enabled: 'all' }}
            size="small"
          >
            <Row>
              <Col>
                <Form.Item label="Enabled" name="enabled" size="small">
                  <Select style={{ width: 70 }} onChange={handleChangeEnabledFilter}>
                    <Select.Option value="all">All</Select.Option>
                    <Select.Option value="true">Yes</Select.Option>
                    <Select.Option value="false">No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item label="Search" name="search" size="small">
                  <Input
                    onChange={handleChangeSearch}
                    type="text"
                    className="search-input"
                    placeholder="CC CMS ID, Phone Number or Site ID"
                    style={{ width: 300 }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Row>
        <Table
          scroll={{ x: 'max-content' }}
          columns={allowedColumns}
          pagination={{
            ...params,
            current: params.page,
            total: count,
            onChange,
          }}
          dataSource={copyCompanies}
          bordered
          className="assign-copy-companies-table"
          rowKey="id"
        />
      </Spin>
    </PageLayout>
  )
}

export default AssignmentCopyCompany

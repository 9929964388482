import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Row, Col, Tabs as Tab, Button, Spin } from 'antd'

import MainLayout from '../../layouts/MainLayout'
import SearchLayout from '../../layouts/SearchLayout'
import Tabs from '../../components/Tabs'
import MedicalEntityModal from '../../components/Modals/MedicalEntityModal'
import CreateFacilityForm from '../../components/Forms/FacilityForm'
import CreateDoctorForm from '../../components/Forms/DoctorForm'
import SearchTable from '../../components/Tables/SearchTable/index'
import {
  facilityTableColumns,
  doctorTableColumns,
  initialDoctorValues,
  initialFacilityValues,
} from '../../../data'
import useDispatchHttp from '../../../hooks/dispatchHttpHandler'

import {
  searchCaseDetailsFacilities,
  searchCaseDetailsDoctors,
  selectAllDoctor,
  selectAllFacility,
  resetState as resetSearchState,
} from '../../../state/modules/search'
import {
  createFacility,
  resetState as resetFacilityState,
} from '../../../state/modules/facilities'
import {
  createDoctor,
  resetState as resetDoctorState,
} from '../../../state/modules/doctors'

import { DOCTOR, FACILITY } from '../../../constants'
import { updateCreateFacilitySchema } from '../../../schema/updateCreateFacility'
import { createUpdateDoctorSchema } from '../../../schema/createUpdateDoctor'
import { useUser } from '../../../providers/UserProvider'
import { getHighlightFields } from '../exceptions/helpers/getHighlightFields'
import { formatPhoneNumbers } from '../../../utils/helpers'

const initialDoctorCreateValues = {
  ...initialDoctorValues,
  phone_numbers: initialDoctorValues.phone_numbers.map(item => ({
    ...item,
    verified: true,
  })),
}

const initialFacilityCreateValues = {
  ...initialFacilityValues,
  phone_numbers: initialFacilityValues.phone_numbers.map(item => ({
    ...item,
    verified: true,
  })),
}
const Search = () => {
  const [formValues, setFormValues] = useState({})
  const allFacilities = useSelector(selectAllFacility)
  const allDoctors = useSelector(selectAllDoctor)
  const { count: facilityCount } = useSelector(state => state.search.facility)
  const { count: doctorsCount } = useSelector(state => state.search.doctor)
  const facilityStatus = useSelector(state => state.search.facilityStatus)
  const doctorStatus = useSelector(state => state.search.doctorStatus)

  const dispatchHttp = useDispatchHttp()
  const dispatch = useDispatch()
  const history = useHistory()
  const { isUserActionAllowed } = useUser()
  let activeTab = 'all'

  if (isUserActionAllowed('view_doctor') && !isUserActionAllowed('view_facility')) {
    activeTab = 'doctors'
  }

  if (!isUserActionAllowed('view_doctor') && isUserActionAllowed('view_facility')) {
    activeTab = 'facilities'
  }
  const [activeTabKey, setActiveTabKey] = useState(activeTab)

  const handleShowAllResults = (e, key) => {
    setActiveTabKey(key)
    window.scrollTo(0, 0)
  }

  const handleChangeTab = key => {
    setActiveTabKey(key)
  }

  const handleSearch = useCallback(
    values => {
      if (isUserActionAllowed('view_doctor')) {
        dispatchHttp(
          searchCaseDetailsDoctors({
            data: values,
          })
        )
      }

      if (isUserActionAllowed('view_facility')) {
        dispatchHttp(
          searchCaseDetailsFacilities({
            data: values,
          })
        )
      }

      setFormValues(values)
    },
    // eslint-disable-next-line
    [dispatchHttp, setFormValues]
  )

  const handleCreateFacility = async (_, data) => {
    const resp = await dispatchHttp(
      createFacility({
        ...data,
        phone_numbers: formatPhoneNumbers(data.phone_numbers),
        fax_numbers: formatPhoneNumbers(data.fax_numbers),
      }),
      'Facility has been successfully created'
    )
    history.push(`facility/${resp.id}`)
  }

  const handleCreateDoctor = async (_, data) => {
    const resp = await dispatchHttp(
      createDoctor({
        ...data,
        phone_numbers: formatPhoneNumbers(data.phone_numbers),
        fax_numbers: formatPhoneNumbers(data.fax_numbers),
      }),
      'Doctor has been successfully created'
    )
    history.push(`doctor/${resp.id}`)
  }

  useEffect(
    () => () => {
      dispatch(resetDoctorState())
      dispatch(resetFacilityState())
      dispatch(resetSearchState())
    },
    [dispatch]
  )
  const facilityColumns = isUserActionAllowed('view_doctor')
    ? facilityTableColumns
    : facilityTableColumns.map(item => {
        if (item.key === 'related_doctors') {
          return { ...item, link: null }
        }
        return { ...item }
      })
  const doctorColumns = isUserActionAllowed('view_facility')
    ? doctorTableColumns
    : doctorTableColumns.map(item => {
        if (item.key === 'related_facilities') {
          return { ...item, link: null }
        }
        return { ...item }
      })

  const highlightedFields = getHighlightFields({ primary_search: formValues }, [
    allFacilities,
    allDoctors,
  ])

  return (
    <MainLayout>
      <Spin spinning={false}>
        <SearchLayout
          searchRequest={handleSearch}
          handleSearch={handleSearch}
          handleReset={() => setFormValues({})}
          loadInitData
        >
          <Row gutter={40}>
            <Tabs
              tabsProps={{
                activeKey: activeTabKey,
                onChange: handleChangeTab,
              }}
              fullWidth
              tabBarExtraContent={
                <Row justify="end" gutter={8}>
                  {isUserActionAllowed('add_facility') &&
                    isUserActionAllowed('view_facility') && (
                      <Col>
                        <MedicalEntityModal
                          title="Create New Facility"
                          trigger={
                            <Button ghost type="primary">
                              Create New Facility
                            </Button>
                          }
                          form={
                            <CreateFacilityForm
                              initialValues={initialFacilityCreateValues}
                              onSubmit={handleCreateFacility}
                              validationSchema={updateCreateFacilitySchema}
                              action="create"
                              submitButtonText="Create"
                            />
                          }
                        />
                      </Col>
                    )}

                  {isUserActionAllowed('add_doctor') &&
                    isUserActionAllowed('view_doctor') && (
                      <Col>
                        <MedicalEntityModal
                          title="Create New Doctor"
                          trigger={
                            <Button ghost type="primary">
                              Create New Doctor
                            </Button>
                          }
                          form={
                            <CreateDoctorForm
                              initialValues={initialDoctorCreateValues}
                              onSubmit={handleCreateDoctor}
                              validationSchema={createUpdateDoctorSchema}
                              action="create"
                              submitButtonText="Create"
                            />
                          }
                        />
                      </Col>
                    )}
                </Row>
              }
              topContent
            >
              {isUserActionAllowed('view_facility') &&
                isUserActionAllowed('view_doctor') && (
                  <Tab.TabPane tab="All" key="all">
                    <div className="search-tab">
                      {isUserActionAllowed('view_facility') && (
                        <SearchTable
                          onLoadData={searchCaseDetailsFacilities}
                          tableTitle="Facilities"
                          columns={facilityColumns}
                          entity={FACILITY}
                          onShowMore={e => handleShowAllResults(e, 'facilities')}
                          totalData={allFacilities}
                          totalCount={facilityCount}
                          loadingStatus={facilityStatus}
                          primarySearch={formValues}
                          highlightedFields={highlightedFields}
                        />
                      )}

                      {isUserActionAllowed('view_doctor') && (
                        <SearchTable
                          onLoadData={searchCaseDetailsDoctors}
                          tableTitle="Doctors"
                          columns={doctorColumns}
                          entity={DOCTOR}
                          onShowMore={e => handleShowAllResults(e, 'doctors')}
                          totalData={allDoctors}
                          totalCount={doctorsCount}
                          loadingStatus={doctorStatus}
                          primarySearch={formValues}
                          highlightedFields={highlightedFields}
                        />
                      )}
                    </div>
                  </Tab.TabPane>
                )}
              {isUserActionAllowed('view_facility') && (
                <Tab.TabPane tab="Only Facilities" key="facilities">
                  <div className="search-tab">
                    <SearchTable
                      onLoadData={searchCaseDetailsFacilities}
                      tableTitle="Facilities"
                      columns={facilityColumns}
                      entity={FACILITY}
                      totalData={allFacilities}
                      totalCount={facilityCount}
                      formData={formValues}
                      loadingStatus={facilityStatus}
                      primarySearch={formValues}
                      highlightedFields={highlightedFields}
                    />
                  </div>
                </Tab.TabPane>
              )}
              {isUserActionAllowed('view_doctor') && (
                <Tab.TabPane tab="Only Doctors" key="doctors">
                  <div className="search-tab">
                    <SearchTable
                      onLoadData={searchCaseDetailsDoctors}
                      tableTitle="Doctors"
                      columns={doctorColumns}
                      entity={DOCTOR}
                      totalData={allDoctors}
                      totalCount={doctorsCount}
                      formData={formValues}
                      loadingStatus={doctorStatus}
                      primarySearch={formValues}
                      highlightedFields={highlightedFields}
                    />
                  </div>
                </Tab.TabPane>
              )}
            </Tabs>
          </Row>
        </SearchLayout>
      </Spin>
    </MainLayout>
  )
}

export default Search

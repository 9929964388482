import { createAsyncThunk } from '@reduxjs/toolkit'
import { getPagination, downloadFileByUrl } from '../../../utils/functions'

import ImportLogsService from '../../../services/ImportLogsService'
import WeeklyNpeesUpdatesService from '../../../services/WeeklyNpeesUpdatesService'
import ExtraImportLogsService from '../../../services/ExtraImportLogsService'

const feature = 'importLogs'

export const getImportLogsList = createAsyncThunk(`${feature}/getList`, data =>
  getPagination(data, ImportLogsService.getImportLogsList.bind(ImportLogsService))
)

export const getWeeklyNpeesUpdatesList = createAsyncThunk(
  `getWeeklyNpeesUpdatesList`,
  data =>
    getPagination(
      data,
      WeeklyNpeesUpdatesService.getWeeklyNpeesUpdatesList.bind(WeeklyNpeesUpdatesService)
    )
)

export const getWeeklyNpeesUpdatesById = createAsyncThunk(
  `getWeeklyNpeesUpdatesById`,
  async id => {
    const response = await WeeklyNpeesUpdatesService.getWeeklyNpeesUpdatesById(id)

    return response
  }
)

export const getExtraImportLogsList = createAsyncThunk(`getExtraImportLogsList`, data =>
  getPagination(
    data,
    ExtraImportLogsService.getExtraImportLogsList.bind(ExtraImportLogsService)
  )
)

export const getExtraImportLogById = createAsyncThunk(
  `getExtraImportLogById`,
  async id => {
    const response = await ExtraImportLogsService.getExtraImportLogById(id)
    return response
  }
)

export const getExtraImportLogFile = createAsyncThunk(
  'getExtraImportLogFile',
  async (id, file_name) => {
    const response = await ExtraImportLogsService.getExtraImportLogFileUrl(id)

    downloadFileByUrl(response.link, file_name)
  }
)

export const getImportLogById = createAsyncThunk(`${feature}/getById`, async id => {
  const response = await ImportLogsService.getImportLogById(id)

  return response
})

export const getImportLogFile = createAsyncThunk(
  `${feature}/getFile`,
  async (id, file_name) => {
    const response = await ImportLogsService.getImportLogFileUrl(id)

    downloadFileByUrl(response.link, file_name)
  }
)

export const getExtraImportLogsTypes = createAsyncThunk(
  'getExtraImportLogsTypes',
  data => {
    const response = ExtraImportLogsService.getExtraImportLogsTypes(data)

    return response
  }
)

import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Card, Typography, Tag } from 'antd'
import {
  selectImportLogById,
  isFetchingImportLogs,
  getExtraImportLogById,
  getExtraImportLogFile,
} from '../../../state/modules/importLogs'
import PageLayout from '../../layouts/DetailPageLayout'
import Table from '../../components/Tables/SearchTable'
import useDispatchHttp from '../../../hooks/dispatchHttpHandler'
import { mapImportLogsStatusColor } from '../../../utils/helpers'

const RenderPhoneLogTableTitle = ({ file_name }) => {
  const { id } = useParams()
  const dispatch = useDispatch()

  return (
    <Typography.Link
      onClick={() => {
        dispatch(getExtraImportLogFile(id))
      }}
    >
      {file_name}
    </Typography.Link>
  )
}

const RenderImportLogTableStatus = status => (
  <Tag color={mapImportLogsStatusColor(status)}>{status}</Tag>
)
const detailColumns = [
  {
    title: 'ID',
    key: 'id',
  },
  {
    title: 'Date | Time',
    key: 'started_at',
    date: true,
  },
  {
    title: 'Title',
    key: 'data_file',
    render: RenderPhoneLogTableTitle,
  },
  {
    title: 'Status',
    key: 'status',
    render: RenderImportLogTableStatus,
  },
]
const statisticColumns = [
  {
    title: 'Total Logs',
    key: 'total_logs',
  },
  {
    title: 'Processed',
    key: 'processed',
  },
  {
    title: 'Skipped',
    key: 'skipped',
  },
  {
    title: 'Verified',
    key: 'verified',
  },
]

const ImportLogDetails = () => {
  const dispatchHttp = useDispatchHttp()
  const { id } = useParams()
  const phoneLog = useSelector(state => selectImportLogById(state, id))
  const loading = useSelector(isFetchingImportLogs)
  const title = phoneLog?.data_file?.file_name
  const breadcrumbs = [
    { name: 'Import Log', path: '/data-sync-log' },
    {
      name: title,
      path: `/phone-logs/${phoneLog?.id}`,
    },
  ]

  useEffect(() => {
    dispatchHttp(getExtraImportLogById(id))
  }, [dispatchHttp, id])

  return (
    <PageLayout breadcrumbs={breadcrumbs} title={title}>
      <Card style={{ width: '100%' }}>
        <Table
          columns={detailColumns}
          tableTitle="Import Details"
          loading={loading}
          totalData={[phoneLog]}
        />
        <Table
          columns={statisticColumns}
          tableTitle="Import Statistics"
          loading={loading}
          totalData={[phoneLog]}
        />
      </Card>
      {phoneLog?.process_logs && (
        <Card loading={loading}>
          <Typography.Paragraph>
            <pre>{phoneLog?.process_logs}</pre>
          </Typography.Paragraph>
        </Card>
      )}
    </PageLayout>
  )
}

export default ImportLogDetails

import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import {
  fetchAllRoles,
  createRole,
  updateRole,
  fetchRoleById,
  fetchRolePermissions,
} from './actions'

import {
  IDLE_STATUS,
  LOADING_STATUS,
  SUCCESS_STATUS,
  FAILURE_STATUS,
} from '../../../constants'

export const rolesAdapter = createEntityAdapter()

const initialState = rolesAdapter.getInitialState({
  status: IDLE_STATUS,
  permissions: [],
})

const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchAllRoles.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(fetchAllRoles.fulfilled, (state, { payload }) => {
      state.status = SUCCESS_STATUS
      if (payload.results) {
        // with pagination
        rolesAdapter.setAll(state, payload.results)
        state.count = payload.count
      } else {
        // without pagination
        rolesAdapter.setAll(state, payload)
        state.count = payload.length
      }
    })
    builder.addCase(fetchAllRoles.rejected, (state, { payload, error }) => {
      state.status = FAILURE_STATUS
      state.error = payload || error.message
    })
    builder.addCase(fetchRoleById.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(fetchRoleById.fulfilled, (state, { payload }) => {
      state.status = SUCCESS_STATUS
      rolesAdapter.addOne(state, payload)
    })
    builder.addCase(fetchRolePermissions.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(fetchRolePermissions.fulfilled, (state, { payload }) => {
      state.status = SUCCESS_STATUS
      state.permissions = payload
    })
    builder.addCase(createRole.fulfilled, rolesAdapter.upsertOne)
    builder.addCase(updateRole.fulfilled, (state, { payload }) => {
      rolesAdapter.removeOne(state, payload.id)
      rolesAdapter.upsertOne(state, payload)
    })
  },
})
export const { updateRoles, addRoles, setAll, updateMainCheckbox } = rolesSlice.actions

const { reducer } = rolesSlice

export default reducer

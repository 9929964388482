import React from 'react'
import { Form, Input } from 'antd'
import PropTypes from 'prop-types'

const FieldWrapper =
  Field =>
  // eslint-disable-next-line react/display-name
  ({
    icon,
    size,
    field,
    form: { errors, touched, setFieldValue, setFieldTouched },
    label,
    type,
    error,
    suffix,
    required,
    ...props
  }) => {
    const { name } = field

    const handleBlur = () => {
      setFieldTouched(name, true)
    }

    const handleChange = ({ target: { value } }) => {
      setFieldValue(name, value)
    }

    return (
      <Form.Item
        label={label}
        validateStatus={(errors[name] && touched[name]) || error ? 'error' : 'success'}
        help={(errors[name] && touched[name]) || error ? errors[name] || error : null}
        required={required}
      >
        {type === 'password' ? (
          <Field.Password
            prefix={icon}
            size={size}
            autoComplete="current-password"
            {...field}
            {...props}
          />
        ) : (
          <Field
            {...field}
            {...props}
            prefix={icon}
            autoComplete={name}
            size={size}
            onBlur={handleBlur}
            onChange={handleChange}
            suffix={suffix}
          />
        )}
      </Form.Item>
    )
  }

const TextField = FieldWrapper(Input)
TextField.displayName = 'TextField'

TextField.propTypes = {
  icon: PropTypes.element,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  form: PropTypes.shape({
    touched: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object)),
    ]),
    errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  }).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.oneOf(['password', 'text', 'search', 'email']),
  size: PropTypes.oneOf(['large', 'small', 'medium']),
  required: PropTypes.bool,
}

TextField.defaultProps = {
  icon: null,
  field: PropTypes.shape({
    value: '',
    onChange: () => null,
    onBlur: () => null,
  }),
  label: '',
  type: 'text',
  size: 'medium',
  required: false,
}

export default TextField

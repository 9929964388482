import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Row, Col } from 'antd'

import { useSelector } from 'react-redux'
import MainLayout from '../../layouts/MainLayout'
import UserForm from '../../components/Forms/UserForm'
import { createUser } from '../../../state/modules/users'
import { createUserSchema } from '../../../schema/createUser'
import useDispatchHttp from '../../../hooks/dispatchHttpHandler'
import { fetchAllRoles, selectAllRoles } from '../../../state/modules/roles'
import { capitalizeEveryWord } from '../../../utils/helpers'
import { useUser } from '../../../providers/UserProvider'

const AddNewUser = ({ breadcrumbs }) => {
  const dispatchHttp = useDispatchHttp()
  const history = useHistory()
  const roles = useSelector(selectAllRoles)
  const roleOptions = roles.map(role => ({
    value: role.id,
    label: capitalizeEveryWord(role.name),
  }))
  const { isUserActionAllowed } = useUser()

  const handleSubmit = async ({ role, ...formData }) => {
    try {
      const data = { ...formData, groups: [role].filter(Boolean) }
      await dispatchHttp(createUser({ data }), 'User has been successfully created')
      history.goBack()
    } catch (err) {
      /* empty */
    }
  }

  useEffect(() => {
    if (isUserActionAllowed('view_group')) {
      dispatchHttp(fetchAllRoles({ skipPagination: true }))
    }
    // eslint-disable-next-line
  }, [dispatchHttp])

  return (
    <MainLayout withSubHeader subHeaderProps={{ breadcrumb: breadcrumbs }}>
      <Row justify="center">
        <Col>
          <UserForm
            submitButtonText="Add New User"
            onSubmit={handleSubmit}
            validationSchema={createUserSchema}
            formType="create"
            roleOptions={roleOptions}
            isSubmitAllowed={isUserActionAllowed('add_user')}
          />
        </Col>
      </Row>
    </MainLayout>
  )
}

AddNewUser.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    })
  ).isRequired,
}

export default AddNewUser

import React from 'react'
import PropTypes from 'prop-types'
import { List, Row, Col, Avatar, Typography, Space, Tooltip } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { formatDate } from '../../../utils/helpers'

const RetrieverListItem = ({
  data: { fullname, cms_id, total_cases, contname, last_case_date },
  isAutoDetectionEnabled,
  radioButton,
}) => {
  const card = (
    <div className="user-card small">
      <Row wrap={false} gutter={24} align="middle">
        <Col>
          <Avatar
            size={80}
            icon={<UserOutlined className="user-card__avatar--icon" />}
            className="user-card__avatar"
          />
        </Col>
        <Col flex={1}>
          <Row wrap={false} justify="space-between" align="middle">
            <Col>
              <Row>
                <Typography.Title level={3} className="user-card__title">
                  {fullname}
                </Typography.Title>
              </Row>
              <Row>
                {!!contname && <Typography.Paragraph>{contname}</Typography.Paragraph>}
              </Row>
              <Row>
                <Typography.Paragraph className="user-card__title-sub">
                  <Space size={16}>
                    <span>
                      CMS ID <strong>{cms_id}</strong>
                    </span>
                    <span>
                      Total Cases <strong>{total_cases}</strong>
                    </span>
                  </Space>
                </Typography.Paragraph>
              </Row>
              <Row>
                {!!last_case_date && (
                  <Typography.Paragraph>
                    {formatDate(last_case_date)}
                  </Typography.Paragraph>
                )}
              </Row>
            </Col>
            {radioButton && <Col>{radioButton}</Col>}
          </Row>
        </Col>
      </Row>
    </div>
  )

  return (
    <List.Item>
      {isAutoDetectionEnabled && (
        <Tooltip title="Disable auto-detection to select main retriever">{card}</Tooltip>
      )}
      {!isAutoDetectionEnabled && card}
    </List.Item>
  )
}

RetrieverListItem.propTypes = {
  data: PropTypes.shape({
    fullname: PropTypes.string,
    cms_id: PropTypes.number,
    total_cases: PropTypes.number,
    contname: PropTypes.string,
    last_case_date: PropTypes.string,
  }),
  isAutoDetectionEnabled: PropTypes.bool,
  radioButton: PropTypes.element,
}

RetrieverListItem.defaultProps = {
  data: PropTypes.shape({
    fullname: '',
  }),
  isAutoDetectionEnabled: false,
  radioButton: null,
}

export default RetrieverListItem

import Api from './index'
import { API_URL } from '../constants'

class Merger extends Api {
  getMergerById(id) {
    return this.get(`merger/${id}/`)
  }

  createMerger(data) {
    return this.post('merger/', { data })
  }

  deleteMerger(id) {
    return this.del(`merger/${id}/`)
  }

  runMerger(id) {
    return this.post(`merger/${id}/run/`)
  }

  getMergers(id, params) {
    return this.get('merger/', { params })
  }

  getMergerProviders(_, params) {
    const { id, providerType, ...rest } = params
    return this.get(`merger/${id}/providers/${providerType}/`, {
      params: rest,
    })
  }

  relateMerger(id, provider_type, data) {
    return this.post(`merger/${id}/relate/${provider_type}/`, { data })
  }

  removeMergerProviderRelation(id, provider_type, provider_id, data) {
    return this.del(`merger/${id}/relate/${provider_type}/${provider_id}/`, {
      data,
    })
  }
}

export default new Merger({
  baseURL: API_URL,
})

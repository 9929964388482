import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getPagination } from '../../../utils/functions'

import ClusterService from '../../../services/ClusterService'

const formatResponse = (response = []) =>
  response.map(item => ({
    ...item.headquarter,
    ...item,
  }))

export const getClusters = createAsyncThunk('cluster/getClusters', async data => {
  const response = await getPagination(
    data,
    ClusterService.getClustersList.bind(ClusterService)
  )

  return { ...response, results: formatResponse(response.results) }
})

export const fetchClusterById = createAsyncThunk('cluster/fetchClusterById', async id => {
  const response = await ClusterService.getClusterById(id)

  return response
})

export const createCluster = createAsyncThunk('cluster/createCluster', data => {
  const response = ClusterService.createFacilityCluster(data)

  return response
})

export const deleteCluster = createAsyncThunk('cluster/deleteCluster', async id => {
  await ClusterService.removeFacilityCluster(id)

  return id
})

export const updateCluster = createAsyncThunk(
  'cluster/updateOne',
  async ({ id, data }) => {
    const response = await ClusterService.updateFacilityCluster(id, data)

    return { id, changes: response }
  }
)

export const getClusterFacilities = createAsyncThunk(
  'cluster/getClusterFacilities',
  async data => {
    const response = await getPagination(
      data,
      ClusterService.getClusterFacilities.bind(ClusterService)
    )

    return response
  }
)

export const resetState = createAction('cluster/resetState')

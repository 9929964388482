export function getDoctorFeedbackLoopData(doctorData, feedbackLoopData) {
  const info = { ...doctorData }
  info.name = [info.first_name, info.last_name].filter(Boolean).join(' ')
  const original = {
    addresses: [],
    phone_numbers: [],
    fax_numbers: [],
    emails: [],
    name: '',
  }
  const checkOriginal = (newData, newDataField, oldData, oldDataField, obj) => {
    if (newData[newDataField]?.toLowerCase() !== oldData[oldDataField]?.toLowerCase()) {
      obj[oldDataField] = oldData[oldDataField]
    }
  }
  const newData = { ...(feedbackLoopData?.primary_search || {}) }
  const nameParts = newData?.last_name?.split(' ') || []
  const [firstName, ...lastName] = nameParts
  newData.first_name = firstName
  newData.last_name = lastName.join(' ')
  newData.name = [newData.first_name, newData.last_name].filter(Boolean).join(' ')
  let addresses = [...(doctorData?.addresses || [])]
  let phones = [...(doctorData?.phone_numbers || [])]
  let faxes = [...(doctorData?.fax_numbers || [])]
  let emails = [...(doctorData?.emails || [])]
  addresses = addresses.sort(a => (a.type === 'LOC' ? -1 : 1))
  phones = phones.sort(a => (a.type === 'LOC' ? -1 : 1))
  faxes = faxes.sort(a => (a.type === 'LOC' ? -1 : 1))
  emails = emails.sort(a => (a.type === 'LOC' ? -1 : 1))

  if (newData.address_line_1) {
    if (!addresses.length) {
      addresses.push({})
    }
    if (addresses[0].type === 'LOC') {
      if (!original.addresses.length) {
        original.addresses.push({})
      }

      checkOriginal(
        newData,
        'address_line_1',
        addresses[0],
        'address_line_1',
        original.addresses[0]
      )
    }
    addresses[0] = {
      ...addresses[0],
      address_line_1: newData.address_line_1,
    }
  }

  if (newData.address_line_2) {
    if (!addresses.length) {
      addresses.push({})
    }
    if (addresses[0].type === 'LOC') {
      if (!original.addresses.length) {
        original.addresses.push({})
      }
      checkOriginal(
        newData,
        'address_line_2',
        addresses[0],
        'address_line_2',
        original.addresses[0]
      )
    }
    addresses[0] = {
      ...addresses[0],
      address_line_2: newData.address_line_2,
    }
  }

  if (newData.city) {
    if (!addresses.length) {
      addresses.push({})
    }
    if (addresses[0].type === 'LOC') {
      if (!original.addresses.length) {
        original.addresses.push({})
      }
      checkOriginal(newData, 'city', addresses[0], 'city', original.addresses[0])
    }
    addresses[0] = { ...addresses[0], city: newData.city }
  }

  if (newData.state) {
    if (!addresses.length) {
      addresses.push({})
    }
    if (addresses[0].type === 'LOC') {
      if (!original.addresses.length) {
        original.addresses.push({})
      }
      checkOriginal(newData, 'state', addresses[0], 'state', original.addresses[0])
    }
    addresses[0] = { ...addresses[0], state: newData.state }
  }

  if (newData.zip_code) {
    if (!addresses.length) {
      addresses.push({})
    }
    if (addresses[0].type === 'LOC') {
      if (!original.addresses.length) {
        original.addresses.push({})
      }
      checkOriginal(newData, 'zip_code', addresses[0], 'zip_code', original.addresses[0])
    }
    addresses[0] = { ...addresses[0], zip_code: newData.zip_code }
  }

  if (newData.fax) {
    if (!faxes.length) {
      faxes.push({})
    }
    if (faxes[0].type === 'LOC') {
      if (!original.fax_numbers.length) {
        original.fax_numbers.push({})
      }
      checkOriginal(newData, 'fax', faxes[0], 'number', original.fax_numbers[0])
    }
    faxes[0] = { ...faxes[0], number: newData.fax, type: 'LOC' }
  }

  if (newData.phone) {
    if (!phones.length) {
      phones.push({})
    }
    if (phones[0].type === 'LOC') {
      if (!original.phone_numbers.length) {
        original.phone_numbers.push({})
      }
      checkOriginal(newData, 'phone', phones[0], 'number', original.phone_numbers[0])
    }
    phones[0] = { ...phones[0], number: newData.phone, type: 'LOC' }
  }

  if (newData.last_name) {
    checkOriginal(newData, 'last_name', info, 'last_name', original)
    info.last_name = newData.last_name
  }

  if (newData.first_name) {
    checkOriginal(newData, 'first_name', info, 'first_name', original)
    info.first_name = newData.first_name
  }

  if (newData.name) {
    checkOriginal(newData, 'name', info, 'name', original)
    info.name = newData.name
  }

  info.addresses = addresses
  info.phone_numbers = phones
  info.fax_numbers = faxes
  info.emails = emails

  return {
    originalValues: original,
    editValues: info,
  }
}

import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { Tabs as Tab, List } from 'antd'

import PageLayout from '../../layouts/DetailPageLayout'
import Tabs from '../../components/Tabs'
import ActivityLogCard from '../../components/ActivityLogCard'
import ActivityLogFiltersEntities from '../../components/ActivityLogFilters/ActivityLogFiltersEntities'
import ActivityLogFiltersCases from '../../components/ActivityLogFilters/ActivityLogFiltersCases'
import {
  getActivityLogs,
  selectActivityLogsCount,
  isActivityLogsFetching,
  selectAllActivityLogs,
} from '../../../state/modules/activityLogs'
import {
  getActivityLogCases,
  selectActivityLogCasesCount,
  isActivityLogCasesFetching,
  selectAllActivityLogCases,
} from '../../../state/modules/activityLogCases'
import useDispatchHttp from '../../../hooks/dispatchHttpHandler'
import { validateSearchParams } from '../../../utils/functions'
import ExceptionLogCard from '../../components/ExceptionLogCard'
import { isExceptionShouldBeSkipped } from '../../../utils/helpers'
import { useUser } from '../../../providers/UserProvider'

const tabs = [
  { name: 'All', key: 'all', label: 'All', value: 'all' },
  {
    name: 'Facility',
    key: 'facility',
    label: 'Facility',
    value: 'facility',
  },
  {
    name: 'Facility Profile',
    key: 'facility_profile',
    label: 'Facility Profile',
    value: 'facility_profile',
  },
  {
    name: 'Auth Form',
    key: 'auth_form',
    label: 'Auth Form',
    value: 'auth_form',
  },
  { name: 'Doctor', key: 'doctor', label: 'Doctor', value: 'doctor' },
  {
    name: 'Facility Cluster',
    key: 'facility_cluster',
    label: 'Facility Cluster',
    value: 'facility_cluster',
  },
  {
    name: 'Doctor to Facility Relation',
    key: 'doctor_to_facility_relation',
    label: 'Doctor to Facility Relation',
    value: 'doctor_to_facility_relation',
  },
  {
    name: 'Jaia User',
    key: 'gaia_user',
    label: 'Jaia User',
    value: 'gaia_user',
  },
  { name: 'Role', key: 'group', label: 'Role', value: 'group' },
  {
    name: 'Health Network',
    key: 'health_network',
    label: 'Health Network',
    value: 'health_network',
  },
  {
    name: 'Insurance',
    key: 'insurance_company',
    label: 'Insurance',
    value: 'insurance_company',
  },
  { name: 'Agency', key: 'agency', label: 'Agency', value: 'agency' },
  {
    name: 'СС Assignment',
    key: 'assignment_copy_company',
    label: 'СС Assignment',
    value: 'assignment_copy_company',
  },
  {
    name: 'Vendor',
    key: 'vendor',
    label: 'Vendor',
    value: 'vendor',
  },
  {
    name: 'Facility Vendor',
    key: 'facility_vendor',
    label: 'Facility Vendor',
    value: 'facility_vendor',
  },
  {
    name: 'Doctor Vendor',
    key: 'doctor_vendor',
    label: 'Doctor Vendor',
    value: 'doctor_vendor',
  },
  {
    name: 'Retriever',
    key: 'retriever',
    label: 'Retriever',
    value: 'retriever',
  },
]

const ActivityLogList = () => {
  const { isUserActionAllowed } = useUser()
  const { id: currentUserID } = useSelector(state => state.auth)
  const [currentPageEntities, setCurrentPageEntities] = useState(1)
  const [pageSizeEntities, setPageSizeEntities] = useState(10)
  const [currentPageCases, setCurrentPageCases] = useState(1)
  const [pageSizeCases, setPageSizeCases] = useState(10)
  const location = useLocation()
  const history = useHistory()
  const { search } = useLocation()
  const dispatchHttp = useDispatchHttp()
  const query = new URLSearchParams(search)
  const userId = query.get('user_id')
  const instance_id = query.get('id')
  const entity = query.get('entity')
  const editedParam = query.get('edited')
  const selectedMainTab = query.get('tab')
  const [activityTypeTab, setActivityTypeTab] = useState(
    selectedMainTab || (isUserActionAllowed('view_history_case') && 'cases') || 'entities'
  )
  const activityLogs = useSelector(selectAllActivityLogs)
  const activityLogCases = useSelector(selectAllActivityLogCases)
  const isFetchingActivityLogs = useSelector(isActivityLogsFetching)
  const isFetchingActivityLogCases = useSelector(isActivityLogCasesFetching)
  const countEntities = useSelector(selectActivityLogsCount)
  const countCases = useSelector(selectActivityLogCasesCount)
  const [searchParamsEntities, setSearchParamsEntities] = useState({
    user_id: null,
  })
  const [searchParamsCases, setSearchParamsCases] = useState({
    user_id: null,
  })

  const preparedUpdatedData = (newParams, oldParams) => {
    // exclude user_id and edited keys
    const { user_id, edited, ...preparedSearchParams } = oldParams
    // Can have only one user_id or edited value so if one of this params is present we clear them from the state
    const newSearchParams =
      newParams.user_id || newParams.edited ? preparedSearchParams : oldParams
    return {
      ...newSearchParams,
      ...newParams,
    }
  }

  const updateSearchParam = useCallback(
    params => {
      const data = preparedUpdatedData(params, searchParamsEntities)

      setSearchParamsEntities(data)
      dispatchHttp(
        getActivityLogs({
          ...validateSearchParams(data),
        })
      )
      setCurrentPageEntities(1)
      setPageSizeEntities(10)
    },
    [searchParamsEntities, dispatchHttp]
  )

  const updateSearchParamCases = useCallback(
    params => {
      const data = preparedUpdatedData(params, searchParamsCases)

      setSearchParamsCases(data)
      dispatchHttp(
        getActivityLogCases({
          ...validateSearchParams(data),
        })
      )
      setCurrentPageCases(1)
      setPageSizeCases(10)
    },
    [searchParamsCases, dispatchHttp]
  )

  const handlePaginate = (page, newPageSize) => {
    dispatchHttp(
      getActivityLogs({
        ...validateSearchParams(searchParamsEntities),
        page,
        pageSize: newPageSize,
      })
    )
    setCurrentPageEntities(page)
    setPageSizeEntities(newPageSize)
  }

  const handlePaginateCases = (page, newPageSize) => {
    dispatchHttp(
      getActivityLogCases({
        ...validateSearchParams(searchParamsCases),
        page,
        pageSize: newPageSize,
      })
    )
    setCurrentPageCases(page)
    setPageSizeCases(newPageSize)
  }

  const handleSelectEntity = entityItem => {
    updateSearchParam({ entity: entityItem })
  }

  const removeUrlParams = () => {
    const queryParams = new URLSearchParams(location.search)

    if (queryParams.has('tab')) {
      history.replace({
        search: '',
      })
    }
  }

  useEffect(() => {
    if (activityTypeTab === 'cases') {
      const additionalParams = {}
      additionalParams.ordering = '-created_at'

      if (isUserActionAllowed('view_user')) {
        additionalParams.edited = 'all'
      } else {
        additionalParams.user_id = currentUserID
      }

      setCurrentPageCases(1)
      setPageSizeCases(10)

      dispatchHttp(
        getActivityLogCases({
          ...validateSearchParams(additionalParams),
        })
      )
      setSearchParamsCases({
        ...additionalParams,
      })

      removeUrlParams()
    } else {
      const additionalParams = {}

      if (isUserActionAllowed('view_user')) {
        if (userId) {
          additionalParams.user_id = +userId
        } else {
          additionalParams.edited = editedParam || 'all'
        }
      } else if (userId) {
        additionalParams.user_id = +userId
      } else if (editedParam) {
        additionalParams.edited = editedParam
      } else {
        additionalParams.user_id = currentUserID
      }

      setCurrentPageEntities(1)
      setPageSizeEntities(10)

      dispatchHttp(
        getActivityLogs({
          entity,
          instance_id,
          ...validateSearchParams(additionalParams),
        })
      )
      setSearchParamsEntities({
        entity: entity || 'all',
        ...additionalParams,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchHttp, activityTypeTab])

  const handleSelect = key => {
    handleSelectEntity(key)
  }

  const renderItem = useCallback(
    data => (
      <ActivityLogCard
        data={data}
        userId={searchParamsEntities.user_id}
        edited={searchParamsEntities.edited}
        selectedEntityFilter={searchParamsEntities.entity}
      />
    ),
    [searchParamsEntities]
  )

  const renderItemCases = useCallback(
    data => {
      const changes = data.changes.new
      const isOnlyOneChange = Object.keys(changes).length === 1

      if (isOnlyOneChange && isExceptionShouldBeSkipped(changes)) return null

      return (
        <ExceptionLogCard
          data={data}
          isMrd={data?.is_mrd_case}
          id={data?.case}
          renderCmsID
        />
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParamsCases]
  )

  return (
    <PageLayout title="Activity Log">
      <Tabs
        fullWidth
        theme="dark"
        tabsProps={{
          size: 'small',
          tabBarGutter: 24,
          onChange: val => setActivityTypeTab(val),
          activeKey: activityTypeTab,
        }}
      >
        {isUserActionAllowed('view_history_case') && (
          <Tab.TabPane tab="Cases" key="cases">
            <ActivityLogFiltersCases
              searchParams={searchParamsCases}
              updateSearchParam={updateSearchParamCases}
            />
            <List
              grid={{ gutter: 24, column: 1 }}
              dataSource={activityLogCases}
              renderItem={renderItemCases}
              pagination={{
                hideOnSinglePage: true,
                total: countCases,
                onChange: handlePaginateCases,
                current: currentPageCases,
                pageSizeCases,
              }}
              loading={isFetchingActivityLogCases}
            />
          </Tab.TabPane>
        )}

        {isUserActionAllowed('view_version') && (
          <Tab.TabPane tab="Entities" key="entities">
            <ActivityLogFiltersEntities
              searchParams={searchParamsEntities}
              updateSearchParam={updateSearchParam}
              handleTabSelect={handleSelect}
              tabs={tabs}
            />

            <Tabs
              fullWidth
              theme="dark"
              tabsProps={{
                size: 'small',
                tabBarGutter: 24,
                onChange: handleSelectEntity,
                activeKey: searchParamsEntities.entity,
              }}
              hideTabBar
            >
              {tabs.map(({ key, name }) => (
                <Tab.TabPane tab={name} key={key}>
                  <List
                    grid={{ gutter: 24, column: 1 }}
                    dataSource={activityLogs}
                    renderItem={renderItem}
                    pagination={{
                      hideOnSinglePage: activityLogs.length === 0,
                      total: countEntities,
                      onChange: handlePaginate,
                      current: currentPageEntities,
                      pageSizeEntities,
                    }}
                    loading={isFetchingActivityLogs}
                  />
                </Tab.TabPane>
              ))}
            </Tabs>
          </Tab.TabPane>
        )}
      </Tabs>
    </PageLayout>
  )
}

export default ActivityLogList

/* eslint-disable react/no-danger */
import React from 'react'
import { Tooltip } from 'antd'
import moment from 'moment-timezone'
import { BookOutlined, CheckOutlined, StarOutlined } from '@ant-design/icons'
import {
  TIME_FORMAT,
  EXCEPTION_MAPPING,
  importLogsMappingColor,
  reportsMappingColor,
  DATE_FORMAT,
  CASE_SEARCH_OPTIONS,
  SKIP_EXCEPTION_TYPES,
} from '../constants'
import { weekDays } from '../data/selectOptions'

export const sortByKey = (a, b, key) => {
  // Check for null values and move them to the end
  if (a[key] === null) {
    return 0
  }

  if (b[key] === null) {
    return 0
  }

  // Standard sorting for non-null values
  if (a[key] < b[key]) {
    return -1
  }

  if (a[key] > b[key]) {
    return 1
  }

  return 0
}

export const capitalizeFirsLetter = str => {
  const { length } = str
  const symbols = ['-', '&', '#']
  if (!length || symbols.includes(str)) return str
  const symbol = symbols.find(item => str.indexOf(item) !== -1)
  const strParts = str.split(symbol)
  const capitalizedParts = strParts.map(item => {
    if (!item) {
      return item
    }

    return item[0]?.toUpperCase() + item.toLowerCase().slice(1)
  })

  return capitalizedParts.join(symbol)
}

export const capitalizeEveryWord = (str = '') =>
  str ? str.split(' ').map(capitalizeFirsLetter).join(' ') : str

export const uniq = (arr = []) => Array.from(new Set(arr))
export const uniqBy = (arr = [], field) => {
  const uniqueArr = []
  arr.forEach(item => {
    const isExist = uniqueArr.find(existingItem => existingItem[field] === item[field])

    if (!isExist) {
      uniqueArr.push(item)
    }
  })

  return uniqueArr
}

export const formatZipCode = (code = '') => {
  const { length } = code

  return length && length > 5 ? code.slice(0, 5) : code
}

export const formatPhoneNumber = (number, phone_extension) => {
  let phone = number
  const cleaned = `${number}`.replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    phone = `+1-(${match[1]})-${match[2]}-${match[3]}`
  }

  if (phone_extension) {
    phone = `${phone} (${phone_extension})`
  }

  return phone
}

export const formatCurrency = value => {
  const numberValue = Number(value)

  if (!Number.isNaN(numberValue)) {
    return Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })
      .format(numberValue)
      .replace(/,/g, ' ')
  }

  return null
}

export const toShortDayName = (name = '') => name.slice(0, 3)

export const formatWeekDays = (days = []) => {
  if (!days.length) {
    return '-'
  }

  return days.map(day => toShortDayName(weekDays[day].label)).join(', ')
}

export const formatWorkingHours = (start = '', end = '') => {
  if (start && end) {
    return `${moment(start, TIME_FORMAT.full).format(TIME_FORMAT.half)} - ${moment(
      end,
      TIME_FORMAT.full
    ).format(TIME_FORMAT.half)}`
  }

  return start || end
}

export const convertTextToValue = (text = '', options = []) => {
  const item = options.find(({ label }) => text === label)

  return item ? item.value : text
}

export const mapStatusColor = (status = '') => {
  const { color } = Object.values(EXCEPTION_MAPPING).find(({ name }) => status === name)

  return color
}

export const mapImportLogsStatusColor = status => importLogsMappingColor[status]
export const mapReportsStatusColor = status => reportsMappingColor[status]

export const isObject = value =>
  value != null && typeof value === 'object' && !Array.isArray(value)

export const isArrayEmpty = value => Array.isArray(value) && value.length

export const formatCaseInfoTableData = (data = [], npiData = {}) => {
  const mapping = {
    facility: 'mrd',
    mrd_facility: 'mrd',
    treatment_facility: 'treatment',
    doctor: 'doctor',
    contact_form_facility: 'mrd',
    contact_form_mrd: 'mrd',
    contact_form_treatment: 'treatment',
    contact_form_doctor: 'doctor',
  }
  return data.filter(Boolean).map(item => {
    const type = [item.suggestion_type, item.form_type].filter(Boolean).join('_')

    const npi = npiData[mapping[type]]
    const nameArr =
      item.suggestion_type === 'facility'
        ? [item.primary_search.last_name]
        : [item.primary_search.first_name, item.primary_search.last_name]

    const name = nameArr.filter(Boolean).join(' ')

    const googleSearchStr = [
      name,
      item.primary_search.address_line_1,
      item.primary_search.address_line_2,
      item.primary_search.city,
      item.primary_search.state,
      item.primary_search.zip_code,
      item.primary_search.phone ? `phone ${item.primary_search.phone}` : '',
      item.primary_search.fax ? `fax ${item.primary_search.fax}` : '',
    ]
      .filter(Boolean)
      .join(', ')
      .toLowerCase()

    const npiSearchArr = [
      {
        key:
          item.suggestion_type === 'facility' ||
          item.suggestion_type === 'mrd_facility' ||
          item.suggestion_type === 'treatment_facility' ||
          item.suggestion_type === 'contact_form'
            ? 'organization_name'
            : 'last_name',
        value: item.primary_search.last_name,
      },
      { key: 'first_name', value: item.primary_search.first_name },
      { key: 'postal_code', value: item.primary_search.zip_code },
      { key: 'city', value: item.primary_search.city },
      { key: 'state', value: item.primary_search.state },
    ]
    const npiSearchStr = npiSearchArr
      .filter(searchItem => searchItem.value)
      .reduce((str, current) => `${str}${current.key}=${current.value}&`, '')

    return {
      ...item,
      search_type: CASE_SEARCH_OPTIONS[item.suggestion_type] || item.suggestion_type,
      name: capitalizeEveryWord(name) || null,
      phone: uniq(item.all_phone_numbers),
      fax: uniq(item.all_fax_numbers),
      state: uniq(item.all_addresses?.map(({ state }) => state)),
      city: uniq(item.all_addresses?.map(({ city }) => capitalizeEveryWord(city))),
      address_line_1: uniq(
        item.all_addresses?.map(({ address_line_1 }) =>
          capitalizeEveryWord(address_line_1)
        )
      ),
      address_line_2: uniq(
        item.all_addresses?.map(({ address_line_2 }) =>
          capitalizeEveryWord(address_line_2)
        )
      ),
      zip_code: uniq(item.all_addresses?.map(({ zip_code }) => formatZipCode(zip_code))),
      links: {
        googleSearchStr,
        npiSearchStr: npiSearchStr.substring(0, npiSearchStr.length - 1),
        npi,
      },
    }
  })
}

export const formatCaseFeedbackLoopTableData = (data = {}) => {
  const formattedData = { ...data }

  Object.keys(data).map(key => {
    if (key === 'state' || key === 'old') {
      formattedData[key] = data[key]
    } else {
      formattedData[key] = capitalizeEveryWord(data[key])
    }

    return formattedData[key]
  })

  return formattedData
}

export const getName = item => {
  const typeMdl = item?.other_names?.find(ech => ech.type === 'MDL')
  const typeSuf = item?.other_names?.find(ech => ech.type === 'SUF')

  const doctorName = [item?.first_name, typeMdl?.name, item?.last_name, typeSuf?.name]
    .filter(Boolean)
    .join(' ')

  return item.first_name
    ? capitalizeEveryWord(doctorName)
    : capitalizeEveryWord(item.name || item.facility_name)
}

export const formatTableData = (data = []) =>
  data.filter(Boolean).map(item => {
    const sortByType = items => [...(items || [])].sort(a => (a.type === 'LOC' ? -1 : 1))

    const addresses = sortByType(item.addresses)
    const phoneNumbers = uniqBy(sortByType(item.phone_numbers), 'number')
    const faxNumbers = uniqBy(sortByType(item.fax_numbers), 'number')
    return {
      ...item,
      name: getName(item),
      phones: phoneNumbers || uniq(item.phones),
      faxes: faxNumbers || uniq(item.faxes),
      address_type: addresses.map(({ type }) => type.slice(0, 1)),
      vendor_relations: uniq(
        item?.vendor_relations?.map(
          relation => !relation.skip_on_import && relation.vendor__name
        )
      ).filter(rel => rel),
      state: addresses.map(({ state }) => state),
      city: addresses.map(({ city }) => capitalizeEveryWord(city)),
      address_line_1: addresses.map(({ address_line_1 }) =>
        capitalizeEveryWord(address_line_1)
      ),
      address_line_2: addresses.map(({ address_line_2 }) =>
        capitalizeEveryWord(address_line_2)
      ),
      zip_code: addresses.map(({ zip_code }) => zip_code),
      main_retriever: item.main_retriever?.fullname,
      history: 'View History',
      params: [
        capitalizeEveryWord(item.params?.facility_name),
        capitalizeEveryWord(item.params?.first_name),
        item.params?.phone,
        item.params?.fax,
        capitalizeEveryWord(item.params?.address_line_1),
        capitalizeEveryWord(item.params?.address_line_2),
        capitalizeEveryWord(item.params?.city),
        item.params?.state,
        item.params?.zip_code,
        item.params?.gaia_id,
        item.params?.npi,
      ],
    }
  })

export const mapActivityLogChanges = data =>
  Object.values(data).reduce((acc, item) => {
    const itemData = Object.entries(item)

    return [...acc, ...itemData]
  }, [])

export const mapActivityLogChangesForTable = data => {
  const preparedData = {}
  Object.entries(data?.common).map(key => {
    preparedData[key[0]] = key[1].new
    return key
  })

  Object.entries(data?.objects).map(key => {
    preparedData[key[0]] = key[1].added
    return key
  })

  return preparedData
}

export const formatDateTime = (date, dateFormat = DATE_FORMAT.month_day_year) =>
  moment.utc(date).tz('America/New_York').format(`${dateFormat} HH:mm`)

export const formatDate = (date, dateFormat = DATE_FORMAT.month_day_year) =>
  moment.utc(date).tz('America/New_York').format(`${dateFormat}`)

export const formatTime = date => moment.utc(date).tz('America/New_York').format(`HH:mm`)

function getTextWithHighlights(
  textToHighlight,
  textFromHighlight,
  highlightedField,
  highlightOptions
) {
  if (!textFromHighlight || !textToHighlight || !highlightedField) return textToHighlight

  const text = textFromHighlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  const regex = new RegExp(`${text}(?![^<]*\\>)`, 'gi')

  const highlightedText = textToHighlight.replace(
    regex,
    match => `<span class='highlight__${highlightOptions.color}'>${match}</span>`
  )
  // eslint-disable-next-line react/no-danger
  return <span dangerouslySetInnerHTML={{ __html: highlightedText }} />
}

export const highlightValue = (
  primarySearch,
  tableValues,
  highlightedFields,
  highlightOptions,
  field
) => {
  // eslint-disable-next-line no-param-reassign
  tableValues = !Array.isArray(tableValues) ? [tableValues] : tableValues
  const tableValuesArr = !Array.isArray(tableValues) ? [tableValues] : tableValues
  const highlightedFieldsArr = highlightOptions.fields || [highlightOptions.field]
  return tableValuesArr.map(tableValue => {
    let updatedData = tableValue

    highlightedFieldsArr.forEach(dataField => {
      const selectedValue = primarySearch && primarySearch[dataField]
      const highlightedField = highlightedFields && highlightedFields[dataField]
      const value =
        // eslint-disable-next-line no-underscore-dangle
        updatedData?.props?.dangerouslySetInnerHTML?.__html || tableValue

      if (selectedValue && highlightedField) {
        if (isObject(value)) {
          updatedData = {
            ...value,
            [field]: getTextWithHighlights(
              value[field],
              selectedValue.trim(),
              highlightedField,
              highlightOptions
            ),
          }
        } else {
          updatedData = getTextWithHighlights(
            value,
            selectedValue.trim(),
            highlightedField,
            highlightOptions
          )
        }
      }
    })

    return updatedData
  })
}

export const boldLocationValue = (tableValue, field) => {
  const getBoldValue = value => {
    if (isObject(value) && value.type === 'LOC') {
      const val = field ? value[field] : value
      if (!field) return 'Provide field'
      if (!React.isValidElement(val)) {
        return {
          ...value,
          [field]: <span className="bold" dangerouslySetInnerHTML={{ __html: val }} />,
        }
      }

      if (React.isValidElement(val)) {
        return {
          ...value,
          [field]: <span className="bold">{val}</span>,
        }
      }
    }

    return value
  }
  if (Array.isArray(tableValue)) {
    return tableValue.map(val => getBoldValue(val))
  }

  return getBoldValue(tableValue)
}

export const parseValues = (tableValue, field) => {
  const getParsedValue = value => {
    if (isObject(value) && !React.isValidElement(value)) {
      if (value.verified) {
        return (
          <>
            {value[field]}
            <Tooltip title="Contact is verified" placement="topRight">
              <CheckOutlined style={{ color: '#1890ff' }} />
            </Tooltip>
          </>
        )
      }

      return field ? value[field] : 'Provide field'
    }

    return value
  }
  if (Array.isArray(tableValue)) {
    return tableValue.map(val => getParsedValue(val))
  }

  return getParsedValue(tableValue)
}

export const oneStringValue = (tableValue, width) => {
  const getTooltip = value => {
    if (value && !React.isValidElement(value)) {
      return (
        <Tooltip title={value} placement="topLeft">
          <span
            className="one-string"
            style={{ width }}
            dangerouslySetInnerHTML={{ __html: value }}
          />
        </Tooltip>
      )
    }

    if (value && React.isValidElement(value)) {
      return (
        <Tooltip title={value} placement="topLeft">
          <span className="one-string" style={{ width }}>
            {value}
          </span>
        </Tooltip>
      )
    }

    return value
  }
  if (Array.isArray(tableValue)) {
    return tableValue.map(item => getTooltip(item))
  }

  return getTooltip(tableValue)
}

export const addOriginalValue = (tableValue, originalValue, width) => {
  if (!originalValue) return tableValue
  const getOriginalValue = value => (
    <>
      <Tooltip title={`Original: ${originalValue}`} placement="topLeft">
        <span className="one-string" style={{ width }}>
          <span style={{ background: 'rgb(247, 209, 207)' }}>{originalValue}</span>
        </span>
      </Tooltip>
      <br />
      {value}
    </>
  )

  if (Array.isArray(tableValue)) {
    return tableValue.map(item => getOriginalValue(item))
  }
  return getOriginalValue(tableValue)
}

export const getColumnIcon = (icon, data) => {
  const icons = []
  const headquarterIcon = (
    <Tooltip title="Facility Cluster Headquarter">
      <StarOutlined className="search-table__star-icon" />
    </Tooltip>
  )
  const mrdIcon = (
    <Tooltip title="Medical Records Department">
      <BookOutlined className="search-table__star-icon" />
    </Tooltip>
  )
  const soleProprietorIcon = (
    <Tooltip title="Sole Proprietor">
      <CheckOutlined />
    </Tooltip>
  )

  if (data.is_headquarter) {
    icons.push(headquarterIcon)
  }

  if (data.is_mrd) {
    icons.push(mrdIcon)
  }

  if (data.is_sole_proprietor) {
    icons.push(soleProprietorIcon)
  }

  if (icons.length) {
    return <>{icons.map(item => item)}</>
  }

  return icon
}

export const formatPhoneNumbers = phoneNumbers =>
  phoneNumbers.map(item => {
    const { verified, ...rest } = item
    if (item.type === 'LOC') {
      return item
    }
    return {
      ...rest,
    }
  })

export const isExceptionShouldBeSkipped = data => {
  const result = SKIP_EXCEPTION_TYPES.filter(type => data[type])
  return result.some(item => item)
}

import { createAsyncThunk } from '@reduxjs/toolkit'
import UserService from '../../../services/UserService'
import {
  logout,
  login,
  authError,
  userPermissionsLoadStarted,
  userPermissionsLoadFailed,
  userPermissionsLoaded,
  userDataLoaded,
} from './reducer'

export const confirmLogin =
  ({ otpPassword, otpToken }) =>
  async dispatch => {
    const response = await UserService.confirmLogin({
      otpPassword,
      otpToken,
    })
    localStorage.setItem('user', JSON.stringify(response))
    localStorage.setItem('otp_auth_token', response.otp_auth_token)
    dispatch(login(response))
    return response
  }

export const loginUser =
  ({ email, password, otp_auth_token }) =>
  async dispatch => {
    const response = await UserService.login({
      email,
      password,
      otp_auth_token,
    })
    if (response.id) {
      localStorage.setItem('user', JSON.stringify(response))
      localStorage.setItem('otp_auth_token', response.otp_auth_token)
      dispatch(login(response))
    }
    return response
  }

export const setUserDataLoaded = isLoaded => dispatch => {
  dispatch(userDataLoaded(isLoaded))
  return isLoaded
}

export const logoutUser = () => async dispatch => {
  try {
    await UserService.logout()
  } finally {
    localStorage.removeItem('user')
    dispatch(logout())
  }
}

export const setAuthError = () => async dispatch => {
  dispatch(authError())
}

export const getUserPermissions = createAsyncThunk(
  'auth/getUserPermissions',
  async (_, { dispatch }) => {
    dispatch(userPermissionsLoadStarted())
    try {
      const response = await UserService.getUserPermissions()
      dispatch(userPermissionsLoaded(response))
      return response
    } catch (err) {
      dispatch(userPermissionsLoadFailed())
      throw err
    }
  }
)

export const forgotPassword = async ({ email }) => {
  const response = await UserService.forgotPassword({ email })

  return response
}

export const resetPassword = async ({ password, token }) => {
  const response = await UserService.resetPassword({ password, token })

  return response
}

/**
 * Verify reset password token
 * @param {string} token - Reset password token
 */
export const verifyResetPasswordToken = async token => {
  const response = await UserService.verifyResetPasswordToken(token)

  return response
}

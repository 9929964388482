import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getPagination } from '../../../utils/functions'

import CopyCompaniesService from '../../../services/CopyCompaniesService'

export const getCopyCompanies = createAsyncThunk('copyCompanies/getCopyCompanies', data =>
  getPagination(data, CopyCompaniesService.getCopyCompanies.bind(CopyCompaniesService))
)

export const resetState = createAction('copyCompanies/resetState')

import React, { useCallback, useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Table, Checkbox, Button, Row, Col, Card, Form } from 'antd'
import { Field, Formik } from 'formik'
import MainLayout from '../../layouts/MainLayout'
import useDispatchHttp from '../../../hooks/dispatchHttpHandler'
import {
  createRole,
  fetchRolePermissions,
  fetchRoleById,
  selectRoleById,
  updateRole,
} from '../../../state/modules/roles'
import { capitalizeEveryWord } from '../../../utils/helpers'
import TextField from '../../shared/TextField'
import { createRoleSchema } from '../../../schema/createRole'
import { useUser } from '../../../providers/UserProvider'
import { PERMISSION_MAP } from '../../../constants'

const RoleDetails = () => {
  const { roleId } = useParams()
  const { isUserActionAllowed } = useUser()
  const role = useSelector(state => selectRoleById(state, roleId))
  const permissions = useSelector(state => state.roles.permissions)
  const isEditPage = !!roleId

  const [checkboxValues, setCheckboxValues] = useState([])
  const history = useHistory()
  const dispatchHttp = useDispatchHttp()

  const onUpdateRole = useCallback(
    data =>
      dispatchHttp(
        updateRole({
          id: role.id,
          name: data.role_name,
          permissions: data.permissions,
        }),
        'Role has been successfully updated'
      ),
    [dispatchHttp, role]
  )

  const onCreateRole = useCallback(
    data =>
      dispatchHttp(
        createRole({
          name: data.role_name,
          permissions: data.permissions,
        }),
        'Role has been successfully added'
      ),
    [dispatchHttp]
  )

  const onSubmit = useCallback(
    async data => {
      if (isEditPage) {
        await onUpdateRole(data)
      } else {
        await onCreateRole(data)
      }

      history.push('/roles')
    },
    [isEditPage, onCreateRole, onUpdateRole, history]
  )

  const isAllIndeterminate = values =>
    values?.permissions?.length > 0 && permissions?.length !== values?.permissions?.length

  const isAllChecked = values =>
    values?.permissions?.length && permissions?.length === values?.permissions?.length

  const onChangeAll = form => {
    const isCheckExist = checkboxValues.find(item => item.value)
    let checkedPermissions = []

    if (!isCheckExist) {
      checkedPermissions = checkboxValues.map(item => item.id)
    }
    form.setFieldValue('permissions', checkedPermissions)

    const arr = checkboxValues.map(item => ({
      ...item,
      value: !isCheckExist,
    }))
    setCheckboxValues(arr)
  }

  const onChange = (form, field) => {
    const permissionValues = form?.values?.permissions || []
    let checkedPermissions = [...permissionValues]
    if (checkedPermissions.includes(field.value)) {
      checkedPermissions = checkedPermissions.filter(id => id !== field.value)
    } else {
      checkedPermissions = [...checkedPermissions, field.value]
    }
    form.setFieldValue('permissions', checkedPermissions)

    const arr = checkboxValues?.map(item => ({
      ...item,
      value: item.id === field.value ? !item.value : item.value,
    }))
    setCheckboxValues(arr)
  }

  const columns = [
    {
      key: 'group',
      title: 'Group',
      render: (_, record) => ({
        children: (
          <div style={{ textTransform: 'uppercase', fontWeight: '600' }}>
            {record.group || 'Other'}
          </div>
        ),
        props: {
          rowSpan: record.span,
        },
      }),
    },
    {
      key: 'name',
      title: 'Permission',
      render: text => capitalizeEveryWord(text.name),
    },
    {
      key: 'value',
      align: 'center',
      title: (
        <Field
          type="checkbox"
          name="permissions"
          render={({ form }) => (
            <Checkbox
              checked={isAllChecked(form.values)}
              indeterminate={isAllIndeterminate(form.values)}
              onChange={() => onChangeAll(form)}
            />
          )}
        />
      ),
      // eslint-disable-next-line react/display-name
      render: (_, record) => (
        <Field
          type="checkbox"
          value={record.id}
          name="permissions"
          render={({ field, form }) => (
            <Checkbox checked={record.value} onChange={() => onChange(form, field)} />
          )}
        />
      ),
    },
  ]

  useEffect(() => {
    let sameKey
    let count
    let sortedPermissions = PERMISSION_MAP.map(item => {
      const currentPermission = permissions?.find(
        permission => permission.codename === item.codename
      )

      return {
        ...currentPermission,
        group: item.group,
      }
    })

    // permissions that are not listed in PERMISSION_MAP
    const permissionsSkipped = permissions
      ?.filter(permission => {
        const perm = sortedPermissions?.find(
          element => element.codename === permission.codename
        )
        return !perm
      })
      .map(item => ({ ...item, group: 'Other' }))

    sortedPermissions = [...sortedPermissions, ...permissionsSkipped]

    const preparedData = sortedPermissions.map(item => {
      if (sameKey !== item.group) {
        sameKey = item.group
        count = sortedPermissions.filter(element => element.group === item.group).length
      } else {
        count = 0
      }

      return {
        ...item,
        span: count,
      }
    })

    const values = preparedData?.map(item => ({
      ...item,
      value: role?.permissions.includes(item.id),
    }))

    setCheckboxValues(values)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions, role])

  useEffect(() => {
    if (+roleId) {
      dispatchHttp(fetchRoleById(roleId))
    }
  }, [dispatchHttp, roleId])

  useEffect(() => {
    if (isUserActionAllowed('view_permission')) {
      dispatchHttp(fetchRolePermissions())
    }
    // eslint-disable-next-line
  }, [dispatchHttp])

  const breadcrumbsAddRolePage = [
    { name: 'Roles', path: '/roles' },
    {
      name: `Create Role`,
    },
  ]

  const breadcrumbsEditRolePage = [
    { name: 'Roles', path: '/roles' },
    {
      name: `Edit Role: ${role?.name}`,
    },
  ]

  return (
    <MainLayout
      withSubHeader
      subHeaderProps={{
        breadcrumb: isEditPage ? breadcrumbsEditRolePage : breadcrumbsAddRolePage,
      }}
    >
      <Row justify="center">
        <Card className="user-info-card">
          <Col>
            {' '}
            <Formik
              initialValues={{
                role_name: role?.name || '',
                permissions: role?.permissions,
              }}
              onSubmit={onSubmit}
              validationSchema={createRoleSchema}
              enableReinitialize
            >
              {({ handleSubmit, isSubmitting, dirty }) => (
                <Form layout="vertical" onFinish={handleSubmit}>
                  <Row gutter={24}>
                    <Col span={24}>
                      <Field
                        component={TextField}
                        label="Role Name"
                        size="medium"
                        name="role_name"
                        required
                      />
                    </Col>
                  </Row>
                  {isUserActionAllowed('view_permission') && (
                    <Table
                      bordered
                      dataSource={checkboxValues}
                      className="roles-table roles-table-details"
                      pagination={{ position: ['none'], pageSize: 1000 }}
                      columns={columns}
                      rowKey="id"
                    />
                  )}

                  <Row
                    justify="space-between"
                    style={{
                      marginTop: '10px',
                    }}
                  >
                    <Col className="role-details">
                      <Link to="/roles">
                        <Button>Cancel</Button>
                      </Link>
                    </Col>
                    <Col className="role-details">
                      {isUserActionAllowed('change_group') && isEditPage && (
                        <Button
                          type="primary"
                          ghost
                          htmlType="submit"
                          disabled={!dirty}
                          loading={isSubmitting}
                        >
                          Edit
                        </Button>
                      )}
                      {!isEditPage && (
                        <Button
                          type="primary"
                          ghost
                          htmlType="submit"
                          disabled={!dirty}
                          loading={isSubmitting}
                        >
                          Create
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Card>
      </Row>
    </MainLayout>
  )
}

export default RoleDetails

import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  Row,
  Col,
  Form,
  Input,
  Tabs as Tab,
  Select,
  Button,
  DatePicker,
  Switch,
} from 'antd'
import { Formik } from 'formik'
import { SearchOutlined, SaveOutlined } from '@ant-design/icons'

import Tabs from '../../Tabs'

import { selectAllRetrievers, getRetrievers } from '../../../../state/modules/retrievers'
import useDispatchHttp from '../../../../hooks/dispatchHttpHandler'
import { DATE_FORMAT } from '../../../../constants'
import {
  sortOptions,
  mainRetrieverPastColumns,
  mainRetrieverColumns,
} from '../../../../data'
import Table from '../../Tables/SearchTable'

const TABS = {
  PAST: 'past',
  ALL: 'all',
}

const SetRetrieverForm = ({
  initialValues,
  onSubmit,
  entityId,
  onClose,
  isUserActionAllowed,
  isAutoDetection,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  })
  const [searchParams, setSearchParams] = useState({
    facility_profile: initialValues.id,
    ordering: 'last_case_date',
  })
  const [isAutoDetectionEnabled, setIsAutoDetectionEnabled] = useState(isAutoDetection)
  const [selectedRowKeys, setSelectedRowKeys] = useState([initialValues.main_retriever])
  const [preparedRetrieversList, setPreparedRetrieversList] = useState([])
  const [activeTab, setActiveTab] = useState(TABS.PAST)
  const [columns, setColumns] = useState(mainRetrieverPastColumns)
  const dispatchHttp = useDispatchHttp()
  const retrieversList = useSelector(selectAllRetrievers)
  const { count } = useSelector(state => state.retrievers)
  const loadingStatus = useSelector(state => state.retrievers.status)

  const handleSearch = useCallback(
    e => {
      setSearchParams(state => ({ ...state, search: e.target.value }))
      dispatchHttp(getRetrievers({ ...searchParams, search: e.target.value }))
    },
    [dispatchHttp, searchParams]
  )

  const handleSelect = useCallback(
    value => {
      setSearchParams(state => ({ ...state, ordering: value }))
      dispatchHttp(getRetrievers({ ...searchParams, ordering: value }))
    },
    [dispatchHttp, searchParams]
  )

  const handleKeyPress = e => {
    if ((e.charCode || e.keyCode) === 13) {
      e.preventDefault()
    }
  }

  const handleChangeTab = tab => {
    let params
    setPagination({
      page: 1,
      pageSize: 10,
    })
    if (tab === TABS.PAST) {
      params = {
        facility_profile: initialValues.id,
        ordering: 'last_case_date',
      }
    } else {
      params = {
        facility_profile: null,
        date_range: null,
        ordering: null,
      }
    }

    setSearchParams(state => ({ ...state, ...params }))
    dispatchHttp(getRetrievers({ ...searchParams, ...params }))
    setActiveTab(tab)
  }

  const handleSelectRange = (dates, datesString) => {
    const date_range = datesString.filter(date => !!date).toString()

    setSearchParams(state => ({ ...state, date_range }))
    dispatchHttp(getRetrievers({ ...searchParams, date_range }))
  }

  useEffect(() => {
    dispatchHttp(
      getRetrievers({
        facility_profile: initialValues.id,
        ordering: 'last_case_date',
      })
    )
  }, [dispatchHttp, initialValues])

  useEffect(() => {
    const data = retrieversList.map(item => ({
      ...item,
      isMainRetriever: item.id === initialValues.main_retriever,
    }))
    setPreparedRetrieversList(data)
  }, [retrieversList, initialValues])

  useEffect(() => {
    if (activeTab === TABS.PAST) {
      setColumns(mainRetrieverPastColumns)
    } else {
      setColumns(mainRetrieverColumns)
    }
  }, [activeTab])

  const list = (
    <Table
      columns={columns}
      totalData={preparedRetrieversList}
      onLoadData={getRetrievers}
      formData={searchParams}
      loadingStatus={loadingStatus}
      rowKey="id"
      customRowSelection={{
        key: 'isMainRetriever',
        disabled:
          !isUserActionAllowed('update_retrievers_facilityprofile') ||
          !isAutoDetectionEnabled,
        onSelect: newSelectedRowKeys => {
          setSelectedRowKeys([newSelectedRowKeys])
        },
      }}
      totalCount={count}
      onPageChange={setPagination}
    />
  )

  return (
    <Formik
      initialValues={{ main_retriever: initialValues.main_retriever }}
      onSubmit={async () => {
        try {
          await onSubmit(
            entityId,
            { main_retriever: selectedRowKeys && selectedRowKeys[0] },
            isAutoDetectionEnabled
          )
          onClose()
        } catch (err) {
          /* empty */
        }
      }}
    >
      {({ values, handleSubmit }) => (
        <Form onFinish={handleSubmit} initialValues={values} onKeyDown={handleKeyPress}>
          <Form.Item>
            <Input
              prefix={<Button type="link" size="small" icon={<SearchOutlined />} />}
              placeholder="Name, Contname, CMS ID"
              onPressEnter={handleSearch}
            />
          </Form.Item>
          <Tabs
            defaultActiveKey="past"
            fullWidth
            theme="dark"
            tabBarExtraContent={
              <Form layout="inline" className="sort-form" component="div">
                <Row gutter={32}>
                  {isUserActionAllowed('update_main_retriever_auto_detection') && (
                    <Col>
                      <Form.Item
                        label="Auto Detection"
                        className="sort-form__select-field"
                      >
                        <Switch
                          defaultChecked={isAutoDetectionEnabled}
                          checked={isAutoDetectionEnabled}
                          name="auto_detection"
                          key="switch"
                          onChange={checked => setIsAutoDetectionEnabled(checked)}
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {activeTab === TABS.PAST && (
                    <>
                      <Col>
                        <DatePicker.RangePicker
                          format={DATE_FORMAT.year_month_day}
                          onChange={handleSelectRange}
                        />
                      </Col>
                      <Col>
                        <Form.Item label="Sort by" className="sort-form__select-field">
                          <Select
                            size="middle"
                            className="sort-form__select"
                            onChange={handleSelect}
                            options={sortOptions}
                            placeholder="Select here"
                            value={searchParams.ordering}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Row>
              </Form>
            }
            tabsProps={{
              size: 'small',
              tabBarGutter: 24,
              onChange: handleChangeTab,
            }}
          >
            <Tab.TabPane tab="Past Retrievers" key={TABS.PAST}>
              {list}
            </Tab.TabPane>
            <Tab.TabPane tab="All" key={TABS.ALL}>
              {list}
            </Tab.TabPane>
          </Tabs>
          <Row justify="space-between">
            <Col>
              <Button onClick={onClose}>Close window</Button>
            </Col>
            <Col>
              {(isUserActionAllowed('update_retrievers_facilityprofile') ||
                isUserActionAllowed('update_main_retriever_auto_detection')) && (
                <Button type="primary" icon={<SaveOutlined />} htmlType="submit">
                  Save Changes
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default SetRetrieverForm

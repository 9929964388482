/* eslint-disable no-template-curly-in-string */
import { object, number } from 'yup'

export const facility_profile__id = number().nullable().typeError('Must be a number')
export const cms_id = number().nullable().typeError('Must be a number')

export const cmsFilterSchema = object().shape({
  facility_profile__id,
  cms_id,
})

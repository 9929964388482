import React, { useEffect, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import DetailPageLayout from '../../layouts/DetailPageLayout'
import Facility from '../../templates/Facility'

import { resetState, selectFacilityById } from '../../../state/modules/facilities'

import { capitalizeEveryWord } from '../../../utils/helpers'

const FacilityPage = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const facilityData = useSelector(state => selectFacilityById(state, id))

  useEffect(
    () => () => {
      dispatch(resetState())
    },
    [dispatch]
  )

  const breadcrumbs = useMemo(
    () => [
      { name: 'Search', path: '/search' },
      {
        name: capitalizeEveryWord(facilityData?.name),
        path: `/facility/${id}/`,
      },
    ],
    [facilityData, id]
  )
  const tableRef = useRef(null)

  return (
    <DetailPageLayout breadcrumbs={breadcrumbs}>
      <Facility id={id} tableRef={tableRef} />
    </DetailPageLayout>
  )
}

export default FacilityPage

import React from 'react'
import { Link } from 'react-router-dom'
import { Form, Button } from 'antd'
import { MailOutlined, LockOutlined } from '@ant-design/icons'
import { Formik, Field } from 'formik'
import PropTypes from 'prop-types'

import TextField from '../../../shared/TextField'
import { signInSchema } from '../../../../schema/auth'
import { routePaths } from '../../../../utils/routes'

const LoginForm = ({ onSubmit }) => (
  <Formik
    onSubmit={onSubmit}
    validationSchema={signInSchema}
    initialValues={{ email: '', password: '' }}
  >
    {({ handleSubmit, isSubmitting }) => (
      <Form onFinish={handleSubmit} data-testid="login-form">
        <Field
          component={TextField}
          name="email"
          placeholder="E-mail"
          type="email"
          icon={<MailOutlined className="form-input-icon" />}
        />
        <Field
          component={TextField}
          name="password"
          placeholder="Password"
          type="password"
          icon={<LockOutlined className="form-input-icon" />}
        />

        <Form.Item>
          <Link to={routePaths.openForgotPassword()} className="forgot-password-link">
            Forgot password
          </Link>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            block
            loading={isSubmitting}
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    )}
  </Formik>
)

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default LoginForm

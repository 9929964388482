import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getPagination } from '../../../utils/functions'

import DoctorService from '../../../services/DoctorService'

export const fetchDoctorById = createAsyncThunk('doctors/fetchDoctorById', async id => {
  const response = await DoctorService.getDoctorById(id)

  return response
})

export const updateDoctor = createAsyncThunk(
  'doctors/updateOne',
  async ({ id, data }) => {
    const response = await DoctorService.updateDoctor(id, data)

    return { id, changes: response }
  }
)

export const createDoctor = createAsyncThunk('doctors/createDoctor', data => {
  const response = DoctorService.createDoctor(data)

  return response
})

export const deleteDoctor = createAsyncThunk('doctors/deleteDoctor', async id => {
  await DoctorService.deleteDoctor(id)

  return id
})

export const getRelatedFacilities = createAsyncThunk(
  'doctors/getRelatedFacilities',
  data => getPagination(data, DoctorService.getRelatedFacilities.bind(DoctorService))
)

export const linkFacility = createAsyncThunk(
  'doctors/linkFacility',
  async ({ id, data }) => {
    const response = await DoctorService.linkFacility(id, data)

    return response
  }
)

export const unlinkFacility = createAsyncThunk(
  'doctors/unlinkFacility',
  async ({ id, data }) => {
    await DoctorService.unlinkFacility(id, data)

    return id
  }
)

export const resetState = createAction('doctors/resetState')

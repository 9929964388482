import Api from './index'
import { API_URL } from '../constants'

class User extends Api {
  login({ email, password, otp_auth_token }) {
    return this.post('auth/', {
      data: { email, password, otp_auth_token },
    })
  }

  confirmLogin({ otpPassword, otpToken }) {
    return this.post('/otp_check/', {
      data: { otp_password: otpPassword },
      params: { otp_token: otpToken },
    })
  }

  logout() {
    return this.del('auth/')
  }

  getUserPermissions() {
    return this.get('profile/permissions/')
  }

  forgotPassword({ email }) {
    return this.post('forgot_password/', { data: { email } })
  }

  resetPassword({ password, token }) {
    return this.post('forgot_password/confirm/', {
      data: { password, token },
    })
  }

  createUser(data) {
    return this.post('users/', { data })
  }

  updateUser(id, data) {
    return this.put(`users/${id}/`, { data })
  }

  getUsers(id, params) {
    return this.get('users/', { params })
  }

  getUserById(id) {
    return this.get(`users/${id}/`)
  }

  getCurrentUser() {
    return this.get(`profile/`)
  }

  /**
   *
   * @param {string} token
   */
  verifyResetPasswordToken(token) {
    return this.post(`forgot_password/validate_token/`, {
      data: { token },
    })
  }
}

export default new User({
  baseURL: `${API_URL}accounts/`,
})

import { isObject, isArrayEmpty } from './helpers'
import { MODEL_NAMES } from '../constants'

export const getPagination = async (
  { page = 1, pageSize = 10, data: id, ...params } = {},
  callback
) => {
  const offset = (page - 1) * pageSize
  const response = await callback(id, {
    limit: pageSize,
    offset,
    ...params,
  })
  return { ...response, page, pageSize }
}

export const removeEmptyValues = (values = {}) => {
  const formatter = prop =>
    Object.entries(prop)
      .filter(([, v]) => v !== '' && v !== null)
      .map(([k, v]) => [
        k,
        isArrayEmpty(v)
          ? v.map(val => (isObject(val) ? Object.fromEntries(formatter(val)) : val))
          : v,
      ])

  return Object.fromEntries(formatter(values))
}

/**
 * Download file from url and set name
 * @param {string} url - File url
 * @param {string} name - Save filename
 */
export const downloadFileByUrl = (url, name) => {
  const anchor = document.createElement('a')
  anchor.href = url
  anchor.download = name

  document.body.appendChild(anchor)

  anchor.click()

  document.body.removeChild(anchor)
}

export const validateSearchParams = data =>
  Object.entries(data).reduce(
    (acc, [key, item]) => ({
      ...acc,
      [key]: !!item && item !== 'all' ? item : null,
    }),
    {}
  )

/**
 * Map array and add id by index + 1
 * @param {array} array - File url
 */
export const setIdsFromIndexes = array =>
  array.map((item, index) => ({
    ...item,
    id: index + 1,
  }))

export const getModelPath = modelName => {
  switch (modelName) {
    case MODEL_NAMES.FACILITY:
      return 'facility'
    case MODEL_NAMES.FACILITY_PROFILE:
      return 'facility-profiles'
    case MODEL_NAMES.DOCTOR:
      return 'doctor'
    case MODEL_NAMES.FACILITY_CLUSTER:
      return 'clusters'
    case MODEL_NAMES.GAIA_USER:
      return 'users'
    case MODEL_NAMES.GROUP:
      return 'roles'
    case MODEL_NAMES.HEALTH_NETWORK:
      return 'health-networks'
    case MODEL_NAMES.INSURANCE_COMPANY:
      return 'insurance-company'
    case MODEL_NAMES.AGENCY:
      return 'agency'
    case MODEL_NAMES.ASSIGNMENT_COPY_COMPANY:
      return 'copy-company-assignment'
    default:
      return null
  }
}

export const getModelNameForPath = modelName => {
  switch (modelName) {
    case MODEL_NAMES.FACILITY:
      return 'facility'
    case MODEL_NAMES.FACILITY_PROFILE:
      return 'facility_profile'
    case MODEL_NAMES.AUTH_FORM:
      return 'auth_form'
    case MODEL_NAMES.DOCTOR:
      return 'doctor'
    case MODEL_NAMES.FACILITY_CLUSTER:
      return 'facility_cluster'
    case MODEL_NAMES.DOCTOR_TO_FACILITY:
      return 'doctor_to_facility_relation'
    case MODEL_NAMES.GAIA_USER:
      return 'gaia_user'
    case MODEL_NAMES.GROUP:
      return 'group'
    case MODEL_NAMES.HEALTH_NETWORK:
      return 'health_network'
    case MODEL_NAMES.INSURANCE_COMPANY:
      return 'insurance_company'
    case MODEL_NAMES.AGENCY:
      return 'agency'
    case MODEL_NAMES.ASSIGNMENT_COPY_COMPANY:
      return 'assignment_copy_company'
    case MODEL_NAMES.VENDOR:
      return 'vendor'
    case MODEL_NAMES.FACILITY_VENDOR:
      return 'facility_vendor'
    case MODEL_NAMES.DOCTOR_VENDOR:
      return 'doctor_vendor'
    case MODEL_NAMES.RETRIEVER:
      return 'retriever'
    default:
      return modelName
  }
}

export const getActivityLogNamesFromPath = modelName => {
  switch (modelName) {
    case 'facility':
      return 'Facility'
    case 'facility_profile':
      return 'Facility Profile'
    case 'auth_form':
      return 'Auth Form'
    case 'doctor':
      return 'Doctor'
    case 'facility_cluster':
      return 'Facility Cluster'
    case 'doctor_to_facility_relation':
      return 'Doctor to Facility Relation'
    case 'gaia_user':
      return 'Jaia User'
    case 'group':
      return 'Role'
    case 'health_network':
      return 'Health Network'
    case 'insurance_company':
      return 'Insurance'
    case 'agency':
      return 'Agency'
    case 'assignment_copy_company':
      return 'СС Assignment'
    case 'vendor':
      return 'Vendor'
    case 'facility_vendor':
      return 'Facility Vendor'
    case 'doctor_vendor':
      return 'Doctor Vendor'
    case 'retriever':
      return 'Retriever'
    default:
      return modelName
  }
}

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import {
  IDLE_STATUS,
  LOADING_STATUS,
  SUCCESS_STATUS,
  FAILURE_STATUS,
} from '../../../constants'
import { doctorAdapter } from '../doctors/reducer'
import UserService from '../../../services/UserService'

const initialUser = localStorage.getItem('user')
  ? JSON.parse(localStorage.getItem('user'))
  : null

const initialState = doctorAdapter.getInitialState({
  email: null,
  token: null,
  otpAuthToken: localStorage.getItem('otp_auth_token') || null,
  isAuthError: false,
  permissions: [],
  permissionsStatus: IDLE_STATUS,
  isUserDataLoaded: false,
  ...initialUser,
})

export const getAuthUser = createAsyncThunk('auth/user', async () => {
  const response = await UserService.getCurrentUser()

  return response
})

export const authAdapter = createEntityAdapter()
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, { payload }) => ({ ...state, ...payload }),
    logout: state => {
      state.token = null
      state.otpAuthToken = null
      state.email = null
      state.permissions = []
      state.permissionsStatus = IDLE_STATUS
      state.isUserDataLoaded = false
      state.initialUser = null
      state.isAuthError = false

      authAdapter.removeAll(state)
    },
    authError: state => {
      state.isAuthError = true
    },
    userDataLoaded: (state, { payload }) => {
      state.isUserDataLoaded = payload
    },
    userPermissionsLoaded: (state, { payload }) => {
      state.permissionsStatus = SUCCESS_STATUS
      state.permissions = payload
    },
    userPermissionsLoadStarted: state => {
      state.permissionsStatus = LOADING_STATUS
    },
    userPermissionsLoadFailed: state => {
      state.permissionsStatus = FAILURE_STATUS
    },
  },

  extraReducers: builder => {
    builder.addCase(getAuthUser.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(getAuthUser.fulfilled, (state, { payload }) => {
      authAdapter.upsertOne(state, payload)
    })
  },
})

const { actions, reducer } = authSlice

export const {
  login,
  logout,
  authError,
  userPermissionsLoaded,
  userPermissionsLoadStarted,
  userPermissionsLoadFailed,
  userDataLoaded,
} = actions

export default reducer

import React, { useState, useRef, useEffect } from 'react'
import cx from 'classnames'
import { Modal, Row, Col, Typography, Button } from 'antd'
import { CloseOutlined, ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'

import Table from '../../Tables/SearchTable'

import { useToggle } from '../../../../hooks/useToggle'
import { caseInformationColumns } from '../../../../data'

const SCROLLBAR_WIDTH = window.innerWidth - document.body.clientWidth
const TITLE_HEIGHT = 54
/* eslint-disable */
// disable eslint because it requires an array of useEffect dependencies
const RelateExceptionModal = ({
  children,
  id,
  dataCaseInformation,
  isVisibleModal,
  onCloseModal,
  caseInformationId,
  scrollRef,
  isAutoScrollModal,
}) => {
  const [bottomTableHeight, setBottomTableHeight] = useState('')
  const [modalBottomPadding, setModalBottomPadding] = useState('')

  const [open, setOpen] = useToggle(false)
  const footer = useRef()
  const caseInfoRef = useRef()

  useEffect(() => {
    if (caseInfoRef && caseInfoRef.current && caseInfoRef.current.clientHeight) {
      setBottomTableHeight(caseInfoRef.current.clientHeight)
    }
  })

  useEffect(() => {
    setModalBottomPadding(bottomTableHeight)
  }, [bottomTableHeight])

  if (scrollRef?.current && isAutoScrollModal) {
    scrollRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <Modal
      wrapClassName="relate-modal__wrapper"
      style={{ paddingBottom: modalBottomPadding }}
      visible={isVisibleModal}
      footer={null}
      centered
      destroyOnClose
      width="85%"
      transitionName=""
      maskClosable={false}
      maskTransitionName=""
      closeIcon={<CloseOutlined className="relate-modal__close-icon" />}
      onCancel={() => {
        onCloseModal()
      }}
      maskStyle={{
        background: 'rgba(0, 0, 0, 0.6)',
        backdropFilter: 'blur(10px)',
      }}
    >
      {React.cloneElement(children, { id })}
      <div
        className={cx('relate-modal__footer', { 'is-toggled': open })}
        style={{
          width: `calc(100% - ${SCROLLBAR_WIDTH}px)`,
          bottom: open ? `-${footer?.current?.clientHeight - TITLE_HEIGHT}px` : 0,
        }}
        ref={footer}
      >
        <div ref={caseInfoRef} className="relate-modal__footer--inner">
          <Table
            tableTitle={
              <Row justify="space-between" align="middle">
                <Col>
                  <Typography.Title level={4} className="relate-modal__footer-title">
                    Case Information # {caseInformationId}
                  </Typography.Title>
                </Col>
                <Col>
                  <Button
                    onClick={setOpen}
                    type="text"
                    icon={
                      !open ? (
                        <ArrowDownOutlined className="relate-modal__footer-icon" />
                      ) : (
                        <ArrowUpOutlined className="relate-modal__footer-icon" />
                      )
                    }
                  />
                </Col>
              </Row>
            }
            columns={caseInformationColumns.filter(item => item.key !== 'searchOptions')}
            totalData={dataCaseInformation}
            useClearData
          />
        </div>
      </div>
    </Modal>
  )
}

export default RelateExceptionModal

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DatePicker, Row, Col, Form, Select, Space } from 'antd'
import useDispatchHttp from '../../../hooks/dispatchHttpHandler'
import {
  fetchAllUsers,
  selectAllUsersForSelect,
  isUsersFetching,
} from '../../../state/modules/users'
import { DATE_FORMAT } from '../../../constants'
import { useUser } from '../../../providers/UserProvider'

const defaultActorOptions = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Jaia User',
    value: 'gaia_user',
  },
  {
    label: 'System',
    value: 'parameds_cms',
  },
]

const ActivityLogFilters = ({ updateSearchParam, searchParams }) => {
  const dispatchHttp = useDispatchHttp()
  const [actorOptions, setActorOptions] = useState([...defaultActorOptions])
  const { id, firstname, lastname } = useSelector(state => state.auth)
  const users = useSelector(selectAllUsersForSelect)
  const isFetchingUsers = useSelector(isUsersFetching)
  const { isUserActionAllowed } = useUser()
  const [form] = Form.useForm()

  useEffect(() => {
    if (isUserActionAllowed('view_user')) {
      setActorOptions([...defaultActorOptions, ...users])
    } else {
      setActorOptions([
        ...defaultActorOptions,
        { value: id, label: `${firstname} ${lastname}` },
      ])
    }
    form.setFieldsValue({
      actor: searchParams.user_id || searchParams.edited,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users])

  useEffect(() => {
    if (isUserActionAllowed('view_user')) {
      dispatchHttp(fetchAllUsers({ skipPagination: true }))
    }
  }, [dispatchHttp, isUserActionAllowed])

  const handleSelect = value => {
    let param
    const isDefaultActorOption = defaultActorOptions.filter(
      variant => value === variant.value
    ).length

    if (isDefaultActorOption) {
      param = { edited: value }
    } else {
      param = { user_id: value }
    }

    updateSearchParam(param)
  }

  const handleFilterByDate = (dates, datesString) => {
    const date_range = datesString.filter(date => !!date).toString()

    updateSearchParam({ date_range })
  }

  return (
    <Row className="activity-log-filter-form" justify="end" align="bottom">
      <Col>
        <Form form={form} layout="inline" className="sort-form" component="div">
          <Space>
            <DatePicker.RangePicker
              format={DATE_FORMAT.year_month_day}
              onChange={handleFilterByDate}
            />
            <Form.Item label="Actor" className="sort-form__select-field" name="actor">
              <Select
                size="middle"
                className="sort-form__select"
                onChange={handleSelect}
                options={actorOptions}
                placeholder="Select here"
                value={searchParams.user_id || searchParams.edited}
                loading={isUserActionAllowed('view_user') && isFetchingUsers}
              />
            </Form.Item>
          </Space>
        </Form>
      </Col>
    </Row>
  )
}

export default ActivityLogFilters

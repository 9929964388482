import React, { memo, useCallback } from 'react'
import {
  Card,
  Empty,
  Skeleton,
  Typography,
  Divider,
  Space,
  Row,
  Col,
  Dropdown,
  Menu,
} from 'antd'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { ClockCircleOutlined, FileOutlined, MoreOutlined } from '@ant-design/icons'

import MedicalEntityModal from '../Modals/MedicalEntityModal'
import AuthFormsList from '../Forms/AuthFormsList'
import AuthForm from '../Forms/CreateAuthForm'
import UpdateFacilityForm from '../Forms/FacilityProfileForm'

import {
  capitalizeEveryWord,
  formatCurrency,
  formatWeekDays,
  convertTextToValue,
  formatWorkingHours,
  formatDate,
} from '../../../utils/helpers'
import { LOADING_STATUS, FACILITY_PROFILE } from '../../../constants'
import useDispatchHttp from '../../../hooks/dispatchHttpHandler'
import {
  fetchFormById,
  fetchFacilityProfileById,
  createAuthForm,
  updateFacilityProfile,
} from '../../../state/modules/facilities'
import { createAuthFormSchema } from '../../../schema/createAuthForm'
import { createFacilityProfileSchema } from '../../../schema/createFacilityProfile'
import { communicationOptions, paymentOptions, postbillOptions } from '../../../data'
import { useUser } from '../../../providers/UserProvider'
import { downloadFileByUrl } from '../../../utils/functions'

const FacilityProfileCard = ({ data, title, loadingStatus }) => {
  const dispatchHttp = useDispatchHttp()
  const { isUserActionAllowed } = useUser()
  const primaryCopyCompany =
    data?.copy_companies?.find(item => item.is_primary) ||
    (data?.copy_companies && data?.copy_companies[0]) ||
    {}

  // useEffect(() => {
  //   if (data?.form?.id && isUserActionAllowed('download_authorizationform')) {
  //     dispatchHttp(fetchFormById({ formId: data?.form?.id, id: data?.id }))
  //   }
  // }, [dispatchHttp, data?.form?.id, data?.id, isUserActionAllowed])

  const handleFormCreate = useCallback(
    async (_, values) => {
      await dispatchHttp(
        createAuthForm({ ...values, facility_profile_id: data?.id }),
        'Authorization Form has been successfully created'
      )
      await dispatchHttp(fetchFacilityProfileById(data?.id))
    },
    [dispatchHttp, data?.id]
  )

  const handleFacilityProfileUpdate = useCallback(
    async (facilityId, values) => {
      await dispatchHttp(
        updateFacilityProfile({
          facilityId,
          data: {
            days_open: values.days_open || [],
            email: values.email || '',
            contact_name: values.contact_name || '',
            emr_data_source: values.emr_data_source || '',
            emr_vendor_name: values.emr_vendor_name || '',
            notes: values.notes || '',
            working_hours_end: values.working_hours_end || null,
            working_hours_start: values.working_hours_start || null,
          },
        }),
        'Facility Profile has been successfully updated'
      )
      await dispatchHttp(fetchFacilityProfileById(data?.id))
    },
    [data?.id, dispatchHttp]
  )

  const initialValues = {
    communication_method: convertTextToValue(
      data?.communication_method,
      communicationOptions
    ),
    payment_type: convertTextToValue(data?.payment_type, paymentOptions),
    postbill: convertTextToValue(data?.postbill, postbillOptions),
    main_retriever: data?.main_retriever?.id,
    last_retriever: data?.last_retriever?.fullname,
    provider_name: data?.provider_name,
    last_case_date: data?.last_case_date,
    case_count: data?.case_count,
    emr_vendor_name: data?.emr_vendor_name,
    emr_data_source: data?.emr_data_source,
    working_hours_start: data?.working_hours_start || '09:00:00',
    working_hours_end: data?.working_hours_end || '17:00:00',
    turn_around_min: data?.turn_around_min,
    turn_around_max: data?.turn_around_max,
    turn_around_avg: data?.turn_around_avg,
    contact_name: data?.contact_name,
    min_fee: data?.min_fee,
    max_fee: data?.max_fee,
    avg_facility_records_fee: data?.avg_facility_records_fee,
    avg_fee: data?.avg_fee,
    total_paid: data?.total_paid,
    days_open: data?.days_open,
    notes: data?.notes,
    email: data?.email,
  }

  const downloadForm = useCallback(async () => {
    const resp = await dispatchHttp(
      fetchFormById({ formId: data?.form?.id, id: data.id })
    )

    downloadFileByUrl(resp?.changes?.link)
  }, [dispatchHttp, data])

  const isShowDownloadForm =
    data?.form && isUserActionAllowed('download_authorizationform')
  const isShowCreateForm = !data?.form && isUserActionAllowed('add_authorizationform')

  const isShowDivider =
    (isShowDownloadForm || isShowCreateForm) &&
    isUserActionAllowed('view_authorizationform')

  const isShowAuthFormSection =
    isShowDownloadForm ||
    isShowCreateForm ||
    isUserActionAllowed('view_authorizationform')

  const menuOptions = [
    {
      content: (
        <MedicalEntityModal
          title="Edit Facility Profile"
          trigger={<div>Edit</div>}
          form={
            <UpdateFacilityForm
              initialValues={initialValues}
              onSubmit={handleFacilityProfileUpdate}
              validationSchema={createFacilityProfileSchema}
              action="edit"
              entityId={data?.id}
            />
          }
        />
      ),
      key: 'edit',
      hide: !isUserActionAllowed('change_facilityprofile'),
    },
    {
      content: (
        <Link to={`/${FACILITY_PROFILE}/${data.id}/`}>Open Facility Profile Page</Link>
      ),
      key: 'link',
    },
  ]

  const mainRetriever = data?.main_retriever_definition?.contname ? (
    <span>
      {data?.main_retriever_definition?.fullname ? (
        <span>
          {data?.main_retriever_definition?.fullname}
          <Divider type="vertical" className="profile-card__divider" />
        </span>
      ) : null}
      {data?.main_retriever_definition?.contname}
    </span>
  ) : (
    data?.main_retriever_definition?.fullname
  )

  const lastRetriever =
    data?.last_retriever?.fullname || data?.last_retriever?.contname ? (
      <span>
        {data?.last_retriever?.fullname ? (
          <span>
            {capitalizeEveryWord(data?.last_retriever?.fullname)}
            <Divider type="vertical" className="profile-card__divider" />
          </span>
        ) : null}
        {capitalizeEveryWord(data?.last_retriever?.contname)}
      </span>
    ) : (
      '-'
    )

  return (
    <div className="info-card">
      <div className="info-card__heading">{title}</div>
      <Card bordered={false}>
        <Skeleton
          loading={loadingStatus === LOADING_STATUS}
          active
          paragraph={{ rows: 4 }}
        >
          {!!data && !!Object.values(data).length ? (
            <Row wrap={false}>
              <Col flex={1}>
                <div className="info-card__body">
                  <div className="info-card__row">
                    <div className="info-card__col">
                      <div className="info-card__col--label">Main Retriever</div>
                      <div className="info-card__col--value">{mainRetriever}</div>
                    </div>
                    <div className="info-card__col">
                      <div className="info-card__col--label">Past Retrievers</div>
                      <div className="info-card__col--value">
                        {lastRetriever}
                        <Divider type="vertical" className="profile-card__divider" />
                        {isUserActionAllowed('view_retriever') && (
                          <Link to={`/facility-profiles/${data?.id}/retrievers/`}>
                            <Space size={6}>
                              <ClockCircleOutlined />
                              History
                            </Space>
                          </Link>
                        )}
                      </div>
                    </div>
                    <div className="info-card__col">
                      <div className="info-card__col--label">Communication Method</div>
                      <div className="info-card__col--value">
                        {data?.communication_method}
                      </div>
                    </div>
                    <div className="info-card__col">
                      <div className="info-card__col--label">Payment Method</div>
                      <div className="info-card__col--value">{data?.payment_type}</div>
                    </div>
                    <div className="info-card__col">
                      <div className="info-card__col--label">Last Case Date</div>
                      <div className="info-card__col--value">
                        {formatDate(data?.last_case_date)}
                      </div>
                    </div>
                    <div className="info-card__col">
                      <div className="info-card__col--label">Case Count</div>
                      <div className="info-card__col--value">{data?.case_count}</div>
                    </div>
                    <div className="info-card__col">
                      <div className="info-card__col--label">
                        Special Authorization Form Requirement
                      </div>
                      <div className="info-card__col--value">{data?.special_auth}</div>
                    </div>
                    {isShowAuthFormSection && (
                      <div className="info-card__col">
                        <div className="info-card__col--label">
                          Special Authorization Form
                        </div>
                        <div className="info-card__col--value">
                          <>
                            {isShowDownloadForm && (
                              <Typography.Link onClick={downloadForm}>
                                <Space size={6}>
                                  <FileOutlined />
                                  Download Form
                                </Space>
                              </Typography.Link>
                            )}
                            {isShowCreateForm && (
                              <MedicalEntityModal
                                title="Create Special Authorization Form"
                                className="set-retriever-modal"
                                width={580}
                                trigger={
                                  <Typography.Link>
                                    <Space size={6}>
                                      <FileOutlined />
                                      Add New Form
                                    </Space>
                                  </Typography.Link>
                                }
                                form={
                                  <AuthForm
                                    validationSchema={createAuthFormSchema}
                                    onSubmit={handleFormCreate}
                                    initialValues={{
                                      special_auth: 0,
                                      e_signature: 0,
                                      attachment: null,
                                    }}
                                  />
                                }
                              />
                            )}
                            {isShowDivider && (
                              <Divider
                                type="vertical"
                                className="profile-card__divider"
                              />
                            )}
                            {isUserActionAllowed('view_authorizationform') && (
                              <MedicalEntityModal
                                title="Special Authorization Forms"
                                className="set-retriever-modal"
                                width={1200}
                                trigger={
                                  <Typography.Link>
                                    <Space size={6}>
                                      <ClockCircleOutlined />
                                      History
                                    </Space>
                                  </Typography.Link>
                                }
                                form={
                                  <AuthFormsList
                                    initialValues={data}
                                    onSubmit={handleFormCreate}
                                    canDelete={isUserActionAllowed(
                                      'delete_authorizationform'
                                    )}
                                  />
                                }
                              />
                            )}
                          </>
                        </div>
                      </div>
                    )}
                    <div className="info-card__col">
                      <div className="info-card__col--label">
                        E-Signature Verification
                      </div>
                      <div className="info-card__col--value">{data?.e_signature}</div>
                    </div>
                  </div>
                  <div className="info-card__row">
                    <div className="info-card__col">
                      <div className="info-card__col--label">Copy Company</div>
                      <div className="info-card__col--value">
                        {data?.copy_companies_definition}
                      </div>
                    </div>
                    <div className="info-card__col">
                      <div className="info-card__col--label">Copy Company Site ID</div>
                      <div className="info-card__col--value">
                        {primaryCopyCompany?.site_id ?? '-'}
                      </div>
                    </div>
                    <div className="info-card__col">
                      <div className="info-card__col--label">EMR Vendor</div>
                      <div className="info-card__col--value">{data?.emr_vendor_name}</div>
                    </div>
                    <div className="info-card__col">
                      <div className="info-card__col--label">EMR Data Source</div>
                      <div className="info-card__col--value">{data?.emr_data_source}</div>
                    </div>
                    <div className="info-card__col">
                      <div className="info-card__col--label">Days Open</div>
                      <div className="info-card__col--value">
                        {formatWeekDays(data?.days_open)}
                      </div>
                    </div>
                    <div className="info-card__col">
                      <div className="info-card__col--label">Hours Open</div>
                      <div className="info-card__col--value">
                        {formatWorkingHours(
                          data?.working_hours_start,
                          data?.working_hours_end
                        )}
                      </div>
                    </div>
                    <div className="info-card__col">
                      <div className="info-card__col--label">Turn Around Time</div>
                      <div className="info-card__col--value">
                        <Space size={12}>
                          {!!data?.turn_around_min && (
                            <span>
                              <Typography.Text type="secondary">Min </Typography.Text>
                              {data?.turn_around_min} days
                            </span>
                          )}
                          {!!data?.turn_around_max && (
                            <span>
                              <Typography.Text type="secondary">Max </Typography.Text>
                              {data?.turn_around_max} days
                            </span>
                          )}
                          {!!data?.turn_around_avg && (
                            <span>
                              <Typography.Text type="secondary">Avg </Typography.Text>
                              {data?.turn_around_avg} days
                            </span>
                          )}
                        </Space>
                      </div>
                    </div>
                    <div className="info-card__col">
                      <div className="info-card__col--label">
                        Contact Name of Medical Department
                      </div>
                      <div className="info-card__col--value">{data?.contact_name}</div>
                    </div>
                    <div className="info-card__col">
                      <div className="info-card__col--label">Email</div>
                      <div className="info-card__col--value">{data?.email}</div>
                    </div>
                  </div>
                  <div className="info-card__row">
                    <div className="info-card__col">
                      <div className="info-card__col--label">Min Fee</div>
                      <div className="info-card__col--value">
                        {formatCurrency(data?.min_fee)}
                      </div>
                    </div>
                    <div className="info-card__col">
                      <div className="info-card__col--label">Max Fee</div>
                      <div className="info-card__col--value">
                        {formatCurrency(data?.max_fee)}
                      </div>
                    </div>
                    <div className="info-card__col">
                      <div className="info-card__col--label">
                        Average Facility Records Fee
                      </div>
                      <div className="info-card__col--value">
                        {formatCurrency(data?.avg_facility_records_fee)}
                      </div>
                    </div>
                    <div className="info-card__col">
                      <div className="info-card__col--label">Avg Fee</div>
                      <div className="info-card__col--value">
                        {formatCurrency(data?.avg_fee)}
                      </div>
                    </div>
                    <div className="info-card__col">
                      <div className="info-card__col--label">Postbill</div>
                      <div className="info-card__col--value">{data?.postbill}</div>
                    </div>
                    <div className="info-card__col">
                      <div className="info-card__col--label">Total Paid</div>
                      <div className="info-card__col--value">
                        {formatCurrency(data?.total_paid)}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col>
                <Dropdown.Button
                  icon={<MoreOutlined />}
                  trigger="click"
                  overlay={
                    <Menu>
                      {menuOptions
                        .filter(item => !item.hide)
                        .map(({ content, key, ...props }) => (
                          <Menu.Item key={key} {...props}>
                            {content}
                          </Menu.Item>
                        ))}
                    </Menu>
                  }
                />
              </Col>
            </Row>
          ) : (
            <Empty imageStyle={{ display: 'none' }} description="No results" />
          )}
        </Skeleton>
      </Card>
    </div>
  )
}

FacilityProfileCard.propTypes = {
  data: PropTypes.shape({
    main_retriever_definition: PropTypes.shape({
      contname: PropTypes.string,
      fullname: PropTypes.string,
    }),
    copy_companies_definition: PropTypes.string,
    avg_facility_records_fee: PropTypes.number,
    avg_fee: PropTypes.number,
    case_count: PropTypes.number,
    communication_method: PropTypes.string,
    contact_name: PropTypes.string,
    copy_companies: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    days_open: PropTypes.arrayOf(PropTypes.number),
    notes: PropTypes.string,
    emr_data_source: PropTypes.number,
    emr_vendor_name: PropTypes.string,
    form: PropTypes.shape({
      id: PropTypes.string,
    }),
    special_auth: PropTypes.string,
    e_signature: PropTypes.string,
    id: PropTypes.number,
    last_case_date: PropTypes.string,
    last_retriever: PropTypes.shape({
      fullname: PropTypes.string,
      contname: PropTypes.string,
    }),
    main_retriever: PropTypes.shape({
      fullname: PropTypes.string,
      id: PropTypes.number,
      contname: PropTypes.string,
    }),
    max_fee: PropTypes.number,
    min_fee: PropTypes.number,
    payment_type: PropTypes.string,
    payments_postbill_count: PropTypes.number,
    postbill: PropTypes.string,
    provider_name: PropTypes.string,
    total_paid: PropTypes.number,
    turn_around_avg: PropTypes.number,
    turn_around_max: PropTypes.number,
    turn_around_min: PropTypes.number,
    working_hours_end: PropTypes.string,
    working_hours_start: PropTypes.string,
    link: PropTypes.string,
    email: PropTypes.string,
  }),
  onUnlink: PropTypes.func,
  loadingStatus: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

FacilityProfileCard.defaultProps = {
  data: {},
  loadingStatus: '',
  title: 'Facility Profile',
  onUnlink: null,
}

export default memo(FacilityProfileCard)

import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getPagination } from '../../../utils/functions'

import ExceptionService from '../../../services/ExceptionService'

export const getExceptionList = createAsyncThunk(
  'exceptions/getExceptions',
  async data => {
    const filteredData = { ...data }

    // tab All have key 4. In that case we need to remove status from search params
    if (+data?.status === 4) {
      delete filteredData.status
    }

    if (!filteredData?.cms_id) {
      return getPagination(
        filteredData,
        ExceptionService.getExceptionList.bind(ExceptionService)
      )
    }

    const response = await ExceptionService.getExceptionList(null, filteredData)

    return response
  }
)

export const fetchExceptionById = createAsyncThunk(
  'exceptions/fetchExceptionById',
  async ({ id, params }) => {
    const response = await ExceptionService.getExceptionById(id, params)

    return response
  }
)

export const fetchPrevNextCases = createAsyncThunk(
  'exceptions/fetchPrevNextCases',
  async ({ id, params }) => {
    const response = await ExceptionService.getPrevNextCases(id, params)

    return response
  }
)

export const fetchExceptionHistoryLog = createAsyncThunk(
  'exceptions/fetchExceptionHistoryLog',
  async data =>
    getPagination(data, ExceptionService.getExceptionHistoryLog.bind(ExceptionService))
)

export const relateException = createAsyncThunk(
  'exceptions/relateException',
  async data => {
    const response = await ExceptionService.relateException(data)

    return response
  }
)

export const changeExceptionStatus = createAsyncThunk(
  'exceptions/changeExceptionStatus',
  async ({ id, data, status }) => {
    const response = await ExceptionService.changeExceptionStatus(id, data, status)

    return response
  }
)

export const removeRelation = createAsyncThunk(
  'exceptions/removeRelation',
  async ({ id, data }) => {
    const response = await ExceptionService.removeRelation(id, data)

    return response
  }
)

export const changeDoctorSuggestionStatus = createAsyncThunk(
  'exceptions/changeDoctorSuggestionStatus',
  async ({ data }) => {
    const response = await ExceptionService.changeDoctorSuggestionStatus(data)

    return response
  }
)

export const changeFacilitySuggestionStatus = createAsyncThunk(
  'exceptions/changeFacilitySuggestionStatus',
  async ({ data }) => {
    const response = await ExceptionService.changeFacilitySuggestionStatus(data)

    return response
  }
)

export const changeExceptionGoals = createAsyncThunk(
  'exceptions/changeExceptionGoals',
  async ({ id, data }) => {
    const response = await ExceptionService.changeExceptionGoals(id, data)

    return response
  }
)

export const resetState = createAction('exceptions/resetState')

import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { notification } from 'antd'
import { logoutUser, setAuthError } from '../state/modules/auth'
import { AUTH_CREDS_NOT_PROVIDED, INVALID_TOKEN } from '../constants'

const isAuthError = msg => msg === AUTH_CREDS_NOT_PROVIDED || msg === INVALID_TOKEN

export default () => {
  const dispatch = useDispatch()
  const duration = 2

  const dispatchHandler = useCallback(
    async (reducer, successMsg) => {
      const response = await dispatch(reducer)

      if (response && response.error) {
        if (isAuthError(response.error.message)) {
          dispatch(setAuthError())
          dispatch(logoutUser())
          notification.destroy()
          notification.error({
            message: 'Session has been expired',
            duration,
          })
        } else {
          notification.error({
            message: response.error.message,
            duration,
          })
        }

        throw response.error
      }

      if (successMsg) {
        notification.success({
          message: successMsg,
        })
      }
      return response?.payload
    },
    [dispatch]
  )

  return dispatchHandler
}

import React, { useEffect, useMemo, useCallback, memo, useState, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Spin, Row, Col, Button, Typography, Space } from 'antd'
import { LinkOutlined } from '@ant-design/icons'

import InfoCard from '../components/InfoCard'
import FacilityProfileCard from '../components/FacilityProfileCard'
import DoctorForm from '../components/Forms/DoctorForm'
import SearchTable from '../components/Tables/SearchTable/index'
import MedicalEntityModal from '../components/Modals/MedicalEntityModal'
import showConfirmDialog from '../components/Modals/DeleteConfirm'
import FacilityForm from '../components/Forms/FacilityForm'
import RelateFacilityModal from '../components/Modals/RelateDoctorFacilityModal'
import RelateVendorModal from '../components/Modals/RelateVendorModal/RelateVendorModal'

import {
  LOADING_STATUS,
  FACILITY,
  DOCTOR,
  NO_SKIP_ON_IMPORT_VENDOR_LIST,
} from '../../constants'
import {
  fetchDoctorById,
  selectDoctorById,
  selectRelatedFacilities,
  getRelatedFacilities,
  updateDoctor,
  deleteDoctor,
  linkFacility,
  unlinkFacility,
} from '../../state/modules/doctors'
import {
  updateFacility,
  fetchFacilityProfileById,
  selectFacilityProfileById,
  createFacilityWithDoctor,
} from '../../state/modules/facilities'
import { getVendors, selectAllVendors } from '../../state/modules/vendors'
import {
  addVendorDoctor,
  removeVendorDoctor,
  updateVendorDoctor,
} from '../../state/modules/vendorDoctors'
import useDispatchHttp from '../../hooks/dispatchHttpHandler'
import { createUpdateDoctorSchema } from '../../schema/createUpdateDoctor'
import { updateCreateFacilitySchema } from '../../schema/updateCreateFacility'
import {
  facilityTableColumns,
  initialDoctorValues,
  initialFacilityValues,
} from '../../data'
import { routePaths } from '../../utils/routes'
import { useUser } from '../../providers/UserProvider'
import { formatPhoneNumbers } from '../../utils/helpers'

const Doctor = ({
  id,
  relateDoctor,
  relateFacilityProfile,
  exceptionId,
  hasRelation,
  removeRelation,
  tableRef,
  isActionDisabled,
}) => {
  const history = useHistory()
  const location = useLocation()
  const { isUserActionAllowed } = useUser()
  const { id: currentUserID } = useSelector(state => state.auth)
  const [userID, setUserID] = useState(null)
  const [relatedFacilitiesPagination, setRelatedFacilitiesPagination] = useState({
    page: 1,
    pageSize: 10,
  })
  const [isDoctorRemoved, setDoctorRemoved] = useState(false)
  const [isDoctorLoading, setDoctorLoading] = useState(false)
  const [isRelatedFacilitiesLoading, setRelatedFacilitiesLoading] = useState(false)
  const [isFacilityProfileLoading, setFacilityProfileLoading] = useState(false)
  const { status } = useSelector(state => state.facilities)
  const doctorData = useSelector(state => selectDoctorById(state, id))
  const vendorsData = useSelector(selectAllVendors)
  const allRelatedFacilities = useSelector(selectRelatedFacilities)
  const { count: facilityCount } = useSelector(state => state.doctors.related_facilities)

  const facilityProfileData = useSelector(state =>
    selectFacilityProfileById(state, doctorData?.facility_profile)
  )
  const dispatchHttp = useDispatchHttp()

  const doctorsRef = useRef()
  const handleDoctorUpdate = useCallback(
    async (doctorId, data) => {
      await dispatchHttp(
        updateDoctor({
          id: doctorId,
          data: {
            ...data,
            phone_numbers: formatPhoneNumbers(data.phone_numbers),
            fax_numbers: formatPhoneNumbers(data.fax_numbers),
            npi: data.npi || null,
            taxonomy_group_name: data.taxonomy_group_name || null,
          },
        }),
        'Doctor has been successfully updated'
      )
      await dispatchHttp(fetchDoctorById(id))
    },
    [dispatchHttp, id]
  )
  const handleFacilityUpdate = useCallback(
    async (facilityId, data) => {
      await dispatchHttp(
        updateFacility({
          facilityId,
          data,
        }),
        'Facility has been successfully updated'
      )

      await dispatchHttp(
        getRelatedFacilities({ ...relatedFacilitiesPagination, data: id })
      )
    },
    [dispatchHttp, relatedFacilitiesPagination, id]
  )

  const handleDelete = useCallback(
    async facilityId => {
      try {
        setDoctorRemoved(true)
        await dispatchHttp(
          deleteDoctor(facilityId),
          'Doctor has been successfully deleted'
        )
        history.push('/search')
      } catch (e) {
        setDoctorRemoved(false)
      }
    },

    [dispatchHttp, history]
  )

  const handleLinkFacility = useCallback(
    async data => {
      await dispatchHttp(
        linkFacility({ id, data }),
        'Facility has been successfully related'
      )
      await dispatchHttp(fetchDoctorById(id))
      await dispatchHttp(getRelatedFacilities({ data: id }))
    },
    [dispatchHttp, id]
  )

  const handleUnlinkFacility = useCallback(
    async data => {
      await dispatchHttp(
        unlinkFacility({ id, data }),
        'Relation has been successfully removed'
      )
      await dispatchHttp(fetchDoctorById(id))
      await dispatchHttp(getRelatedFacilities({ data: id }))
    },
    [dispatchHttp, id]
  )

  const handleCreateFacilityAndLinkDoctor = useCallback(
    async (doctorId, data) => {
      const {
        preprocessing_doctor_id,
        is_sole_proprietor,
        first_name,
        last_name,
        related_facilities,
        id: doctorUnusedId,
        ...rest
      } = data
      const result = await dispatchHttp(
        createFacilityWithDoctor({ doctorId, data: rest }),
        'Facility has been successfully created'
      )

      history.push(routePaths.openFacilityById(result.id))
    },
    [dispatchHttp, history]
  )

  const removeVendorRelation = async data => {
    await dispatchHttp(
      removeVendorDoctor(data.id),
      'Doctor has been successfully removed from the vendor'
    )
  }

  const addVendorRelation = async data => {
    let preparedData = {
      doctor_id: id,
      vendor_id: data.vendor_id,
      organization_id: data.organization_id,
      organization_name: data.organization_name,
    }

    if (!NO_SKIP_ON_IMPORT_VENDOR_LIST.includes(data.vendor__name)) {
      preparedData = {
        ...preparedData,
        skip_on_import: data.skip_on_import,
      }
    }

    await dispatchHttp(
      addVendorDoctor({ data: preparedData }),
      'Doctor has been successfully added to a vendor'
    )
  }

  const updateVendorRelation = async data => {
    let updatedData = {
      vendor_id: id,
      organization_id: data.organization_id,
      organization_name: data.organization_name,
    }

    if (!NO_SKIP_ON_IMPORT_VENDOR_LIST.includes(data.vendor__name)) {
      updatedData = {
        ...updatedData,
        skip_on_import: data.skip_on_import,
      }
    }

    dispatchHttp(
      updateVendorDoctor({ id: data.id, data: updatedData }),
      'Doctor relation updated successfully'
    )
  }

  const openHistory = useCallback(() => {
    history.push(
      routePaths.openEntityHistory('doctor', doctorData?.id, userID, 'all', 'doctor')
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorData, history])

  const initialValues = {
    ...initialDoctorValues,
    ...doctorData,
  }

  const initialTouched = {
    addresses: initialValues.addresses.map(() => ({
      type: true,
    })),
    phone_numbers: initialValues.phone_numbers.map(() => ({
      type: true,
    })),
    fax_numbers: initialValues.fax_numbers.map(() => ({
      type: true,
    })),
    emails: initialValues.emails.map(() => ({
      type: true,
    })),
  }

  const callGetVendors = useCallback(async () => {
    await dispatchHttp(getVendors({ skipPagination: true }))
  }, [dispatchHttp])

  const callGetDoctorById = useCallback(async () => {
    setDoctorLoading(true)
    await dispatchHttp(fetchDoctorById(id))
    setDoctorLoading(false)
  }, [dispatchHttp, id])

  const callGetRelatedFacilities = useCallback(async () => {
    setRelatedFacilitiesLoading(true)
    await dispatchHttp(getRelatedFacilities({ data: id }))
    setRelatedFacilitiesLoading(false)
  }, [dispatchHttp, id])

  const callGetFacilityProfileById = useCallback(async () => {
    setFacilityProfileLoading(true)
    await dispatchHttp(fetchFacilityProfileById(doctorData?.facility_profile))
    setFacilityProfileLoading(false)
  }, [dispatchHttp, doctorData?.facility_profile])

  const preparedDataToSendRequests = data => {
    const dataToDelete = doctorData.vendor_relations
      .filter(relation => {
        const isRelationExistInUpdatedData = data.vendors.some(
          element => element.id === relation.id
        )

        return !isRelationExistInUpdatedData
      })
      .map(element => ({ ...element, action: 'delete' }))

    const dataToAdd = data.vendors
      .filter(element => !element.id)
      .map(element => ({ ...element, action: 'add' }))

    const dataToUpdate = data.vendors
      .filter(relation =>
        doctorData.vendor_relations.find(
          element =>
            (element.id &&
              element.id === relation.id &&
              element.skip_on_import !== relation.skip_on_import) ||
            (element.id &&
              element.id === relation.id &&
              element.organization_id !== relation.organization_id) ||
            (element.id &&
              element.id === relation.id &&
              element.organization_name !== relation.organization_name)
        )
      )
      .map(element => ({ ...element, action: 'update' }))

    const dataToSendRequests = { dataToDelete, dataToAdd, dataToUpdate }
    return dataToSendRequests
  }

  const handleVendorRelation = useCallback(
    async data => {
      const preparedData = preparedDataToSendRequests(data)
      await Promise.all(
        preparedData.dataToDelete.map(async vendorRelation => {
          await removeVendorRelation(vendorRelation)
        })
      )

      await Promise.all(
        preparedData.dataToUpdate.map(async vendorRelation => {
          await updateVendorRelation(vendorRelation)
        })
      )

      await Promise.all(
        preparedData.dataToAdd.map(async vendorRelation => {
          await addVendorRelation(vendorRelation)
        })
      )
      callGetDoctorById()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatchHttp, id, doctorData?.vendor_relations]
  )

  const menuOptions = useMemo(
    () => [
      {
        content: (
          <MedicalEntityModal
            title="Edit Doctor"
            form={
              <DoctorForm
                initialValues={initialValues}
                initialTouched={initialTouched}
                onSubmit={handleDoctorUpdate}
                validationSchema={createUpdateDoctorSchema}
                action="edit"
                entityId={id}
              />
            }
            trigger={<div>Edit</div>}
          />
        ),

        key: 'edit',
        hide: !isUserActionAllowed('change_doctor'),
      },
      {
        content: (
          <RelateVendorModal
            title="Manage Vendors"
            initialData={doctorData?.vendor_relations || []}
            vendorsList={vendorsData}
            onSubmit={data => handleVendorRelation(data)}
            closeAfterSubmit
            trigger={<div>Manage Vendors</div>}
          />
        ),
        hide: !isUserActionAllowed('can_manage_vendor_relations'),
        key: 'relate-vendor',
      },
      {
        content: (
          <MedicalEntityModal
            title="Convert To Facility"
            trigger={<div>Convert To Facility</div>}
            form={
              <FacilityForm
                initialValues={{
                  ...initialFacilityValues,
                  ...doctorData,
                  name: `${doctorData?.first_name} ${doctorData?.last_name} Practice`,
                  npi: null,
                  other_names: null,
                }}
                onSubmit={handleCreateFacilityAndLinkDoctor}
                validationSchema={updateCreateFacilitySchema}
                action="edit"
                entityId={doctorData?.id}
              />
            }
          />
        ),
        key: 'convert',
        hide:
          !isUserActionAllowed('manage_relations_facility') ||
          !isUserActionAllowed('add_facility') ||
          !isUserActionAllowed('view_facility') ||
          !isUserActionAllowed('view_doctor') ||
          doctorData?.related_facilities > 0,
      },
      {
        content: (
          <div onClick={openHistory} role="button" onKeyPress={openHistory} tabIndex="0">
            View History
          </div>
        ),
        key: 'history',
        hide: !isUserActionAllowed('view_version'),
      },
      {
        content: 'Remove',
        key: 'remove',
        hide: !isUserActionAllowed('delete_doctor'),
        danger: true,
        onClick: () =>
          showConfirmDialog({
            title: `Do you want to delete ${doctorData.first_name}?`,
            data: id,
            handleConfirm: handleDelete,
          }),
      },
    ],
    // eslint-disable-next-line
    [
      doctorData,
      handleDelete,
      handleDoctorUpdate,
      id,
      handleCreateFacilityAndLinkDoctor,
      openHistory,
      isUserActionAllowed,
    ]
  )

  useEffect(() => {
    callGetVendors()
  }, [callGetVendors])

  useEffect(() => {
    if (!doctorData && !isDoctorRemoved) {
      callGetDoctorById()
    }
  }, [callGetDoctorById, doctorData, isDoctorRemoved])

  useEffect(() => {
    if (isUserActionAllowed('view_facility')) {
      callGetRelatedFacilities()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callGetRelatedFacilities])

  useEffect(() => {
    if (doctorData?.facility_profile && isUserActionAllowed('view_facilityprofile')) {
      callGetFacilityProfileById()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callGetFacilityProfileById, doctorData?.facility_profile])

  useEffect(() => {
    if (
      location?.data?.autoscroll &&
      !isDoctorLoading &&
      !isRelatedFacilitiesLoading &&
      !isFacilityProfileLoading &&
      tableRef?.current
    ) {
      tableRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [
    location,
    isDoctorLoading,
    isRelatedFacilitiesLoading,
    isFacilityProfileLoading,
    tableRef,
  ])

  useEffect(() => {
    if (!isUserActionAllowed('view_user')) {
      setUserID(currentUserID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchHttp])

  return (
    <Spin size="large" spinning={status === LOADING_STATUS}>
      <InfoCard
        entity={DOCTOR}
        data={doctorData}
        loading={status === LOADING_STATUS}
        menu={menuOptions}
        onRelateException={relateDoctor}
        onRemoveRelation={removeRelation}
        hasRelation={hasRelation}
        exceptionId={exceptionId}
        isActionDisabled={isActionDisabled}
        hideMenuOption={menuOptions.filter(item => !item.hide)}
      />
      {isUserActionAllowed('view_facilityprofile') && doctorData?.facility_profile && (
        <FacilityProfileCard
          loadingStatus={status}
          data={facilityProfileData}
          onRelateException={relateFacilityProfile}
          exceptionId={exceptionId}
        />
      )}

      {isUserActionAllowed('view_facility') && (
        <SearchTable
          tableRef={tableRef}
          onLoadData={getRelatedFacilities}
          myRef={doctorsRef}
          tableTitle={
            <Row ref={doctorsRef} justify="space-between" align="middle">
              <Col>Related Facility {!!facilityCount && facilityCount}</Col>
              {isUserActionAllowed('manage_relations_doctor') && (
                <Col>
                  <RelateFacilityModal
                    title="Relate Facility to Doctor"
                    entity={FACILITY}
                    onSubmit={handleLinkFacility}
                    closeAfterSubmit
                    trigger={
                      <Button type="text" size="large">
                        <Typography.Text type="secondary">
                          <Space>
                            <LinkOutlined /> Relate Facility
                          </Space>
                        </Typography.Text>
                      </Button>
                    }
                  />
                </Col>
              )}
            </Row>
          }
          columns={facilityTableColumns}
          totalData={allRelatedFacilities}
          totalCount={facilityCount}
          hideTotalCount
          formData={id}
          entity={FACILITY}
          loadingStatus={status}
          onPageChange={setRelatedFacilitiesPagination}
          editForm={
            isUserActionAllowed('change_facility') && (
              <FacilityForm
                onSubmit={handleFacilityUpdate}
                validationSchema={updateCreateFacilitySchema}
              />
            )
          }
          rowDeletion={
            isUserActionAllowed('manage_relations_doctor') && {
              tooltip: 'Remove Relation',
              confirmMsg: 'Are you sure you want to remove the relation?',
              okText: 'Yes',
              onConfirm: handleUnlinkFacility,
            }
          }
        />
      )}
    </Spin>
  )
}

export default memo(Doctor)

import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import {
  List,
  Card,
  Row,
  Typography,
  Tag,
  Divider,
  Tabs as Tab,
  Form,
  Select,
} from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import Tabs from '../../components/Tabs'

import PageLayout from '../../layouts/DetailPageLayout'
import ImportLogCardInfoItem from './ImportLogCardInfoItem'
import {
  mapImportLogsStatusColor,
  formatDateTime,
  capitalizeEveryWord,
} from '../../../utils/helpers'
import useDispatchHttp from '../../../hooks/dispatchHttpHandler'

import {
  selectAllImportLogs,
  isFetchingImportLogs,
  importLogsCount,
  selectAllExtraImportLogsTypes,
  getImportLogsList,
  getImportLogFile,
  getWeeklyNpeesUpdatesList,
  getExtraImportLogsList,
  getExtraImportLogFile,
  getExtraImportLogsTypes,
} from '../../../state/modules/importLogs'

const tabs = [
  { name: 'Completed Cases', key: 1 },
  { name: 'New Cases', key: 0 },
  { name: 'Pending Cases', key: 2 },
  { name: 'Weekly NPPES Updates', key: 3 },
  { name: 'Extra Imports', key: 4 },
]
const caseItems = {
  0: [
    'total',
    'mapped',
    'success',
    'rejected',
    'warnings',
    'skipped',
    'mapping_ratio',
    'mapping_time',
    'started_at',
  ],
  1: [
    'total',
    'mapped',
    'success',
    'rejected',
    'warnings',
    'skipped',
    'mapping_ratio',
    'mapping_time',
    'started_at',
  ],
  2: [
    'total',
    'skipped',
    'reprocessed',
    'created',
    'success',
    'rejected',
    'warnings',
    'mapping_ratio',
    'mapping_time',
    'started_at',
  ],
}

const ImportLogs = () => {
  const dispatch = useDispatch()
  const dispatchHttp = useDispatchHttp()
  const [activeTab, setActiveTab] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedImportType, setSelectedImportType] = useState(1)

  const handleListChange = useCallback(
    (page, pageSize) => {
      setCurrentPage(page)
      if (activeTab === 3) {
        dispatchHttp(getWeeklyNpeesUpdatesList({ page, pageSize }))
        return
      }
      if (activeTab === 4) {
        dispatchHttp(
          getExtraImportLogsList({
            page,
            pageSize,
            import_type: selectedImportType,
          })
        )
        return
      }

      dispatchHttp(getImportLogsList({ page, pageSize, case_type: activeTab }))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatchHttp, activeTab]
  )

  const importLogs = useSelector(selectAllImportLogs)
  const loading = useSelector(isFetchingImportLogs)
  const count = useSelector(importLogsCount)
  const importTypes = useSelector(selectAllExtraImportLogsTypes)
  const [extraImportLogsTypesOptions, setExtraImportLogsTypesOptions] = useState([])

  useEffect(() => {
    let typesOptions = []

    typesOptions = importTypes.map(item => ({
      label: capitalizeEveryWord(item.name),
      value: item.id,
    }))

    setExtraImportLogsTypesOptions(typesOptions)
  }, [importTypes])

  const handleSelect = value => {
    setSelectedImportType(value)
  }

  useEffect(() => {
    if (activeTab === 3) {
      dispatchHttp(getWeeklyNpeesUpdatesList())
      return
    }

    if (activeTab === 4) {
      dispatchHttp(getExtraImportLogsTypes())
      dispatchHttp(getExtraImportLogsList({ import_type: selectedImportType }))
      return
    }
    dispatchHttp(getImportLogsList({ case_type: activeTab }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchHttp, activeTab])

  const renderWeeklyNpeesUpdatesItem = useCallback(item => {
    const fileNameParts = item?.file_url?.split('/') || []
    const fileName = fileNameParts[fileNameParts.length - 1]
    return (
      <List.Item key={item.id}>
        <Card style={{ width: '100%' }}>
          <Row align="middle">
            <div className="import_log_card__title">
              <span className="import_log_card__title_id">#{item.id}</span>{' '}
              {formatDateTime(item.created_at)}
            </div>
            <Tag color={mapImportLogsStatusColor(item.status)}>{item.status}</Tag>
          </Row>
          <Row align="middle">
            <Link
              to={`/data-sync-log-weekly-npees-updates/${item.id}`}
              className="import_log_card__file_name"
            >
              {fileName || 'No file'}
            </Link>
          </Row>
          <Row align="middle">
            <Typography.Link strong href={item?.file_url}>
              Download <DownloadOutlined />
            </Typography.Link>
            <Divider type="vertical" />
            <ImportLogCardInfoItem
              title="Total Doctors Imported:"
              value={item.total_doctors_imported}
            />
            <ImportLogCardInfoItem
              title="Total Facilities Imported:"
              value={item.total_facilities_imported}
            />
          </Row>
        </Card>
      </List.Item>
    )
  }, [])

  const getDurationData = item => {
    const finishedAt = item.finished_at
    let minutesSeconds = ''
    let hours = ''

    if (finishedAt) {
      const start = moment(item.started_at)
      const end = moment(finishedAt)
      const diff = end.diff(start)
      const diffMilliseconds = moment.duration(diff).asMilliseconds()
      minutesSeconds = moment.utc(diffMilliseconds).format('mm:ss')
      hours = Math.floor(moment.duration(diffMilliseconds).asHours())
    }

    return {
      finishedAt,
      minutesSeconds,
      hours,
    }
  }

  const renderExtraImportLogsItem = useCallback(
    item => {
      const {
        data_file,
        finished_at,
        started_at,
        id,
        import_type,
        status,
        duration,
        ...rest
      } = item

      return (
        <List.Item key={item.id}>
          <Card style={{ width: '100%' }}>
            <Row align="middle">
              <div className="import_log_card__title">
                <span className="import_log_card__title_id">#{id}</span>{' '}
                {formatDateTime(started_at)}
              </div>
              <Tag color={mapImportLogsStatusColor(status)}>{status}</Tag>
            </Row>
            <Row align="middle">
              <span className="import_log_card__file_name">
                {' '}
                {data_file?.file_name || 'No file'}
              </span>
            </Row>
            <Row align="middle">
              <Typography.Link
                strong
                onClick={() => {
                  dispatch(getExtraImportLogFile(id, data_file?.file_name))
                }}
              >
                Download <DownloadOutlined />
              </Typography.Link>
              <Divider type="vertical" />

              {Object.keys(rest).map(name => {
                const data = rest[name]
                if (typeof rest[name] === 'object') return null
                const title = name?.replace(/_/g, ' ')

                return (
                  <ImportLogCardInfoItem
                    key={`${id}-${name}`}
                    title={`${capitalizeEveryWord(title)}:`}
                    value={data}
                  />
                )
              })}
              <ImportLogCardInfoItem title="Duration:" value={duration || '-'} />
            </Row>
          </Card>
        </List.Item>
      )
    },
    [dispatch]
  )

  const getCaseElement = useCallback((item, type) => {
    const ratio = `${item.cmr}%`
    const { finishedAt, minutesSeconds, hours } = getDurationData(item)
    let averageTimeMapping = '-'
    if (item.avg_case_mapping_time) {
      averageTimeMapping = `${parseFloat(item.avg_case_mapping_time).toFixed(3)}s`
    }

    const mapping = {
      total: (
        <ImportLogCardInfoItem title="Total Cases:" value={item.total_cases_in_file} />
      ),
      mapped: <ImportLogCardInfoItem title="Mapped:" value={item.total_cases_mapped} />,
      success: (
        <ImportLogCardInfoItem
          title="Success:"
          value={item.total_cases_success}
          type={ImportLogCardInfoItem.STATUSES.POSITIVE}
        />
      ),
      rejected: (
        <ImportLogCardInfoItem
          title="Rejected:"
          value={item.total_cases_rejected}
          type={ImportLogCardInfoItem.STATUSES.NEGATIVE}
        />
      ),
      warnings: (
        <ImportLogCardInfoItem
          title="Warnings:"
          value={item.total_exceptions}
          type={ImportLogCardInfoItem.STATUSES.NEGATIVE}
        />
      ),
      reprocessed: (
        <ImportLogCardInfoItem
          title="Reprocessed:"
          value={item.total_cases_reprocessed}
        />
      ),
      created: (
        <ImportLogCardInfoItem title="Created:" value={item.total_cases_created} />
      ),
      skipped: (
        <ImportLogCardInfoItem title="Skipped:" value={item.total_cases_skipped} />
      ),
      mapping_ratio: <ImportLogCardInfoItem title="Case Mapping Ratio:" value={ratio} />,
      mapping_time: (
        <ImportLogCardInfoItem
          title="Average Case Mapping Time:"
          value={averageTimeMapping}
        />
      ),
      started_at: (
        <ImportLogCardInfoItem
          title="Duration:"
          value={finishedAt ? `${hours}:${minutesSeconds}` : '-'}
        />
      ),
    }

    return mapping[type]
  }, [])

  const renderItem = useCallback(
    item => {
      if (activeTab === 3) {
        return renderWeeklyNpeesUpdatesItem(item)
      }
      if (activeTab === 4) {
        return renderExtraImportLogsItem(item)
      }

      return (
        <List.Item key={item.id}>
          <Card style={{ width: '100%' }}>
            <Row align="middle">
              <div className="import_log_card__title">
                <span className="import_log_card__title_id">#{item.id}</span>{' '}
                {formatDateTime(item.started_at)}
              </div>
              <Tag color={mapImportLogsStatusColor(item.status)}>{item.status}</Tag>
            </Row>
            <Row align="middle">
              <Link
                to={`/data-sync-log/${item.id}`}
                className="import_log_card__file_name"
              >
                {item?.cms_file?.file_name}
              </Link>
            </Row>
            <Row align="middle">
              <Typography.Link
                strong
                onClick={() => {
                  dispatch(getImportLogFile(item.id, item?.cms_file?.file_name))
                }}
              >
                Download <DownloadOutlined />
              </Typography.Link>
              <Divider type="vertical" />
              {caseItems[activeTab].map(type => getCaseElement(item, type))}
            </Row>
          </Card>
        </List.Item>
      )
    },
    [
      dispatch,
      activeTab,
      renderWeeklyNpeesUpdatesItem,
      renderExtraImportLogsItem,
      getCaseElement,
    ]
  )

  useEffect(() => {
    if (activeTab === 4) {
      dispatchHttp(
        getExtraImportLogsList({
          import_type: selectedImportType,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedImportType])

  return (
    <PageLayout title="CMS Imports Logs">
      <Tabs
        fullWidth
        theme="dark"
        tabsProps={{
          size: 'small',
          tabBarGutter: 24,
          onChange: key => {
            setCurrentPage(1)
            setActiveTab(+key)
          },
          activeKey: `${activeTab}`,
        }}
      >
        {tabs.map(({ key, name }) => (
          <Tab.TabPane tab={name} key={key}>
            <>
              {activeTab === 4 && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: 0,
                  }}
                >
                  <Form
                    initialValues={{ import_type: selectedImportType }}
                    layout="inline"
                    size="small"
                  >
                    <Form.Item
                      label="Import Type"
                      className="sort-form__select-field"
                      name="import_type"
                      style={{ alignItems: 'center' }}
                    >
                      <Select
                        size="middle"
                        className="sort-form__select"
                        onChange={handleSelect}
                        options={extraImportLogsTypesOptions}
                        placeholder="Select here"
                        loading={loading}
                        style={{ width: 160 }}
                      />
                    </Form.Item>
                  </Form>
                </div>
              )}
              <List
                dataSource={importLogs}
                renderItem={renderItem}
                pagination={{
                  current: currentPage,
                  onChange: handleListChange,
                  total: count,
                  hideOnSinglePage: importLogs.length === 0,
                }}
                loading={loading}
              />
            </>
          </Tab.TabPane>
        ))}
      </Tabs>
    </PageLayout>
  )
}

export default ImportLogs

import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { downloadFileByUrl, getPagination } from '../../../utils/functions'

import ReportsService from '../../../services/ReportsService'

export const getReports = createAsyncThunk('reports/getReports', async data => {
  const response = await getPagination(
    data,
    ReportsService.getReportsList.bind(ReportsService)
  )

  return response
})

export const generateCaseAssignmentReport = createAsyncThunk(
  'reports/generateCaseAssignmentReport',
  data => {
    const response = ReportsService.generateCaseAssignmentReport(data)

    return response
  }
)

export const generateFeedbackLoopReport = createAsyncThunk(
  'reports/generateFeedbackLoopReport',
  data => {
    const response = ReportsService.generateFeedbackLoopReport(data)

    return response
  }
)

export const downloadReport = createAsyncThunk(
  `reports/downloadReport`,
  async (id, file_name) => {
    const response = await ReportsService.getReportFileUrl(id)

    downloadFileByUrl(response.link, file_name)
  }
)

export const deleteReport = createAsyncThunk(`reports/deleteReport`, async id => {
  const response = await ReportsService.deleteReport(id)

  return response
})

export const resetState = createAction('reports/resetState')

import { object, string, ref } from 'yup'

import { email, password } from './fields'

export const signInSchema = object().shape({
  email,
  password: string().required('Please enter a password'),
})

export const forgotPasswordSchema = object().shape({
  email,
})

export const confirmCodeSchema = object().shape({
  code: string().required('Please enter confirmation code'),
})

export const resetPasswordSchema = object().shape({
  password,
  password_confirmation: string()
    .required('Please repeat a password')
    .oneOf([ref('password'), null], "The confirm password and password don't match"),
})

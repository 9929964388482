import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row, Spin, Typography } from 'antd'

import PageLayout from '../../layouts/DetailPageLayout'
import Table from '../../components/Tables/SearchTable'
import EditCell from '../../components/Tables/EditCell'
import { cmsSupervisorID } from '../../../schema/fields'

import {
  getSettings,
  selectAllSettings,
  updateSetting,
  resetMassDispatchState,
} from '../../../state/modules/massDispatch'
import useDispatchHttp from '../../../hooks/dispatchHttpHandler'

const MassDispatchSettings = () => {
  const dispatchHttp = useDispatchHttp()
  const dispatch = useDispatch()
  const settingsList = useSelector(selectAllSettings)
  const { count } = useSelector(state => state.massDispatch)
  const loadingStatus = useSelector(state => state.massDispatch.status)
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 15,
  })

  const handleChangeValue = async ({ value, name, settingsId }) => {
    await dispatchHttp(
      updateSetting({
        id: settingsId,
        data: { [name]: value },
      }),
      'Mass Dispatch Setting updated successfully'
    )
    return dispatchHttp(
      getSettings({
        ...pagination,
      })
    )
  }

  const tableColumns = [
    {
      title: 'Title',
      key: 'title',
    },
    {
      title: 'Priority',
      key: 'index',
      align: 'center',
      width: 50,
    },
    {
      title: 'CMS Supervisor ID',
      key: 'cms_supervisor_id',
      align: 'center',
      width: 180,
      // eslint-disable-next-line react/display-name
      render: (text, col, settings) => {
        // TODO temporary disable editable cms_supervisor_id
        const temporaryDisabled = true
        if (temporaryDisabled) {
          return text
        }

        return (
          <div>
            <EditCell
              type="input"
              disabled
              validationSchema={cmsSupervisorID}
              value={text}
              onSubmit={data =>
                handleChangeValue({
                  value: data || null,
                  name: 'cms_supervisor_id',
                  settingsId: settings.id,
                })
              }
            />
          </div>
        )
      },
    },
    {
      title: 'Active',
      key: 'is_active',
      align: 'center',
      width: 80,
      render: text => (text ? 'Yes' : 'No'),
    },
    {
      title: 'DS Enabled',
      key: 'is_active_dump_screen',
      align: 'center',
      width: 90,
      render: text => (text ? 'Yes' : 'No'),
    },
  ]

  useEffect(() => {
    setPagination({ page: 1, pageSize: 15 })
  }, [count])

  useEffect(() => {
    async function fetchData() {
      dispatchHttp(getSettings({ ...pagination }))
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchHttp])

  useEffect(
    () => () => {
      dispatch(resetMassDispatchState())
    },
    [dispatch]
  )

  return (
    <Spin spinning={false}>
      <PageLayout>
        <>
          <Row
            gutter={[16, 32]}
            style={{
              background: '#fff',
              padding: '10px 12px',
              alignItems: 'center',
              justifyContent: 'space-between',
              margin: 0,
            }}
          >
            <Col>
              <Typography.Title level={4} style={{ marginBottom: 0 }}>
                Mass Dispatch Settings <span className="title-badge">{count}</span>
              </Typography.Title>
            </Col>
          </Row>
          <Table
            className="table-with-editable-cells"
            columns={tableColumns}
            onLoadData={getSettings}
            totalCount={count}
            totalData={settingsList}
            loadingStatus={loadingStatus}
            onPageChange={setPagination}
            rowKey="id"
          />
        </>
      </PageLayout>
    </Spin>
  )
}

export default MassDispatchSettings

import React from 'react'
import { Form, Row, Col, Typography } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { capitalizeEveryWord } from '../../../utils/helpers'

const labelsMapping = {
  is_superuser: 'Is Super User',
  lastname: 'Last Name',
  firstname: 'First Name',
}
const formatLabel = (label = '') => {
  if (labelsMapping[label]) {
    return labelsMapping[label]
  }
  return capitalizeEveryWord(label.split('_').join(' '))
}

const formatValueByType = (value, type) => {
  if (type === 'retriever') {
    return value.fullname && value.id ? `${value?.fullname} #${value?.id}` : ''
  }

  return `${value}`
}

const mapValue = (value, type) => {
  if (Array.isArray(value)) {
    return value.map(item => formatValueByType(item, type)).join(', ')
  }
  return formatValueByType(value, type)
}

const formatUpdateObjectData = (data, type) =>
  data.map((item, index) => (
    <Typography.Paragraph type={type} key={`activity-log-item-${type}-${index + 1}`}>
      <pre>
        {Object.entries(item).map(([itemKey, itemValue]) => {
          if (itemKey === 'address_hash') {
            return null
          }
          return `${formatLabel(itemKey)}: ${itemValue} \n`
        })}
      </pre>
    </Typography.Paragraph>
  ))

const formatUpdateArrayData = (data, type) =>
  data.map((item, index) => (
    <Typography.Paragraph type={type} key={`activity-log-item-${type}-${index + 1}`}>
      <pre>{item}</pre>
    </Typography.Paragraph>
  ))

const ActivityLogDataItem = ({ fieldKey, fieldValue, number, action }) => {
  if (fieldValue.type === 'object') {
    return (
      <Form.Item label={formatLabel(fieldKey)} key={`row-${number}`}>
        {fieldValue.added.length > 0 && (
          <Col flex={1}>
            <Form.Item
              label={<Typography.Text type="secondary">Added</Typography.Text>}
              className="activity-log-card__field"
            >
              {/* exception for retrievers timezone  */}
              {fieldKey === 'timezone'
                ? formatUpdateArrayData(fieldValue.added, 'success')
                : formatUpdateObjectData(fieldValue.added, 'success')}
            </Form.Item>
          </Col>
        )}
        {fieldValue.removed.length > 0 && (
          <Col flex={1}>
            <Form.Item
              label={<Typography.Text type="secondary">Removed</Typography.Text>}
              className="activity-log-card__field"
            >
              {/* exception for retrievers timezone  */}
              {fieldKey === 'timezone'
                ? formatUpdateArrayData(fieldValue.removed, 'danger')
                : formatUpdateObjectData(fieldValue.removed, 'danger')}
            </Form.Item>
          </Col>
        )}
      </Form.Item>
    )
  }

  const span = action !== 'Create' ? 11 : 24
  return (
    <Form.Item key={`row-${number}`} noStyle>
      <Row align="middle" gutter={16}>
        <Col span={span}>
          <Form.Item className="activity-log-card__field" label={formatLabel(fieldKey)}>
            <div
              style={{
                backgroundColor: '#f5f5f5',
                color: '#0050b3',
                fontSize: '16px',
                border: '1px solid #d9d9d9',
                padding: '4px 11px',
              }}
            >
              {mapValue(fieldValue.new, fieldValue.type)}
            </div>
          </Form.Item>
        </Col>
        {action !== 'Create' && (
          <>
            <Col span={2} style={{ textAlign: 'center' }}>
              <ArrowLeftOutlined style={{ color: '#1890ff' }} />
            </Col>
            <Col span={11}>
              <Form.Item
                className="activity-log-card__field"
                label={
                  <Row justify="space-between">
                    <Col>{formatLabel(fieldKey)}</Col>
                    <Col>
                      <Typography.Text type="secondary">Old</Typography.Text>
                    </Col>
                  </Row>
                }
              >
                <div
                  style={{
                    minHeight: '34px',
                    backgroundColor: '#f5f5f5',
                    color: '#0050b3',
                    fontSize: '16px',
                    border: '1px solid #d9d9d9',
                    padding: '4px 11px',
                  }}
                >
                  {mapValue(fieldValue.old, fieldValue.type)}
                </div>
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
    </Form.Item>
  )
}

export default ActivityLogDataItem

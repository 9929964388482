import Api from './index'
import { API_URL } from '../constants'

class Facility extends Api {
  getFacilityById(id) {
    return this.get(`facilities/${id}/`)
  }

  updateFacility(id, data) {
    return this.put(`facilities/${id}/`, { data })
  }

  createFacility(data) {
    return this.post('facilities/', { data })
  }

  deleteFacility(id) {
    return this.del(`facilities/${id}/`)
  }

  getRelatedFacilities(id, params) {
    return this.get(`facilities/${id}/related/`, { params })
  }

  getRelatedDoctors(id, params) {
    return this.get(`facilities/${id}/doctors/`, { params })
  }

  getFacilityProfiles(id, params) {
    return this.get('facility_profiles/', { params })
  }

  createFacilityProfile(data) {
    return this.post('facility_profiles/', { data })
  }

  updateFacilityProfile(id, data) {
    return this.put(`facility_profiles/${id}/`, { data })
  }

  getFacilityProfileById(id) {
    return this.get(`facility_profiles/${id}/`)
  }

  unlinkFacilityProfile(id) {
    return this.del(`facilities/${id}/unlink-facility-profile/`)
  }

  setFacilityProfileRetriever(id, data) {
    return this.post(`facility_profiles/${id}/set_main_retriever/`, {
      data,
    })
  }

  setFacilityProfileMainRetrieverAutoDetection(id, data) {
    return this.post(
      `facility_profiles/${id}/set_facility_profile_main_retriever_auto_detection/`,
      { data }
    )
  }

  setFacilityProfileCopyCompany(id, data) {
    return this.post(`facility_profiles/${id}/set_copy_company/`, { data })
  }

  setFacilityProfileCopyCompanyAutoDetection(id, data) {
    return this.post(
      `facility_profiles/${id}/set_facility_profile_copy_company_auto_detection/`,
      {
        data,
      }
    )
  }

  createAuthForm(data) {
    const formData = new FormData()

    Object.keys(data).forEach(key => {
      formData.append(key, data[key])
    })

    return this.post('auth_forms/', { data: formData })
  }

  deleteAuthForm(id) {
    return this.del(`auth_forms/${id}/`)
  }

  getFormById(id) {
    return this.get(`auth_forms/${id}/retrieve_link/`)
  }

  addFacilityToCluster(id, data) {
    return this.put(`facilities/${id}/add-to-cluster/`, {
      data,
      skipNotFoundRedirect: true,
    })
  }

  removeFromCluster(id) {
    return this.del(`facilities/${id}/unlink-from-cluster/`)
  }

  removeFromFacilityProfile(id, data) {
    return this.put(`facility_profiles/${id}/unlink_facility/`, { data })
  }

  linkDoctor(id, data) {
    return this.post(`facilities/${id}/link-doctor/`, {
      data,
      skipNotFoundRedirect: true,
    })
  }

  unlinkDoctor(id, data) {
    return this.del(`facilities/${id}/unlink-doctor/`, { data })
  }

  setMRDFacility(id, data) {
    return this.post(`facility_profiles/${id}/link_mrd/`, { data })
  }

  addFacilityToFacilityProfile(id, data) {
    return this.put(`facility_profiles/${id}/link_facility/`, {
      data,
      skipNotFoundRedirect: true,
    })
  }

  getRelatedFacilityProfiles(id, params) {
    return this.get(`facility_profiles/${id}/facilities/`, {
      params,
    })
  }

  getFacilityProfileESignStat(params) {
    return this.get(`/special_authorization/e_sign_stat/`, {
      params,
    })
  }

  getSpecialAuth(id, params) {
    return this.get(`/special_authorization/`, {
      params,
    })
  }

  setFacilityProfileCommunicationMethodAutoDetection(id, data) {
    return this.post(
      `/facility_profiles/${id}/set_facility_profile_communication_method_auto_detection/`,
      { data }
    )
  }

  setFacilityProfileCommunicationMethod(id, data) {
    return this.post(`/facility_profiles/${id}/set_communication_method/`, {
      data,
    })
  }
}

export default new Facility({
  baseURL: API_URL,
})

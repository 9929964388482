import React from 'react'
import { Row, Col, Typography, Space, Tag, Button, Collapse } from 'antd'
import { LinkOutlined, BookOutlined } from '@ant-design/icons'
import ProfileCard from '../ProfileCard'
import RelateFacilityModal from '../Modals/RelateDoctorFacilityModal'
import { FACILITY } from '../../../constants'
import { facilityMRDColumns } from '../../../data'
import { getRelatedFacilityProfileFacilities } from '../../../state/modules/facilities'
import Table from '../Tables/SearchTable'

const FacilityProfileMRD = ({
  handleSetMRDFacility,
  unlinkFacilityFromFacilityProfile,
  relateFacilityProfile,
  treatment_facilities,
  mrd_facility,
  relatedFacilityProfilesCount,
  id,
  isUserActionAllowed,
}) => (
  <>
    {' '}
    {mrd_facility && (
      <Col span={24}>
        <ProfileCard
          title={
            <Space size={16}>
              Provider
              <Tag color="processing" icon={<BookOutlined />}>
                MRD
              </Tag>
            </Space>
          }
          style={{
            headerColor: '#E6F7FF',
            bodyColor: '#003A8C',
          }}
        >
          <Table
            columns={facilityMRDColumns}
            totalData={[mrd_facility]}
            entity={FACILITY}
          />
        </ProfileCard>
        <Collapse>
          <Collapse.Panel
            header={
              <>
                Facilities{' '}
                {!!relatedFacilityProfilesCount && relatedFacilityProfilesCount}
              </>
            }
            key="1"
          >
            <Table
              tableTitle={
                <Row justify="space-between" align="middle">
                  <Col />
                  <Col>
                    <RelateFacilityModal
                      title="Relate Facility"
                      entity="Facility"
                      onSubmit={relateFacilityProfile}
                      closeAfterSubmit
                      trigger={
                        isUserActionAllowed('manage_relations_facilityprofile') ? (
                          <Button type="text" size="large">
                            <Typography.Text type="secondary">
                              <Space>
                                <LinkOutlined /> Relate Facility
                              </Space>
                            </Typography.Text>
                          </Button>
                        ) : (
                          <></>
                        )
                      }
                    />
                  </Col>
                </Row>
              }
              columns={facilityMRDColumns}
              totalData={treatment_facilities}
              onLoadData={getRelatedFacilityProfileFacilities}
              totalCount={relatedFacilityProfilesCount}
              formData={+id}
              entity={FACILITY}
              hideTotalCount
              customRowSelection={{
                columnTitle: 'mrd',
                key: 'is_mrd',
                onSelect: handleSetMRDFacility,
                disabled: isUserActionAllowed('update_mrd_facilityprofile'),
              }}
              rowDeletion={
                isUserActionAllowed('manage_relations_facilityprofile') && {
                  tooltip: 'Remove Relation',
                  confirmMsg: 'Are you sure you want to remove the relation?',
                  okText: 'Yes',
                  onConfirm: unlinkFacilityFromFacilityProfile,
                  hideRule: item => item.is_mrd,
                }
              }
            />
          </Collapse.Panel>
        </Collapse>
      </Col>
    )}
  </>
)

export default FacilityProfileMRD

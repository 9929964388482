export function getFacilityFeedbackLoopData(facilityData, feedbackLoopData) {
  const info = { ...facilityData }
  const original = {
    addresses: [],
    phone_numbers: [],
    fax_numbers: [],
    emails: [],
    name: '',
  }
  const checkOriginal = (newData, newDataField, oldData, oldDataField, obj) => {
    if (newData[newDataField]?.toLowerCase() !== oldData[oldDataField]?.toLowerCase()) {
      obj[oldDataField] = oldData[oldDataField]
    }
  }
  const newData = { ...(feedbackLoopData?.primary_search || {}) }
  let addresses = [...(facilityData?.addresses || [])]
  let phones = [...(facilityData?.phone_numbers || [])]
  let faxes = [...(facilityData?.fax_numbers || [])]
  let emails = [...(facilityData?.emails || [])]
  addresses = addresses.sort(a => (a.type === 'LOC' ? -1 : 1))
  phones = phones.sort(a => (a.type === 'LOC' ? -1 : 1))
  faxes = faxes.sort(a => (a.type === 'LOC' ? -1 : 1))
  emails = emails.sort(a => (a.type === 'LOC' ? -1 : 1))

  if (newData.address_line_1) {
    if (!addresses.length) {
      addresses.push({})
    }
    if (addresses[0].type === 'LOC') {
      if (!original.addresses.length) {
        original.addresses.push({})
      }

      checkOriginal(
        newData,
        'address_line_1',
        addresses[0],
        'address_line_1',
        original.addresses[0]
      )
    }
    addresses[0] = {
      ...addresses[0],
      address_line_1: newData.address_line_1,
    }
  }

  if (newData.address_line_2) {
    if (!addresses.length) {
      addresses.push({})
    }
    if (addresses[0].type === 'LOC') {
      if (!original.addresses.length) {
        original.addresses.push({})
      }
      checkOriginal(
        newData,
        'address_line_2',
        addresses[0],
        'address_line_2',
        original.addresses[0]
      )
    }
    addresses[0] = {
      ...addresses[0],
      address_line_2: newData.address_line_2,
    }
  }

  if (newData.city) {
    if (!addresses.length) {
      addresses.push({})
    }
    if (addresses[0].type === 'LOC') {
      if (!original.addresses.length) {
        original.addresses.push({})
      }
      checkOriginal(newData, 'city', addresses[0], 'city', original.addresses[0])
    }
    addresses[0] = { ...addresses[0], city: newData.city }
  }

  if (newData.state) {
    if (!addresses.length) {
      addresses.push({})
    }
    if (addresses[0].type === 'LOC') {
      if (!original.addresses.length) {
        original.addresses.push({})
      }
      checkOriginal(newData, 'state', addresses[0], 'state', original.addresses[0])
    }
    addresses[0] = { ...addresses[0], state: newData.state }
  }

  if (newData.zip_code) {
    if (!addresses.length) {
      addresses.push({})
    }
    if (addresses[0].type === 'LOC') {
      if (!original.addresses.length) {
        original.addresses.push({})
      }
      checkOriginal(newData, 'zip_code', addresses[0], 'zip_code', original.addresses[0])
    }
    addresses[0] = { ...addresses[0], zip_code: newData.zip_code }
  }

  if (newData.fax) {
    if (!faxes.length) {
      faxes.push({})
    }
    if (faxes[0].type === 'LOC') {
      if (!original.fax_numbers.length) {
        original.fax_numbers.push({})
      }
      checkOriginal(newData, 'fax', faxes[0], 'number', original.fax_numbers[0])
    }
    faxes[0] = { ...faxes[0], number: newData.fax, type: 'LOC' }
  }

  if (newData.phone) {
    if (!phones.length) {
      phones.push({})
    }
    if (phones[0].type === 'LOC') {
      if (!original.phone_numbers.length) {
        original.phone_numbers.push({})
      }
      checkOriginal(newData, 'phone', phones[0], 'number', original.phone_numbers[0])
    }
    phones[0] = { ...phones[0], number: newData.phone, type: 'LOC' }
  }

  if (newData.last_name) {
    checkOriginal(newData, 'last_name', info, 'name', original)
    info.name = newData.last_name
  }

  info.addresses = addresses
  info.phone_numbers = phones
  info.fax_numbers = faxes
  info.emails = emails

  return {
    originalValues: original,
    editValues: info,
  }
}

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Row, Col } from 'antd'

import MainLayout from '../../layouts/MainLayout'
import UserForm from '../../components/Forms/UserForm'
import { selectUserById, fetchUserById, updateUser } from '../../../state/modules/users'
import { updateUserSchema } from '../../../schema/updateUser'
import useDispatchHttp from '../../../hooks/dispatchHttpHandler'
import { routePaths } from '../../../utils/routes'
import { fetchAllRoles, selectAllRoles } from '../../../state/modules/roles'
import { useUser } from '../../../providers/UserProvider'

const UserDetails = ({ breadcrumbs }) => {
  const history = useHistory()
  const { id } = useParams()
  const dispatchHttp = useDispatchHttp()
  const user = useSelector(state => selectUserById(state, id))
  const { status } = useSelector(state => state.users)
  const roles = useSelector(selectAllRoles)
  const roleOptions = roles
    .concat(user?.groups || [])
    .filter((role, index, arr) => index === arr.findIndex(t => t.id === role.id))
    .map(role => ({
      value: role.id,
      label: role.name,
    }))

  const { isUserActionAllowed } = useUser()

  useEffect(() => {
    if (isUserActionAllowed('view_user')) {
      dispatchHttp(fetchUserById(id))
    }
    // eslint-disable-next-line
  }, [dispatchHttp, id])

  const handleSubmit = async ({ password, role, ...formData }) => {
    try {
      let data = { ...formData, groups: [role].filter(Boolean) }

      if (password && password.length) {
        data = {
          ...data,
          password,
        }
      }
      await dispatchHttp(updateUser({ id, data }), 'User has been successfully updated')
      history.push(routePaths.openUsersList())
    } catch (err) {
      /* empty */
    }
  }
  useEffect(() => {
    if (isUserActionAllowed('view_group')) {
      dispatchHttp(fetchAllRoles({ skipPagination: true }))
    }
    // eslint-disable-next-line
  }, [dispatchHttp])

  const role = roleOptions.find(item => item?.value === user?.groups[0]?.id)

  return (
    <MainLayout withSubHeader subHeaderProps={{ breadcrumb: breadcrumbs }}>
      <Row justify="center">
        <Col>
          <UserForm
            submitButtonText="Save Changes"
            initialValues={user}
            loading={status === 'loading' && status !== 'success'}
            onSubmit={handleSubmit}
            validationSchema={updateUserSchema}
            formType="update"
            roleOptions={roleOptions}
            userRole={role?.value}
            isSubmitAllowed={isUserActionAllowed('change_user')}
          />
        </Col>
      </Row>
    </MainLayout>
  )
}

UserDetails.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    })
  ).isRequired,
}

export default UserDetails

import { importLogsAdapter } from './reducer'
import { LOADING_STATUS } from '../../../constants'

export const isFetchingImportLogs = state => state.importLogs.status === LOADING_STATUS

export const importLogsCount = state => state.importLogs.count

export const { selectAll: selectAllImportLogs, selectById: selectImportLogById } =
  importLogsAdapter.getSelectors(state => state.importLogs)

export const { selectAll: selectAllExtraImportLogsTypes } =
  importLogsAdapter.getSelectors(state => state.importLogs.types)

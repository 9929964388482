import React, { useEffect, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { List } from 'antd'

import PageLayout from '../../../layouts/DetailPageLayout'
import RetrieverListItem from '../../../components/RetrieverListItem'

import { selectAllRetrievers, getRetrievers } from '../../../../state/modules/retrievers'
import useDispatchHttp from '../../../../hooks/dispatchHttpHandler'
import { LOADING_STATUS } from '../../../../constants'
import {
  fetchFacilityProfileById,
  selectFacilityProfileById,
} from '../../../../state/modules/facilities'

const renderItem = item => <RetrieverListItem data={item} />

const RetrieverList = () => {
  const { id } = useParams()

  const dispatchHttp = useDispatchHttp()
  const retrieversList = useSelector(selectAllRetrievers)
  const { count } = useSelector(state => state.retrievers)
  const loadingStatus = useSelector(state => state.retrievers.status)
  const facilityData = useSelector(state => selectFacilityProfileById(state, id))

  const breadcrumbs = useMemo(
    () => [
      {
        name: `${facilityData?.provider_name || 'Loading'}`,
        path: `/facility-profiles/${id}/`,
      },
      {
        name: `Past Retrievers`,
        path: `/facility-profiles/${id}/retrievers/`,
      },
    ],
    [facilityData?.provider_name, id]
  )

  useEffect(() => {
    dispatchHttp(
      getRetrievers({
        facility_profile: id,
      })
    )
  }, [dispatchHttp, id])

  const handleListChange = useCallback(
    (page, pageSize) => {
      dispatchHttp(getRetrievers({ page, pageSize, facility_profile: id }))
    },
    [dispatchHttp, id]
  )

  useEffect(() => {
    dispatchHttp(fetchFacilityProfileById(id))
  }, [dispatchHttp, id])

  return (
    <PageLayout
      breadcrumbs={breadcrumbs}
      title={
        <>
          Past Retrievers
          <span className="badge">{count}</span>
        </>
      }
    >
      <List
        grid={{ gutter: 24, column: 2 }}
        dataSource={retrieversList}
        renderItem={renderItem}
        pagination={{
          hideOnSinglePage: retrieversList.length === 0,
          total: count,
          onChange: handleListChange,
        }}
        loading={loadingStatus === LOADING_STATUS}
      />
    </PageLayout>
  )
}

export default RetrieverList

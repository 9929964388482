import React, { useState, useMemo, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Typography, Space, Button, Tag } from 'antd'

import { LinkOutlined } from '@ant-design/icons'
import DetailPageLayout from '../../../layouts/DetailPageLayout'
import {
  DEFAULT_MERGERS_PAGE_SIZE_OPTIONS,
  FACILITY,
  DOCTOR,
} from '../../../../constants'

import useDispatchHttp from '../../../../hooks/dispatchHttpHandler'
import {
  selectMergerById,
  selectAllDoctorRelatedMergers,
  selectAllFacilityRelatedMergers,
  fetchMergerById,
  getDoctorMergerProviders,
  getFacilityMergerProviders,
  relateMergerProvider,
  removeMergerProviderRelation,
  runMerger,
  deleteMerger,
  resetState as resetMergersState,
} from '../../../../state/modules/mergers'

import RelateFacilityModal from '../../../components/Modals/RelateDoctorFacilityModal/RelateDoctorFacilityModal'
import { doctorTableColumns, facilityTableColumns } from '../../../../data'
import Table from '../../../components/Tables/SearchTable/SearchTable.container'
import ProfileCard from '../../../components/ProfileCard'
import FacilityProfileCard from '../../../components/FacilityProfileCard/FacilityProfileCard'
import {
  fetchFacilityProfileById,
  selectFacilityProfileById,
} from '../../../../state/modules/facilities'
import { useUser } from '../../../../providers/UserProvider'
import showConfirmDialog from '../../../components/Modals/DeleteConfirm'

const statusMapping = {
  1: {
    name: 'New',
    color: 'blue',
  },
  2: {
    name: 'In Progress',
    color: 'yellow',
  },
  3: {
    name: 'Completed',
    color: 'green',
  },
}

const Merger = () => {
  const { isUserActionAllowed } = useUser()
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams()
  const dispatchHttp = useDispatchHttp()
  const [isMerging, setIsMerging] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const relatedDoctors = useSelector(selectAllDoctorRelatedMergers)
  const relatedDoctorsCount = useSelector(state => state.mergers.relatedDoctors.count)
  const relatedDoctorsStatus = useSelector(state => state.mergers.relatedDoctors.status)
  const relatedFacilitiesCount = useSelector(
    state => state.mergers.relatedFacilities.count
  )
  const relatedFacilitiesStatus = useSelector(
    state => state.mergers.relatedFacilities.status
  )
  const relatedFacilities = useSelector(selectAllFacilityRelatedMergers)
  const mergerData = useSelector(state => {
    const merger = selectMergerById(state, id)
    if (!merger) return null
    return {
      ...merger?.mrd_facility,
      mrd_facility_id: merger?.mrd_facility.id,
      id: merger?.id,
      status: merger?.status,
    }
  })
  const facilityProfileData = useSelector(state =>
    selectFacilityProfileById(state, mergerData?.facility_profile)
  )

  const breadcrumbs = useMemo(
    () => [
      {
        name: 'Facility Profiles Mergers List',
        path: '/facility-profile-mergers',
      },
      {
        name: `${mergerData?.name}`,
        path: `/facility-profile-mergers/${id}/`,
      },
    ],
    [mergerData?.name, id]
  )

  useEffect(() => {
    dispatchHttp(fetchMergerById(id))
  }, [dispatchHttp, id])

  useEffect(() => {
    dispatchHttp(getDoctorMergerProviders({ id, providerType: 'doctors' }))
  }, [dispatchHttp, id])

  useEffect(() => {
    dispatchHttp(getFacilityMergerProviders({ id, providerType: 'facilities' }))
  }, [dispatchHttp, id])

  useEffect(() => {
    if (mergerData?.facility_profile && mergerData?.status === 3) {
      dispatchHttp(fetchFacilityProfileById(mergerData?.facility_profile))
    }
  }, [dispatchHttp, mergerData?.facility_profile, mergerData?.status])

  const relateMerger = async ({ id: providerId, providerType }) => {
    const providerTypeMapping = {
      doctors: 'Doctor',
      facilities: 'Facility',
    }

    const requestMapping = {
      doctors: getDoctorMergerProviders,
      facilities: getFacilityMergerProviders,
    }

    await dispatchHttp(
      relateMergerProvider({
        id,
        providerType,
        data: {
          id: providerId,
        },
      }),
      `${providerTypeMapping[providerType]} has been successfully related to Facility Profile Merger`
    )

    await dispatchHttp(requestMapping[providerType]({ id, providerType }))
  }

  const removeRelation = async ({ id: providerId, providerType }) => {
    const providerTypeMapping = {
      doctors: 'Doctor',
      facilities: 'Facility',
    }

    const requestMapping = {
      doctors: getDoctorMergerProviders,
      facilities: getFacilityMergerProviders,
    }

    await dispatchHttp(
      removeMergerProviderRelation({
        id,
        providerId,
        providerType,
      }),
      `${providerTypeMapping[providerType]} relation has been successfully removed`
    )

    await dispatchHttp(requestMapping[providerType]({ id, providerType }))
  }

  useEffect(
    () => () => {
      dispatch(resetMergersState())
    },
    [dispatch]
  )

  const onRunMerger = async () => {
    try {
      setIsMerging(true)
      await dispatchHttp(runMerger(id))
      await dispatchHttp(fetchMergerById(id))
      setIsMerging(false)
    } catch (e) {
      setIsMerging(false)
    }
  }

  const onDeleteMerger = async () => {
    showConfirmDialog({
      title: `Do you want to delete facility profile merger?`,
      data: id,
      handleConfirm: async () => {
        try {
          setIsDeleting(true)
          await dispatchHttp(deleteMerger(id))
          history.push('/facility-profile-mergers')
          setIsDeleting(false)
        } catch (e) {
          setIsDeleting(false)
        }
      },
    })
  }

  return (
    <DetailPageLayout breadcrumbs={breadcrumbs}>
      <Row>
        {mergerData && (
          <Col span={24}>
            <ProfileCard
              title={
                <Space size={16}>
                  MRD Facility
                  <Tag color={statusMapping[mergerData?.status]?.color}>
                    {statusMapping[mergerData?.status]?.name}
                  </Tag>
                </Space>
              }
              style={{
                headerColor: '#E6F7FF',
                bodyColor: '#003A8C',
                padding: 20,
              }}
            >
              <div style={{ width: '100%' }}>
                <Table
                  columns={facilityTableColumns.filter(
                    item =>
                      item.key !== 'related_doctors' && item.key !== 'facility_profile'
                  )}
                  totalData={[mergerData].map(item => ({
                    ...item,
                    id: item.mrd_facility_id,
                  }))}
                  entity={FACILITY}
                />
                <Row
                  style={{ justifyContent: 'flex-end', marginTop: '10px' }}
                  gutter={[20, 20]}
                >
                  {isUserActionAllowed('run_merger') && mergerData?.status === 1 && (
                    <Col>
                      <Button
                        loading={isMerging}
                        type="primary"
                        className="button--success"
                        onClick={onRunMerger}
                      >
                        Merge
                      </Button>
                    </Col>
                  )}

                  {isUserActionAllowed('delete_merger') && mergerData?.status === 1 && (
                    <Col>
                      <Button
                        loading={isDeleting}
                        type="primary"
                        danger
                        onClick={onDeleteMerger}
                      >
                        Delete
                      </Button>
                    </Col>
                  )}
                </Row>
              </div>
            </ProfileCard>
          </Col>
        )}
      </Row>

      {mergerData?.status === 3 && facilityProfileData && (
        <Col span={24}>
          <FacilityProfileCard data={facilityProfileData} />
        </Col>
      )}

      <Table
        tableTitle={
          <Row justify="space-between" align="middle">
            <Col>
              Related Facilities{' '}
              {relatedFacilitiesCount ? (
                <span className="search-table__title--count">
                  {' '}
                  {relatedFacilitiesCount}
                </span>
              ) : null}
            </Col>
            {isUserActionAllowed('relate_provider_merger') && (
              <Col>
                <RelateFacilityModal
                  title="Relate Facility"
                  entity="Facility"
                  onSubmit={data =>
                    relateMerger({
                      ...data,
                      providerType: 'facilities',
                    })
                  }
                  closeAfterSubmit
                  trigger={
                    mergerData?.status === 1 ? (
                      <Button type="text" size="large">
                        <Typography.Text type="secondary">
                          <Space>
                            <LinkOutlined /> Relate Facility
                          </Space>
                        </Typography.Text>
                      </Button>
                    ) : (
                      <></>
                    )
                  }
                />
              </Col>
            )}
          </Row>
        }
        columns={facilityTableColumns.filter(
          item => item.key !== 'related_doctors' && item.key !== 'facility_profile'
        )}
        totalData={relatedFacilities}
        onLoadData={data =>
          getFacilityMergerProviders({
            ...data,
            providerType: 'facilities',
            id,
          })
        }
        totalCount={relatedFacilitiesCount}
        entity={FACILITY}
        loadingStatus={relatedFacilitiesStatus}
        pageSizeOptions={DEFAULT_MERGERS_PAGE_SIZE_OPTIONS}
        hideTotalCount
        rowDeletion={
          isUserActionAllowed('unrelate_provider_merger') &&
          mergerData?.status === 1 && {
            tooltip: 'Remove Relation',
            confirmMsg: 'Are you sure you want to remove the relation?',
            okText: 'Yes',
            onConfirm: data =>
              removeRelation({
                ...data,
                providerType: 'facilities',
              }),
          }
        }
      />

      <Table
        tableTitle={
          <Row justify="space-between" align="middle">
            <Col>
              Related Doctors
              {relatedDoctorsCount ? (
                <span className="search-table__title--count"> {relatedDoctorsCount}</span>
              ) : null}
            </Col>
            {isUserActionAllowed('relate_provider_merger') && (
              <Col>
                <RelateFacilityModal
                  title="Relate Doctor"
                  entity="Doctor"
                  onSubmit={data =>
                    relateMerger({
                      ...data,
                      providerType: 'doctors',
                    })
                  }
                  closeAfterSubmit
                  trigger={
                    mergerData?.status === 1 ? (
                      <Button type="text" size="large">
                        <Typography.Text type="secondary">
                          <Space>
                            <LinkOutlined /> Relate Doctor
                          </Space>
                        </Typography.Text>
                      </Button>
                    ) : (
                      <></>
                    )
                  }
                />
              </Col>
            )}
          </Row>
        }
        columns={doctorTableColumns.filter(
          item => item.key !== 'facility_profile' && item.key !== 'related_facilities'
        )}
        totalData={relatedDoctors}
        onLoadData={data =>
          getDoctorMergerProviders({
            ...data,
            providerType: 'doctors',
            id,
          })
        }
        totalCount={relatedDoctorsCount}
        entity={DOCTOR}
        loadingStatus={relatedDoctorsStatus}
        pageSizeOptions={DEFAULT_MERGERS_PAGE_SIZE_OPTIONS}
        hideTotalCount
        rowDeletion={
          isUserActionAllowed('unrelate_provider_merger') &&
          mergerData?.status === 1 && {
            tooltip: 'Remove Relation',
            confirmMsg: 'Are you sure you want to remove the relation?',
            okText: 'Yes',
            onConfirm: data =>
              removeRelation({
                ...data,
                providerType: 'doctors',
              }),
          }
        }
      />
    </DetailPageLayout>
  )
}

export default Merger

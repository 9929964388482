import { facilityAdapter } from './reducer'

export const { selectById: selectFacilityById } = facilityAdapter.getSelectors(
  state => state.facilities
)

export const { selectAll: selectRelatedFacilities } = facilityAdapter.getSelectors(
  state => state.facilities.related_facilities
)

export const { selectAll: selectRelatedDoctors } = facilityAdapter.getSelectors(
  state => state.facilities.related_doctors
)
export const { selectAll: selectRelatedFacilityProfiles } = facilityAdapter.getSelectors(
  state => state.facilities.related_facility_profiles
)

export const {
  selectAll: selectFacilityProfiles,
  selectById: selectFacilityProfileById,
} = facilityAdapter.getSelectors(state => state.facilities.facility_profiles)

import React from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { notification } from 'antd'
import {
  getUserPermissions,
  loginUser,
  setUserDataLoaded,
} from '../../../state/modules/auth'
import FormWrapper from '../../components/Forms/AuthForms/FormWrapper'
import LoginForm from '../../components/Forms/AuthForms/LoginForm'
import { routes } from '../../../providers/routes'
import { useUser } from '../../../providers/UserProvider'
import useDispatchHttp from '../../../hooks/dispatchHttpHandler'

const Login = () => {
  const dispatch = useDispatch()
  const dispatchHttp = useDispatchHttp()
  const location = useLocation()
  const history = useHistory()
  const { setCurrentUser, isUserActionAllowed, setUserPermissions } = useUser()
  const { from } = location.state || {
    from: { pathname: '/', search: '' },
  }

  const getRedirectRoute = () => {
    let isRouteAllowed = false
    if (from?.pathname && from?.pathname !== '/forbidden') {
      const route = routes.find(item => item?.path === from?.pathname)
      isRouteAllowed = !route?.permission || isUserActionAllowed(route?.permission)
    }

    return isRouteAllowed ? `${from.pathname}${from.search}` : '/'
  }

  const handleSubmit = async values => {
    try {
      const data = { ...values }
      const otpAuthToken = localStorage.getItem('otp_auth_token')
      if (otpAuthToken) {
        data.otp_auth_token = otpAuthToken
      }
      const resp = await dispatch(loginUser(data))

      if (resp.otp_token) {
        history.push({
          pathname: '/confirm_code',
          search: `?otp_token=${resp.otp_token}`,
          state: {
            from,
          },
        })
        return
      }

      if (resp.id) {
        const permissions = await dispatchHttp(getUserPermissions())
        setCurrentUser(resp)
        setUserPermissions(permissions)
        dispatch(setUserDataLoaded(true))

        history.replace(getRedirectRoute())
      }
    } catch (error) {
      notification.error({
        message: error.message,
        duration: 2,
      })
    }
  }

  return <FormWrapper form={<LoginForm onSubmit={handleSubmit} />} />
}

export default Login

import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getPagination } from '../../../utils/functions'

import FacilityService from '../../../services/FacilityService'
import SearchService from '../../../services/SearchService'

export const fetchFacilityById = createAsyncThunk(
  'facilities/fetchFacilityById',
  async id => {
    const response = await FacilityService.getFacilityById(id)

    return response
  }
)

export const updateFacility = createAsyncThunk(
  'facilities/updateOne',
  async ({ facilityId, data }) => {
    const response = await FacilityService.updateFacility(facilityId, data)

    return { id: facilityId, changes: response }
  }
)

export const createFacility = createAsyncThunk('facilities/createFacility', data => {
  const response = FacilityService.createFacility(data)

  return response
})

export const deleteFacility = createAsyncThunk('facilities/deleteFacility', async id => {
  await FacilityService.deleteFacility(id)

  return id
})

export const getRelatedFacilities = createAsyncThunk(
  'facilities/getRelatedFacilities',
  data => getPagination(data, FacilityService.getRelatedFacilities.bind(FacilityService))
)

export const getRelatedDoctors = createAsyncThunk('facilities/getRelatedDoctors', data =>
  getPagination(data, FacilityService.getRelatedDoctors.bind(FacilityService))
)

export const searchFacilityProfiles = createAsyncThunk(
  'facilities/searchFacilityProfiles',
  async ({ data = {}, page = 1, pageSize = 15 } = {}) => {
    const response = await SearchService.searchFacilityProfiles({
      data: {
        page,
        results_per_page: pageSize,
        ...data,
      },
    })

    return response
  }
)

export const createFacilityProfile = createAsyncThunk(
  'facilities/createFacilityProfile',
  async data => {
    const response = await FacilityService.createFacilityProfile(data)

    return response
  }
)

export const updateFacilityProfile = createAsyncThunk(
  'facilities/updateFacilityProfile',
  async ({ facilityId, data }) => {
    const response = await FacilityService.updateFacilityProfile(facilityId, data)

    return { id: facilityId, changes: response }
  }
)

export const fetchFacilityProfileById = createAsyncThunk(
  'facilities/fetchFacilityProfileById',
  async id => {
    const response = await FacilityService.getFacilityProfileById(id)

    return response
  }
)

export const unlinkFacilityProfile = createAsyncThunk(
  'facilities/unlinkFacilityProfile',
  async ({ id }) => {
    await FacilityService.unlinkFacilityProfile(id)

    return id
  }
)

export const setFacilityProfileRetriever = createAsyncThunk(
  'facilities/setFacilityProfileRetriever',
  async ({ facilityId, data }) => {
    const response = await FacilityService.setFacilityProfileRetriever(facilityId, data)

    return response
  }
)

export const setFacilityProfileCopyCompany = createAsyncThunk(
  'facilities/setFacilityProfileCopyCompany',
  async ({ facilityId, data }) => {
    const response = await FacilityService.setFacilityProfileCopyCompany(facilityId, data)

    return response
  }
)

export const setFacilityProfileCopyCompanyAutoDetection = createAsyncThunk(
  'facilities/setFacilityProfileCopyCompanyAutoDetection',
  async ({ facilityId, data }) => {
    const response = await FacilityService.setFacilityProfileCopyCompanyAutoDetection(
      facilityId,
      data
    )

    return response
  }
)

export const setFacilityProfileMainRetrieverAutoDetection = createAsyncThunk(
  'facilities/setFacilityProfileMainRetrieverAutoDetection',
  async ({ facilityId, data }) => {
    const response = await FacilityService.setFacilityProfileMainRetrieverAutoDetection(
      facilityId,
      data
    )

    return response
  }
)

export const createAuthForm = createAsyncThunk('facilities/createAuthForm', data => {
  const response = FacilityService.createAuthForm(data)

  return response
})

export const deleteAuthForm = createAsyncThunk(
  'facilities/deleteAuthForm',
  async ({ formId, facilityId }) => {
    await FacilityService.deleteAuthForm(formId)

    return { formId, facilityId }
  }
)

export const fetchFormById = createAsyncThunk(
  'facilities/fetchFormById',
  async ({ formId, id }) => {
    const response = await FacilityService.getFormById(formId)

    return { id, changes: response }
  }
)

export const addFacilityToCluster = createAsyncThunk(
  'facilities/addToCluster',
  async ({ id, data }) => {
    const response = await FacilityService.addFacilityToCluster(id, data)

    return response
  }
)

export const removeFacilityFromCluster = createAsyncThunk(
  'facilities/removeFromCluster',
  async id => {
    await FacilityService.removeFromCluster(id)

    return id
  }
)

export const linkDoctor = createAsyncThunk(
  'facilities/linkDoctor',
  async ({ id, data }) => {
    const response = await FacilityService.linkDoctor(id, data)

    return response
  }
)

export const unlinkDoctor = createAsyncThunk(
  'facilities/unlinkDoctor',
  async ({ id, data }) => {
    await FacilityService.unlinkDoctor(id, data)

    return id
  }
)

export const createFacilityWithDoctor = createAsyncThunk(
  'facilities/createFacilityWithDoctor',
  async ({ doctorId, data }) => {
    const facility = await FacilityService.createFacility(data)
    await FacilityService.linkDoctor(facility.id, {
      id: doctorId,
    })

    return facility
  }
)

export const setMRDFacility = createAsyncThunk(
  'facilities/updateMRDFacility',
  async ({ id, data }) => {
    const response = await FacilityService.setMRDFacility(id, data)

    return { id, changes: response }
  }
)

export const removeFacilityFromFacilityProfile = createAsyncThunk(
  'facilities/removeFromFacilityProfile',
  async ({ id, data }) => {
    await FacilityService.removeFromFacilityProfile(id, data)

    return id
  }
)

export const addFacilityToFacilityProfile = createAsyncThunk(
  'facilities/addToFacilityProfile',
  async ({ id, data }) => {
    const response = await FacilityService.addFacilityToFacilityProfile(id, data)

    return response
  }
)

export const getRelatedFacilityProfileFacilities = createAsyncThunk(
  'facilities/getRelatedFacilityProfileFacilities',
  data =>
    getPagination(data, FacilityService.getRelatedFacilityProfiles.bind(FacilityService))
)

export const getFacilityProfileESignStat = createAsyncThunk(
  'facilities/getFacilityProfileESignStat',
  async data => FacilityService.getFacilityProfileESignStat(data)
)

export const getSpecialAuth = createAsyncThunk('facilities/getSpecialAuth', data =>
  getPagination(data, FacilityService.getSpecialAuth.bind(FacilityService))
)

export const setFacilityProfileCommunicationMethodAutoDetection = createAsyncThunk(
  'facilities/setFacilityProfileCommunicationMethodAutoDetection',
  async ({ facilityId, data }) => {
    const response =
      await FacilityService.setFacilityProfileCommunicationMethodAutoDetection(
        facilityId,
        data
      )

    return response
  }
)

export const setFacilityProfileCommunicationMethod = createAsyncThunk(
  'facilities/setFacilityProfileCommunicationMethod',
  async ({ facilityId, data }) => {
    const response = await FacilityService.setFacilityProfileCommunicationMethod(
      facilityId,
      data
    )

    return response
  }
)

export const resetState = createAction('facilities/resetState')

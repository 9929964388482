import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getPagination } from '../../../utils/functions'

import MergerService from '../../../services/MergersService'

export const fetchMergerById = createAsyncThunk('merger/fetchMergerById', async id => {
  const response = await MergerService.getMergerById(id)

  return response
})

export const createMerger = createAsyncThunk('merger/createMerger', data => {
  const response = MergerService.createMerger(data)

  return response
})

export const getMergersList = createAsyncThunk('merger/getMergers', data =>
  getPagination(data, MergerService.getMergers.bind(MergerService))
)

export const getDoctorMergerProviders = createAsyncThunk(
  'merger/getDoctorMergerProviders',
  async data => getPagination(data, MergerService.getMergerProviders.bind(MergerService))
)

export const getFacilityMergerProviders = createAsyncThunk(
  'merger/getFacilitiesMergerProviders',
  async data => getPagination(data, MergerService.getMergerProviders.bind(MergerService))
)

export const relateMergerProvider = createAsyncThunk(
  'merger/relateMergerProvider',
  async ({ id, providerType, data }) => {
    const response = await MergerService.relateMerger(id, providerType, data)

    return response
  }
)

export const removeMergerProviderRelation = createAsyncThunk(
  'merger/removeMergerProviderRelation',
  async ({ id, providerType, providerId, data }) => {
    const response = await MergerService.removeMergerProviderRelation(
      id,
      providerType,
      providerId,
      data
    )

    return response
  }
)

export const runMerger = createAsyncThunk('merger/runMerger', async id => {
  const response = await MergerService.runMerger(id)

  return response
})

export const deleteMerger = createAsyncThunk('merger/deleteMerger', async id => {
  await MergerService.deleteMerger(id)

  return id
})

export const resetState = createAction('facilities/resetState')

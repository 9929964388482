import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Form, TimePicker } from 'antd'
import moment from 'moment'

import { TIME_FORMAT } from '../../../constants'

const TimePickerField = ({
  field: { ...field },
  form: { touched, errors, setFieldValue },
  label,
  size,
  error,
  format,
  ...props
}) => {
  const { name, value } = field
  const [dateValue, setDateValue] = useState(
    moment(value, format).isValid() ? moment(value, format) : null
  )

  const handleChange = useCallback(
    date => {
      setDateValue(date)
      setFieldValue(
        field.name,
        moment(date).isValid() ? moment(date).format(TIME_FORMAT.full) : null
      )
    },
    [field.name, setFieldValue]
  )

  return (
    <Form.Item
      label={label}
      validateStatus={(errors[name] && touched[name]) || error ? 'error' : 'success'}
      help={(errors[name] && touched[name]) || error ? errors[name] || error : null}
    >
      <TimePicker
        {...field}
        {...props}
        onChange={handleChange}
        value={dateValue}
        format={format}
      />
    </Form.Item>
  )
}

TimePickerField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onBlur: PropTypes.func,
  }),
  form: PropTypes.shape({
    touched: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object)),
    ]),
    errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    setFieldValue: PropTypes.func,
  }).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  type: PropTypes.oneOf(['password', 'text', 'search', 'email']),
  size: PropTypes.oneOf(['large', 'small', 'medium']),
  error: PropTypes.string,
  format: PropTypes.string,
}

TimePickerField.defaultProps = {
  field: PropTypes.shape({
    value: '',
    onBlur: () => null,
  }),
  label: '',
  type: 'text',
  size: 'large',
  error: '',
  format: TIME_FORMAT.half,
}

export default TimePickerField

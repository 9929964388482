import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { List, Typography, Button, Row, Col, Space, Divider } from 'antd'
import { DeleteOutlined, FileOutlined } from '@ant-design/icons'

import showConfirmDialog from '../Modals/DeleteConfirm'
import useDispatchHttp from '../../../hooks/dispatchHttpHandler'
import { deleteAuthForm } from '../../../state/modules/facilities'
import { formatDate } from '../../../utils/helpers'
import { useUser } from '../../../providers/UserProvider'

export const FormListItem = ({
  attach_name,
  id,
  created_at,
  form,
  facilityId,
  editor,
  canDelete,
}) => {
  const dispatchHttp = useDispatchHttp()
  const { isUserActionAllowed } = useUser()

  const handleDelete = useCallback(
    async formId => {
      dispatchHttp(
        deleteAuthForm({ formId, facilityId }),
        'Form has been successfully deleted'
      )
    },
    [dispatchHttp, facilityId]
  )

  const deleteHandler = useCallback(
    () =>
      showConfirmDialog({
        title: `Do you want to delete ${attach_name}?`,
        data: id,
        handleConfirm: handleDelete,
      }),
    [attach_name, handleDelete, id]
  )

  return (
    <>
      <List.Item
        className="form-list-item"
        actions={
          canDelete && [
            <Button
              key={id}
              icon={<DeleteOutlined />}
              size="middle"
              onClick={deleteHandler}
            />,
          ]
        }
      >
        <Row align="middle" gutter={16}>
          <Col>
            <FileOutlined className="form-list-item__icon" />
          </Col>
          <Col>
            <Typography.Title level={3} className="form-list-item__title">
              {attach_name}
            </Typography.Title>
            <Space size={24}>
              {form?.id === id && (
                <Typography.Text strong className="form-list-item--current">
                  Current Form
                </Typography.Text>
              )}
              <Typography.Text type="secondary">
                Last Editing{' '}
                <Typography.Text className="form-list-item__date">
                  {formatDate(created_at)}
                </Typography.Text>
              </Typography.Text>
              {editor && (
                <Typography.Text type="secondary">
                  by{' '}
                  {isUserActionAllowed('view_user') ? (
                    <Link to={`/users/${editor.id}`} component={Typography.Link}>
                      {`${editor.firstname} ${editor.lastname}`}
                    </Link>
                  ) : (
                    <>{`${editor.firstname} ${editor.lastname}`}</>
                  )}
                </Typography.Text>
              )}
            </Space>
          </Col>
        </Row>
      </List.Item>
      {form?.id === id && <Divider />}
    </>
  )
}

FormListItem.propTypes = {
  attach_name: PropTypes.string,
  id: PropTypes.number,
  created_at: PropTypes.string,
  form: PropTypes.shape({
    id: PropTypes.number,
  }),

  facilityId: PropTypes.number,
  editor: PropTypes.shape({
    id: PropTypes.number,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
  }),
  canDelete: PropTypes.bool,
}

FormListItem.defaultProps = {
  attach_name: '',
  id: null,
  created_at: '',
  form: { id: null },
  facilityId: null,
  editor: null,
  canDelete: true,
}

export default FormListItem

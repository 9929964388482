import React, { useRef, useState, useCallback } from 'react'
import { Row, Col, Button, Space, Typography } from 'antd'
import { LinkOutlined, DeleteOutlined } from '@ant-design/icons'
import Table from '../Tables/SearchTable'
import { DOCTOR } from '../../../constants'
import RelateExceptionModal from '../Modals/RelateExceptionModal'
import Doctor from '../../templates/Doctor'
import { useUser } from '../../../providers/UserProvider'
import showConfirmDialog from '../Modals/DeleteConfirm'

const ExceptionDoctorTable = ({
  id,
  data,
  onLoadData,
  disabledRemove,
  handleRemoveRelation,
  loadingStatus,
  title,
  tableTitle,
  totalCount,
  handleRelateDoctor,
  hasRelatedDoctor,
  exceptionData,
  dataCaseInformation,
  caseInformationId,
  primarySearch,
  highlightedFields,
  doctorColumns,
  formData,
}) => {
  const [isVisibleModal, setVisibleModal] = useState(false)
  const [isAutoScrollModal, setAutoScrollModal] = useState(false)
  const [modalId, setModalId] = useState(false)
  const { isUserActionAllowed } = useUser()

  const removeRelationHandler = useCallback(
    relationData =>
      showConfirmDialog({
        title: 'Are you sure you want to remove relation?',
        data: relationData,
        handleConfirm: payload => {
          setAutoScrollModal(false)
          setVisibleModal(false)
          handleRemoveRelation(payload)
        },
        okText: 'Remove Relation',
      }),
    [handleRemoveRelation]
  )

  const columns = doctorColumns.map(item => ({
    ...item,
    onCell: record => ({
      onClick: e => {
        if (e.target.closest('.redirect')) return
        if (item.key === 'name') {
          setVisibleModal(true)
          setModalId(record.id)
        }

        if (item.key === 'related_facilities') {
          setVisibleModal(true)
          setModalId(record.id)
          setAutoScrollModal(true)
        }
      },
    }),
  }))

  const tableRef = useRef(null)
  if (!data?.length) {
    return null
  }

  return (
    <>
      <RelateExceptionModal
        data={exceptionData}
        isVisibleModal={isVisibleModal}
        isAutoScrollModal={isAutoScrollModal}
        scrollRef={tableRef}
        onCloseModal={() => {
          setAutoScrollModal(false)
          setVisibleModal(false)
        }}
        dataCaseInformation={dataCaseInformation}
        caseInformationId={caseInformationId}
        id={modalId}
      >
        <Doctor
          tableRef={tableRef}
          isActionDisabled={disabledRemove}
          relateDoctor={doctor => {
            setAutoScrollModal(false)
            setVisibleModal(false)
            handleRelateDoctor(doctor)
          }}
          removeRelation={() => {
            removeRelationHandler({ doctor: hasRelatedDoctor })
          }}
          exceptionId={id}
          hasRelation={hasRelatedDoctor}
        />
      </RelateExceptionModal>

      <Table
        tableTitle={
          tableTitle || (
            <Row justify="space-between" align="middle">
              <Col>
                <LinkOutlined className="search-table__title--icon" />
                {title}
              </Col>
              {isUserActionAllowed('manage_relations_case') && (
                <Col>
                  <Button
                    type="text"
                    size="large"
                    disabled={disabledRemove}
                    onClick={() => {
                      removeRelationHandler({ doctor: hasRelatedDoctor })
                    }}
                  >
                    <Typography.Text type={disabledRemove ? 'secondary' : `danger`}>
                      <Space>
                        <DeleteOutlined />
                        Remove Relation
                      </Space>
                    </Typography.Text>
                  </Button>
                </Col>
              )}
            </Row>
          )
        }
        primarySearch={primarySearch}
        highlightedFields={highlightedFields}
        columns={columns}
        entity={DOCTOR}
        totalData={data}
        totalCount={totalCount}
        loadingStatus={loadingStatus}
        formData={formData}
        onLoadData={onLoadData}
      />
    </>
  )
}

export default ExceptionDoctorTable

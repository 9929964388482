import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import {
  fetchAllAssignmentCopyCompanies,
  fetchAssignmentCopyCompanyById,
  createAssignmentCopyCompany,
  updateAssignmentCopyCompany,
} from './actions'

import {
  IDLE_STATUS,
  LOADING_STATUS,
  SUCCESS_STATUS,
  FAILURE_STATUS,
} from '../../../constants'

export const assignmentCopyCompanyAdapter = createEntityAdapter()

const initialState = assignmentCopyCompanyAdapter.getInitialState({
  status: IDLE_STATUS,
  count: 0,
})

const assignmentCopyCompanySlice = createSlice({
  name: 'assignmentCopyCompanies',
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchAllAssignmentCopyCompanies.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(
      fetchAllAssignmentCopyCompanies.fulfilled,
      (state, { payload: { results = [], count = 0 } }) => {
        state.status = SUCCESS_STATUS
        assignmentCopyCompanyAdapter.setAll(state, results)
        state.count = count
      }
    )
    builder.addCase(
      fetchAllAssignmentCopyCompanies.rejected,
      (state, { payload, error }) => {
        state.status = FAILURE_STATUS
        state.error = payload || error.message
      }
    )
    builder.addCase(fetchAssignmentCopyCompanyById.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(fetchAssignmentCopyCompanyById.fulfilled, (state, { payload }) => {
      state.status = SUCCESS_STATUS
      assignmentCopyCompanyAdapter.addOne(state, payload)
    })
    builder.addCase(
      createAssignmentCopyCompany.fulfilled,
      assignmentCopyCompanyAdapter.upsertOne
    )
    builder.addCase(updateAssignmentCopyCompany.fulfilled, (state, { payload }) => {
      assignmentCopyCompanyAdapter.removeOne(state, payload.id)
      assignmentCopyCompanyAdapter.upsertOne(state, payload)
    })
  },
})

export const { setAll } = assignmentCopyCompanySlice.actions

const { reducer } = assignmentCopyCompanySlice

export default reducer

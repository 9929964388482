import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { Divider, Row, Col, Switch } from 'antd'
import { Field } from 'formik'
import TextField from '../../../shared/TextField'

const CollapsibleCard = ({ renderHints, searchSuggestion, onSetFieldValue, isOpen }) => {
  const [open, setOpen] = useState(isOpen)

  const handleCardVisibility = checked => {
    setOpen(checked)
  }

  useEffect(() => {
    handleCardVisibility(isOpen)
  }, [isOpen])

  return (
    <div
      className={cx('search-form__card search-form__card--collapsible', {
        'search-form__card--compact': !open,
      })}
    >
      {!open && (
        <Row justify="space-between">
          <Col>
            <div className="ant-form-item-label">
              <label>
                Jaia ID
                <Divider type="vertical" className="search-form__divider" />
                NPI
              </label>
            </div>
          </Col>
          <Col>
            <Switch onChange={handleCardVisibility} defaultChecked={open} />
          </Col>
        </Row>
      )}
      {open && (
        <>
          <Field
            component={TextField}
            name="gaia_id"
            placeholder="Type Here"
            label={
              <Row justify="space-between" align="middle">
                <Col>Jaia ID</Col>
                <Col>
                  <Switch onChange={handleCardVisibility} defaultChecked={open} />
                </Col>
              </Row>
            }
            suffix={renderHints(searchSuggestion, 'gaia_id', onSetFieldValue)}
          />
          <Field
            component={TextField}
            name="npi"
            placeholder="Type Here"
            label="NPI"
            suffix={renderHints(searchSuggestion, 'npi', onSetFieldValue)}
          />
        </>
      )}
    </div>
  )
}

export default CollapsibleCard

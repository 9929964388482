import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import {
  searchFacilityProfiles,
  fetchFacilityById,
  updateFacility,
  getRelatedFacilities,
  getRelatedDoctors,
  createFacility,
  deleteFacility,
  fetchFacilityProfileById,
  createFacilityProfile,
  updateFacilityProfile,
  setFacilityProfileRetriever,
  deleteAuthForm,
  fetchFormById,
  getRelatedFacilityProfileFacilities,
  resetState,
} from './actions'
import {
  IDLE_STATUS,
  LOADING_STATUS,
  SUCCESS_STATUS,
  FAILURE_STATUS,
} from '../../../constants'

export const facilityAdapter = createEntityAdapter()
const initialState = facilityAdapter.getInitialState({
  status: IDLE_STATUS,
  related_facilities: facilityAdapter.getInitialState({ count: 0 }),
  related_doctors: facilityAdapter.getInitialState({ count: 0 }),
  facility_profiles: facilityAdapter.getInitialState({ count: 0 }),
  related_facility_profiles: facilityAdapter.getInitialState({ count: 0 }),
})

const facilitySlice = createSlice({
  name: 'facilities',
  initialState,
  extraReducers: builder => {
    builder.addCase(resetState, state => {
      state.related_facilities = facilityAdapter.getInitialState({
        count: 0,
      })
      state.related_doctors = facilityAdapter.getInitialState({ count: 0 })
      state.facility_profiles = facilityAdapter.getInitialState({
        count: 0,
      })
      state.related_facility_profiles = facilityAdapter.getInitialState({
        count: 0,
      })
      state.status = IDLE_STATUS
      facilityAdapter.removeAll(state)
    })
    builder.addCase(searchFacilityProfiles.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(
      searchFacilityProfiles.fulfilled,
      (state, { payload: { results = [], count = 0 } }) => {
        state.status = SUCCESS_STATUS
        state.facility_profiles.count = count
        facilityAdapter.setAll(state.facility_profiles, results)
      }
    )
    builder.addCase(searchFacilityProfiles.rejected, state => {
      state.status = FAILURE_STATUS
    })
    builder.addCase(fetchFacilityById.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(fetchFacilityById.fulfilled, (state, { payload }) => {
      facilityAdapter.upsertOne(state, payload)
      state.status = SUCCESS_STATUS
    })
    builder.addCase(fetchFacilityById.rejected, state => {
      state.status = FAILURE_STATUS
    })
    builder.addCase(createFacility.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(createFacility.fulfilled, facilityAdapter.upsertOne)
    builder.addCase(createFacility.rejected, state => {
      state.status = FAILURE_STATUS
    })
    builder.addCase(updateFacility.fulfilled, facilityAdapter.updateOne)
    builder.addCase(deleteFacility.fulfilled, facilityAdapter.removeOne)
    builder.addCase(
      getRelatedFacilities.fulfilled,
      (state, { payload: { results = [], count = 0 } }) => {
        state.status = SUCCESS_STATUS
        state.related_facilities.count = count
        facilityAdapter.setAll(state.related_facilities, results)
      }
    )
    builder.addCase(
      getRelatedDoctors.fulfilled,
      (state, { payload: { results = [], count = 0 } }) => {
        state.status = SUCCESS_STATUS
        state.related_doctors.count = count
        facilityAdapter.setAll(state.related_doctors, results)
      }
    )
    builder.addCase(fetchFacilityProfileById.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(fetchFacilityProfileById.rejected, state => {
      state.status = FAILURE_STATUS
    })
    builder.addCase(fetchFacilityProfileById.fulfilled, (state, { payload }) => {
      const form = payload.form && payload.form.is_active ? payload.form : null
      const forms = payload.form
        ? [payload.form, ...payload.history_forms]
        : [...payload.history_forms]
      const history_forms = forms.filter(({ is_active }) => is_active)

      facilityAdapter.upsertOne(state.facility_profiles, {
        ...payload,
        form,
        history_forms,
      })
      state.status = SUCCESS_STATUS
    })
    builder.addCase(createFacilityProfile.fulfilled, (state, { payload }) => {
      facilityAdapter.upsertOne(state.facility_profiles, payload)
    })
    builder.addCase(updateFacilityProfile.fulfilled, (state, { payload }) => {
      facilityAdapter.updateOne(state.facility_profiles, payload)
    })

    builder.addCase(setFacilityProfileRetriever.fulfilled, (state, { payload }) => {
      facilityAdapter.updateOne(state.facility_profiles, payload)
    })
    builder.addCase(deleteAuthForm.fulfilled, (state, { payload }) => {
      const entity = state.facility_profiles.entities[payload.facilityId]

      const changes = {
        ...entity,
        form: entity?.form?.id === payload.formId ? null : entity.form,
        history_forms: entity.history_forms.filter(({ id }) => id !== payload.formId),
      }

      facilityAdapter.updateOne(state.facility_profiles, {
        id: payload.facilityId,
        changes,
      })
    })
    builder.addCase(fetchFormById.fulfilled, (state, { payload }) => {
      facilityAdapter.updateOne(state.facility_profiles, payload)
    })
    builder.addCase(getRelatedFacilityProfileFacilities.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(
      getRelatedFacilityProfileFacilities.fulfilled,
      (state, { payload: { results = [], count = 0, pageSize, page } }) => {
        state.related_facility_profiles.pageSize = pageSize
        state.related_facility_profiles.page = page
        state.related_facility_profiles.count = count
        facilityAdapter.setAll(state.related_facility_profiles, results)
        state.status = SUCCESS_STATUS
      }
    )
    builder.addCase(getRelatedFacilityProfileFacilities.rejected, state => {
      state.status = FAILURE_STATUS
    })
  },
})

const { reducer } = facilitySlice

export default reducer

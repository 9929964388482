import React from 'react'
import PropTypes from 'prop-types'
import { Tabs } from 'antd'

const TabsComponent = ({
  tabBarExtraContent,
  tabs,
  tabData,
  fullWidth,
  topContent,
  bottomContent,
  children,
  tabsProps,
  theme,
  defaultActiveKey,
  hideTabBar,
  destroyInactiveTabPane,
}) => (
  <Tabs
    defaultActiveKey={defaultActiveKey}
    destroyInactiveTabPane={destroyInactiveTabPane}
    tabBarExtraContent={tabBarExtraContent}
    renderTabBar={(props, DefaultTabBar) => (
      <div
        className={`custom-tab-list ${fullWidth && 'full-width'} ${theme}`}
        style={hideTabBar ? { display: 'none' } : {}}
      >
        {topContent && <div className="custom-tab-list__top-content">{topContent}</div>}
        <DefaultTabBar {...props} />
        {bottomContent && (
          <div className="custom-tab-list__bottom-content">{bottomContent}</div>
        )}
      </div>
    )}
    {...tabsProps}
  >
    {tabs.length
      ? tabs.map(({ name, key, component: Component }) => (
          <Tabs.TabPane tab={name} key={key}>
            <Component data={tabData} />
          </Tabs.TabPane>
        ))
      : children}
  </Tabs>
)

TabsComponent.propTypes = {
  tabBarExtraContent: PropTypes.element,
  destroyInactiveTabPane: PropTypes.bool,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      Component: PropTypes.elementType,
    })
  ),
  onChange: PropTypes.func,
  tabData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  fullWidth: PropTypes.bool,
  topContent: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  bottomContent: PropTypes.element,
  children: PropTypes.arrayOf(PropTypes.element),
  activeKey: PropTypes.string,
  tabsProps: PropTypes.shape({
    size: PropTypes.oneOf(['small', 'default', 'large']),
    tabBarGutter: PropTypes.number,
  }),
  theme: PropTypes.oneOf(['dark', 'light']),
  defaultActiveKey: PropTypes.string,
  isViewSearchPermission: PropTypes.bool,
  hideTabBar: PropTypes.bool,
}

TabsComponent.defaultProps = {
  tabs: [],
  destroyInactiveTabPane: false,
  tabBarExtraContent: null,
  onChange: () => null,
  tabData: [],
  fullWidth: false,
  topContent: null,
  bottomContent: null,
  children: null,
  activeKey: 'all',
  tabsProps: {
    size: 'default',
    tabBarGutter: 10,
  },
  theme: 'light',
  defaultActiveKey: 'all',
  isViewSearchPermission: false,
  hideTabBar: false,
}

export default TabsComponent

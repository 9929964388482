import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Col, Row, Table, Spin } from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import MainLayout from '../../layouts/MainLayout'
import useDispatchHttp from '../../../hooks/dispatchHttpHandler'
import {
  deleteRole,
  fetchAllRoles,
  selectAllRoles,
  isRolesFetching,
} from '../../../state/modules/roles'
import showConfirmDialog from '../../components/Modals/DeleteConfirm'
import { useUser } from '../../../providers/UserProvider'

const Roles = () => {
  const roles = useSelector(selectAllRoles)
  const isLoading = useSelector(isRolesFetching)
  const { isUserActionAllowed } = useUser()
  const { count } = useSelector(state => state.roles)
  const dispatchHttp = useDispatchHttp()

  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
  })
  const onChange = (page, pageSize) => {
    dispatchHttp(fetchAllRoles({ page, pageSize }))
    setParams(prev => ({ ...prev, page, pageSize }))
  }

  useEffect(() => {
    dispatchHttp(fetchAllRoles({ page: params.page, pageSize: params.pageSize }))
    // eslint-disable-next-line
  }, [dispatchHttp])

  const handleDeleteRole = useCallback(
    async id => {
      await dispatchHttp(
        deleteRole(id),
        'Role has been successfully removed from Roles page'
      )
      setParams({
        page: 1,
        pageSize: 10,
      })
      await dispatchHttp(fetchAllRoles({ page: 1, pageSize: 10 }))
    },
    [dispatchHttp]
  )
  const removeRoleHandler = useCallback(
    data =>
      showConfirmDialog({
        title: 'Are you sure you want to remove role?',
        data,
        handleConfirm: handleDeleteRole,
        okText: 'Remove Role',
      }),
    [handleDeleteRole]
  )

  return (
    <MainLayout
      withSubHeader
      subHeaderProps={{
        title: 'Roles',
        subTitle: 'Manage Roles Here',
        button: isUserActionAllowed('add_group') ? (
          <Link to="/roles/add-new-role">
            <Button type="primary" icon={<PlusOutlined style={{ fontSize: '12px' }} />}>
              Add New Role
            </Button>
          </Link>
        ) : (
          <></>
        ),
      }}
    >
      <Spin spinning={isLoading}>
        <Row
          justify="center"
          style={{
            marginTop: '24px',
          }}
        >
          <Col xxl={16} sm={20}>
            <Table
              pagination={{
                ...params,
                current: params.page,
                total: count,
                onChange,
              }}
              dataSource={roles}
              bordered
              className="roles-table"
            >
              {' '}
              <Table.Column
                width="90%"
                key="name"
                title="Name"
                render={text => <Link to={`/roles/${text.id}`}> {text.name}</Link>}
              />
              {(isUserActionAllowed('change_group') ||
                isUserActionAllowed('delete_group')) && (
                <Table.Column
                  cols
                  key="name"
                  title="Actions"
                  className="centered"
                  render={(text, record) => (
                    <div className="action-buttons-container">
                      {isUserActionAllowed('change_group') && (
                        <Link to={`/roles/${text.id}`}>
                          <EditOutlined className="action-icon" />
                        </Link>
                      )}
                      {isUserActionAllowed('delete_group') && (
                        <DeleteOutlined
                          onClick={() => removeRoleHandler(record.id)}
                          className="action-icon"
                        />
                      )}
                    </div>
                  )}
                />
              )}
            </Table>
          </Col>
        </Row>
      </Spin>
    </MainLayout>
  )
}

export default Roles

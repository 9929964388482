import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import {
  fetchDoctorById,
  getRelatedFacilities,
  updateDoctor,
  createDoctor,
  deleteDoctor,
  resetState,
} from './actions'
import { IDLE_STATUS, LOADING_STATUS, SUCCESS_STATUS } from '../../../constants'

export const doctorAdapter = createEntityAdapter()
const initialState = doctorAdapter.getInitialState({
  status: IDLE_STATUS,
  related_facilities: doctorAdapter.getInitialState({ count: 0 }),
})

const doctorSlice = createSlice({
  name: 'doctors',
  initialState,
  extraReducers: builder => {
    builder.addCase(resetState, state => {
      state.related_facilities = doctorAdapter.getInitialState({
        count: 0,
      })
      state.status = IDLE_STATUS
      doctorAdapter.removeAll(state)
    })
    builder.addCase(fetchDoctorById.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(fetchDoctorById.fulfilled, (state, { payload }) => {
      state.status = SUCCESS_STATUS
      doctorAdapter.upsertOne(state, payload)
    })
    builder.addCase(createDoctor.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(createDoctor.fulfilled, doctorAdapter.upsertOne)
    builder.addCase(createDoctor.rejected, state => {
      state.status = IDLE_STATUS
    })
    builder.addCase(updateDoctor.fulfilled, doctorAdapter.updateOne)
    builder.addCase(deleteDoctor.fulfilled, doctorAdapter.removeOne)

    builder.addCase(
      getRelatedFacilities.fulfilled,
      (state, { payload: { results = [], count = 0 } }) => {
        state.status = SUCCESS_STATUS
        state.related_facilities.count = count
        doctorAdapter.setAll(state.related_facilities, results)
      }
    )
  },
})

const { reducer } = doctorSlice

export default reducer

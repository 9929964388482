import React from 'react'
import { Row, Checkbox } from 'antd'

import { CASE_GOALS_MRD_DETECTED, CASE_GOALS_MRD_NOT_DETECTED } from '../../../constants'
import {
  fetchExceptionById,
  changeExceptionGoals,
} from '../../../state/modules/exceptions'
import useDispatchHttp from '../../../hooks/dispatchHttpHandler'

const goalLabels = {
  [CASE_GOALS_MRD_DETECTED.FIND_DOCTOR]: 'Find Doctor',
  [CASE_GOALS_MRD_DETECTED.FIND_TREATMENT_FACILITY]: 'Find Treatment Facility',
  [CASE_GOALS_MRD_DETECTED.FIND_MRD_FACILITY]: 'Find MRD Facility',
  [CASE_GOALS_MRD_NOT_DETECTED.FIND_DOCTOR]: 'Find Doctor',
  [CASE_GOALS_MRD_NOT_DETECTED.FIND_FACILITY]: 'Find Facility',
}

const CaseGoalsCustomRender = ({
  id,
  goals,
  selectedGoals,
  setWarningModalGoals,
  disabled,
  sort,
}) => {
  const dispatchHttp = useDispatchHttp()
  const handleChangeGoals = async values => {
    const data = goals.reduce(
      (acc, item) => ({
        ...acc,
        [item]: !!values.find(i => item === i),
      }),
      {}
    )

    await dispatchHttp(changeExceptionGoals({ id, data }), 'Goal has been updated')

    const isTreatmentFacilityGoalExist = selectedGoals.includes('find_treatment_facility')
    const isMrdFacilityGoalExist = selectedGoals.includes('find_mrd_facility')
    let warningModalGoal = false
    if (isTreatmentFacilityGoalExist && !data.find_treatment_facility) {
      warningModalGoal = 'treatment'
    }

    if (isMrdFacilityGoalExist && !data.find_mrd_facility) {
      warningModalGoal = 'mrd'
    }

    setWarningModalGoals(warningModalGoal)

    dispatchHttp(fetchExceptionById({ id, params: { sort } }))
  }

  return (
    <>
      <Row>
        <Checkbox.Group
          onChange={handleChangeGoals}
          value={selectedGoals}
          options={goals.map(goal => ({
            label: goalLabels[goal],
            value: goal,
          }))}
          disabled={disabled}
        />
      </Row>
    </>
  )
}
export default CaseGoalsCustomRender

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { routePaths } from '../../../utils/routes'
import { useUser } from '../../../providers/UserProvider'

export const PrivateRoute = ({ component: Component, permission, title, ...rest }) => {
  const { token } = useSelector(state => state.auth)
  const { isAuthError } = useSelector(state => state.auth)
  const { isUserActionAllowed } = useUser()

  useEffect(() => {
    document.title = title || 'Jaia Search'
  }, [title])

  return (
    <Route
      {...rest}
      render={props => {
        const crumbs = rest.routes
          .filter(({ path }) => props.match.path.includes(path))
          .map(({ path, ...params }) => ({
            path: Object.keys(props.match.params).length
              ? Object.keys(props.match.params).reduce(
                  (route, param) => route.replace(`:${param}`, props.match.params[param]),
                  path
                )
              : path,
            ...params,
          }))

        if (token && !isAuthError) {
          if (isUserActionAllowed(permission) || !permission) {
            return <Component {...props} breadcrumbs={crumbs} />
          }

          return (
            <Redirect
              to={{
                pathname: routePaths.openForbidden(),
                state: { from: props.location },
              }}
            />
          )
        }

        return (
          <Redirect
            to={{
              pathname: routePaths.openLogin(),
              state: { from: props.location },
            }}
          />
        )
      }}
    />
  )
}

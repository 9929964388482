export const initialDoctorValues = {
  type: 'doctor',
  addresses: [
    {
      type: 'LOC',
      zip_code: '',
      city: '',
      state: null,
      address_line_1: '',
    },
  ],
  phone_numbers: [{ number: '', phone_extension: '', type: 'LOC', verified: false }],
  fax_numbers: [],
  emails: [],
  first_name: '',
  last_name: '',
  is_sole_proprietor: false,
}

export const initialFacilityValues = {
  type: 'facility',
  addresses: [
    {
      type: 'LOC',
      zip_code: '',
      city: '',
      state: null,
      address_line_1: '',
    },
  ],
  other_npis: [],
  phone_numbers: [{ number: '', phone_extension: '', type: 'LOC', verified: false }],
  fax_numbers: [],
  emails: [],
  name: '',
  is_headquarter: false,
}
